<template>
  <div>
    <div
      class="flex items-center justify-between h-[43px] bg-gradient-to-b from-white to-[#F1F3F4] px-[18px] border-b border-[#E2E5EC]"
    >
      <span class="text-[#44474A] font-[700] text-[15px] mb-0">
        Blocks
      </span>
    </div>

    <div class="flex flex-column relative max-h-full h-[calc(100vh-197px)]">
      <div class="relative overflow-auto">
        <draggable
          v-model="sections"
          handle=".move"
        >
          <transition-group name="shufle">
            <b-card
              v-for="section in sections"
              :key="section.uid"
              class="border-0"
              no-body
            >
              <section-item-hover @click.native="sectionSettings(section)">
                <icon
                  class="chevron mr-3"
                  icon="chevron.svg"
                />
                <icon
                  class="move mr-3"
                  icon="move.svg"
                />
                <div
                  block
                  class="text-nowrap transition-all"
                >
                  <input
                    v-model="section.name"
                    type="text"
                    class="!text-[14px] bg-transparent"
                    @click.stop
                  >
                </div>

                <template slot="menu">
                  <b-dropdown toggle-class="bg-transparent border-0 absolute w-[40px] h-[6px]">
                    <template slot="button-content">
                      <icon
                        class="dot-menu cursor-pinter"
                        icon="dot menu-primary.svg"
                      />
                    </template>

                    <b-dropdown-item
                      class="d-flex align-items-center"
                      @click.native.stop="$store.dispatch('sections/removeSection', section.uid)"
                    >
                      <icon
                        class="mr-2"
                        icon="trash.svg"
                      />
                      Delete
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </section-item-hover>
            </b-card>
          </transition-group>
        </draggable>
      </div>
    </div>

    <button
      class="group d-flex w-full items-center h-[43px] border-top border-bottom mt-[-1px] relative z-10 !px-[19px]"
      @click="$store.commit('editor/SET_PAGE', 'blocks')"
    >
      <svg
        class="mr-[14px]"
        fill="none"
        height="18"
        viewBox="0 0 17 18"
        width="17"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          class="group-hover:fill-[#3D82EA]"
          clip-rule="evenodd"
          d="M16.6 8.8C16.6 13.384 12.884 17.1 8.3 17.1C3.71604 17.1 0 13.384 0 8.8C0 4.21604 3.71604 0.5 8.3 0.5C12.884 0.5 16.6 4.21604 16.6 8.8ZM8.3 15.8C12.166 15.8 15.3 12.666 15.3 8.8C15.3 4.93401 12.166 1.8 8.3 1.8C4.43401 1.8 1.3 4.93401 1.3 8.8C1.3 12.666 4.43401 15.8 8.3 15.8Z"
          fill="#6E747A"
          fill-rule="evenodd"
        />
        <path
          class="group-hover:fill-[#3D82EA]"
          d="M11.9 9.03195V7.76795H9.03598V4.64795H7.64398V7.76795H4.76398V9.03195H7.64398V12.2799H9.03598V9.03195H11.9Z"
          fill="#6E747A"
        />
      </svg>
      <span class="font-normal text-[14px] text-[#6E747A] group-hover:text-[#4F83E3]">Add New Block</span>
    </button>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Icon from '@builder/components/editor/utils/Icon'
import draggable from 'vuedraggable'
import SectionItemHover from '../utils/SectionItemHover'
import _ from 'lodash'

export default {
  name: 'Sections',
  components: {SectionItemHover, Icon, draggable},
  computed: {
    ...mapGetters('sections', {
      getSections: 'getSections',
      navbar: 'getNavbar',
      footer: 'getFooter'
    }),
    sectionName: {
      get () {
        return ''
      }
    },
    sections: {
      get () {
        return this.getSections.filter(section => {
          return !section.hideFromSections
        })
      },
      set (val) {
        this.$store.dispatch('sections/setSections', val)
      }
    }
  },

  methods: {
    sectionSettings (section) {
      this.$store.dispatch('editor/sectionSettings', {section, openedItem: 'blank-mod'})
      const el = document.querySelector(`[data-uid="${section.uid}"]`)

      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
      }
    },
    label (label) {
      return _.startCase(label)
    },
    newSectionModal () {
      this.$store.commit('editor/SET_SECTION_MODAL', this.sections.length ? this.sections[this.sections.length - 1].uid : 'none')
    }
  }
}
</script>

<style lang="scss">
.custom-menu {
  .dropdown-toggle {
    &,
    &:active,
    &:focus {
      padding: 0;
      background: transparent !important;
      border: 0 !important;
      box-shadow: none !important;
      line-height: 0;
    }


    &:after {
      display: none;
    }
  }

  .dropdown-item {
    padding: 12px 17px;
  }
}
</style>
