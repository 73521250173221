<template>
  <b-row
    class="align-items-center"
    @click="loadProject"
  >
    <b-col cols="1">
      <div
        v-if="project.screenshot"
        class="project-preview shadow"
        :style="{backgroundImage: `url(${project.screenshot.replace('/upload', '/upload/q_10')})`}"
      />
      <svg
        v-else
        width="55"
        height="38"
        viewBox="0 0 55 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="55"
          height="38"
          fill="#C4C4C4"
        />
        <path
          d="M20.4166 15.25C20.4166 14.4985 20.7151 13.7779 21.2465 13.2465C21.7778 12.7152 22.4985 12.4167 23.25 12.4167H31.75C32.5014 12.4167 33.2221 12.7152 33.7534 13.2465C34.2848 13.7779 34.5833 14.4985 34.5833 15.25V23.75C34.5833 24.5014 34.2848 25.2221 33.7534 25.7535C33.2221 26.2848 32.5014 26.5833 31.75 26.5833H23.25C22.4985 26.5833 21.7778 26.2848 21.2465 25.7535C20.7151 25.2221 20.4166 24.5014 20.4166 23.75V15.25Z"
          stroke="#9E9E9E"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M25.0208 18.7917C25.9988 18.7917 26.7917 17.9988 26.7917 17.0208C26.7917 16.0428 25.9988 15.25 25.0208 15.25C24.0428 15.25 23.25 16.0428 23.25 17.0208C23.25 17.9988 24.0428 18.7917 25.0208 18.7917Z"
          stroke="#9E9E9E"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.2893 19.9399L23.25 26.5833H31.8442C32.5707 26.5833 33.2674 26.2947 33.7811 25.781C34.2947 25.2674 34.5833 24.5707 34.5833 23.8442V23.75C34.5833 23.4199 34.4594 23.2931 34.2363 23.0487L31.3817 19.9356C31.2486 19.7905 31.0868 19.6747 30.9065 19.5956C30.7262 19.5165 30.5314 19.4759 30.3345 19.4763C30.1376 19.4767 29.943 19.5181 29.763 19.5979C29.5831 19.6777 29.4217 19.7942 29.2893 19.9399V19.9399Z"
          stroke="#9E9E9E"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </b-col>

    <b-col>
      <h5
        class="item-name text-truncate mb-0"
        v-text="project.name"
      />
    </b-col>

    <b-col
      class="date-stamp"
      cols="2"
    >
      {{ project.updated_at | moment('MMM DD, YYYY') }}
    </b-col>

    <b-col
      class="date-stamp"
      cols="2"
    >
      {{ project.created_at | moment('MMM DD, YYYY') }}
    </b-col>

    <b-col cols="1">
      <b-dropdown
        variant="new-project-card-dropdown"
        right
        dropup
        toggle-class="text-decoration-none"
        menu-class="project-menu py-0"
        no-caret
      >
        <template #button-content>
          <svg
            width="24"
            height="5"
            viewBox="0 0 24 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="2.5"
              cy="2.5"
              r="2.5"
              fill="#4F83E3"
            />
            <circle
              cx="12.0714"
              cy="2.5"
              r="2.5"
              fill="#4F83E3"
            />
            <circle
              cx="21.3572"
              cy="2.5"
              r="2.5"
              fill="#4F83E3"
            />
          </svg>
        </template>


        <move-project-modal :project="project" />
        <delete-project-modal :project="project" />
        <b-dropdown-item
          class="d-flex align-items-center"
          link-class="dashboard-menu-link"
          @click.stop="$router.push({name: 'settings.share', params: {id: project.id}})"
        >
          <svg
            class="mr-2"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.3333 15.3334C14.4379 15.3334 15.3333 14.4379 15.3333 13.3334L15.3333 2.66669C15.3333 1.56212 14.4379 0.666687 13.3333 0.666687H2.66663C1.56206 0.666687 0.666626 1.56212 0.666626 2.66669V4.66669C0.666626 5.03488 0.965104 5.33335 1.33329 5.33335C1.70148 5.33335 1.99996 5.03488 1.99996 4.66669L1.99996 2.66669C1.99996 2.2985 2.29844 2.00002 2.66663 2.00002L13.3333 2.00002C13.7015 2.00002 14 2.2985 14 2.66669L14 13.3334C14 13.7015 13.7015 14 13.3333 14L2.66663 14C2.29844 14 1.99996 13.7015 1.99996 13.3334L1.99996 11.3334C1.99996 10.9652 1.70148 10.6667 1.33329 10.6667C0.965104 10.6667 0.666626 10.9652 0.666626 11.3334V13.3334C0.666626 14.4379 1.56206 15.3334 2.66663 15.3334L13.3333 15.3334Z"
              fill="#44474A"
            />
            <path
              d="M8.19522 11.1381C7.93487 10.8777 7.93487 10.4556 8.19522 10.1953L9.72382 8.66669H4.66659C4.2984 8.66669 3.99992 8.36821 3.99992 8.00002C3.99992 7.63183 4.2984 7.33335 4.66659 7.33335H9.72382L8.19522 5.80476C7.93487 5.54441 7.93487 5.1223 8.19522 4.86195C8.45557 4.6016 8.87768 4.6016 9.13803 4.86195L11.8047 7.52862C12.065 7.78897 12.065 8.21107 11.8047 8.47142L9.13803 11.1381C8.87768 11.3984 8.45557 11.3984 8.19522 11.1381Z"
              fill="#44474A"
            />
          </svg>

          Share Link
        </b-dropdown-item>
        <b-dropdown-item
          :href="`https://app.estage.com/project-import/${project.hash}`"
          class="d-flex align-items-center"
          link-class="dashboard-menu-link"
        >
          <svg
            class="mr-2"
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.70837 7.62499C8.40922 7.62499 8.16671 7.8675 8.16671 8.16666V16.8333C8.16671 17.1325 8.40922 17.375 8.70837 17.375H16.2917C16.5909 17.375 16.8334 17.1325 16.8334 16.8333V11.4167H14.6667C13.7692 11.4167 13.0417 10.6891 13.0417 9.79166V7.62499H8.70837ZM14.125 8.39102L16.0673 10.3333H14.6667C14.3676 10.3333 14.125 10.0908 14.125 9.79166V8.39102ZM7.08337 8.16666C7.08337 7.26919 7.81091 6.54166 8.70837 6.54166H13.5834C13.727 6.54166 13.8648 6.59872 13.9664 6.70031L17.7581 10.492C17.8596 10.5936 17.9167 10.7313 17.9167 10.875V16.8333C17.9167 17.7308 17.1892 18.4583 16.2917 18.4583H8.70837C7.81091 18.4583 7.08337 17.7308 7.08337 16.8333V8.16666Z"
              fill="#44474A"
              stroke="#44474A"
              stroke-width="0.1"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.70837 1.62499C2.40922 1.62499 2.16671 1.8675 2.16671 2.16666V10.8333C2.16671 11.1325 2.40922 11.375 2.70837 11.375H10.2917C10.5909 11.375 10.8334 11.1325 10.8334 10.8333V5.41666H8.66671C7.76924 5.41666 7.04171 4.68912 7.04171 3.79166V1.62499H2.70837ZM8.12504 2.39102L10.0673 4.33332H8.66671C8.36755 4.33332 8.12504 4.09081 8.12504 3.79166V2.39102ZM1.08337 2.16666C1.08337 1.26919 1.81091 0.541656 2.70837 0.541656H7.58337C7.72703 0.541656 7.86481 0.598725 7.96639 0.700307L11.7581 4.49197C11.8596 4.59356 11.9167 4.73133 11.9167 4.87499V10.8333C11.9167 11.7308 11.1892 12.4583 10.2917 12.4583H2.70837C1.81091 12.4583 1.08337 11.7308 1.08337 10.8333V2.16666Z"
              fill="#44474A"
              stroke="#44474A"
              stroke-width="0.1"
              stroke-linejoin="round"
            />
            <rect
              x="5.80005"
              y="5.40002"
              width="5"
              height="6"
              rx="0.7"
              fill="white"
            />
          </svg>

          Duplicate Site
        </b-dropdown-item>
        <edit-project-modal :project="project" />
        <b-dropdown-item
          class="d-flex align-items-center"
          link-class="dashboard-menu-link"
          @click.stop="$router.push({name: 'settings.general', params: {id: project.id}})"
        >
          <svg
            class="mr-2"
            width="16"
            height="14"
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.66663 0.333313C3.03482 0.333313 3.33329 0.63179 3.33329 0.99998V2.44712C4.11009 2.72167 4.66663 3.4625 4.66663 4.33331C4.66663 5.20413 4.11009 5.94495 3.33329 6.21951V13C3.33329 13.3682 3.03482 13.6666 2.66663 13.6666C2.29844 13.6666 1.99996 13.3682 1.99996 13V6.21951C1.22316 5.94495 0.666626 5.20413 0.666626 4.33331C0.666626 3.4625 1.22316 2.72167 1.99996 2.44712V0.99998C1.99996 0.63179 2.29844 0.333313 2.66663 0.333313ZM3.33329 4.33331C3.33329 4.7015 3.03482 4.99998 2.66663 4.99998C2.29844 4.99998 1.99996 4.7015 1.99996 4.33331C1.99996 3.96512 2.29844 3.66665 2.66663 3.66665C3.03482 3.66665 3.33329 3.96512 3.33329 4.33331Z"
              fill="#44474A"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.3333 0.333313C13.7015 0.333313 14 0.63179 14 0.99998V2.44712C14.7768 2.72167 15.3333 3.4625 15.3333 4.33331C15.3333 5.20413 14.7768 5.94495 14 6.21951V13C14 13.3682 13.7015 13.6666 13.3333 13.6666C12.9651 13.6666 12.6666 13.3682 12.6666 13V6.21951C11.8898 5.94495 11.3333 5.20413 11.3333 4.33331C11.3333 3.4625 11.8898 2.72167 12.6666 2.44712V0.99998C12.6666 0.63179 12.9651 0.333313 13.3333 0.333313ZM13.3333 4.99998C13.7015 4.99998 14 4.7015 14 4.33331C14 3.96512 13.7015 3.66665 13.3333 3.66665C12.9651 3.66665 12.6666 3.96512 12.6666 4.33331C12.6666 4.7015 12.9651 4.99998 13.3333 4.99998Z"
              fill="#44474A"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.66663 7.78045V0.99998C8.66663 0.63179 8.36815 0.333313 7.99996 0.333313C7.63177 0.333313 7.33329 0.63179 7.33329 0.99998V7.78045C6.5565 8.05501 5.99996 8.79583 5.99996 9.66665C5.99996 10.5375 6.5565 11.2783 7.33329 11.5528V13C7.33329 13.3682 7.63177 13.6666 7.99996 13.6666C8.36815 13.6666 8.66663 13.3682 8.66663 13V11.5528C9.44342 11.2783 9.99996 10.5375 9.99996 9.66665C9.99996 8.79583 9.44342 8.05501 8.66663 7.78045ZM7.99996 10.3333C8.36815 10.3333 8.66663 10.0348 8.66663 9.66665C8.66663 9.29846 8.36815 8.99998 7.99996 8.99998C7.63177 8.99998 7.33329 9.29846 7.33329 9.66665C7.33329 10.0348 7.63177 10.3333 7.99996 10.3333Z"
              fill="#44474A"
            />
          </svg>

          Settings
        </b-dropdown-item>
        <b-dropdown-item
          :href="getSiteUrl()"
          target="_blank"
          class="d-flex align-items-center"
          link-class="dashboard-menu-link"
        >
          <svg
            class="mr-2"
            width="15"
            height="13"
            viewBox="0 0 15 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.625 6.5C10.625 8.22589 9.22589 9.625 7.5 9.625C5.77411 9.625 4.375 8.22589 4.375 6.5C4.375 4.77411 5.77411 3.375 7.5 3.375C9.22589 3.375 10.625 4.77411 10.625 6.5ZM9.375 6.5C9.375 7.53553 8.53553 8.375 7.5 8.375C6.46447 8.375 5.625 7.53553 5.625 6.5C5.625 5.46447 6.46447 4.625 7.5 4.625C8.53553 4.625 9.375 5.46447 9.375 6.5Z"
              fill="#44474A"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15 6.5C15 8.0625 11.6421 12.125 7.5 12.125C3.35786 12.125 0 8.375 0 6.5C0 4.625 3.35786 0.875 7.5 0.875C11.6421 0.875 15 4.9375 15 6.5ZM13.7497 6.49933L13.7498 6.5L13.7497 6.50067C13.7482 6.51303 13.7388 6.59057 13.6804 6.74288C13.6161 6.91066 13.5098 7.12358 13.3539 7.37034C13.0418 7.86431 12.5706 8.43089 11.9711 8.96572C10.7568 10.0491 9.1656 10.875 7.5 10.875C5.80448 10.875 4.20847 10.1012 3.01361 9.0695C2.42109 8.5579 1.9588 8.00897 1.65325 7.51365C1.33054 6.9905 1.25 6.63849 1.25 6.5C1.25 6.36151 1.33054 6.0095 1.65325 5.48635C1.9588 4.99103 2.42109 4.4421 3.01361 3.9305C4.20847 2.89883 5.80448 2.125 7.5 2.125C9.1656 2.125 10.7568 2.95089 11.9711 4.03428C12.5706 4.5691 13.0418 5.13569 13.3539 5.62966C13.5098 5.87642 13.6161 6.08934 13.6804 6.25712C13.7388 6.40943 13.7482 6.48697 13.7497 6.49933Z"
              fill="#44474A"
            />
          </svg>

          View
        </b-dropdown-item>
      </b-dropdown>
    </b-col>
  </b-row>
</template>

<script>
import EditProjectModal from '../../templates/EditProjectModal'
import DeleteProjectModal from '../../templates/DeleteProjectModal'
import MoveProjectModal from '../../templates/MoveProjectModal'
import {addhttp} from '../../../../../utils/urlHelper'
export default {
  name: 'ProjectListItem',
  components: {MoveProjectModal, DeleteProjectModal, EditProjectModal},
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  methods: {
    getSiteUrl () {
      return this.project.domain ? addhttp(this.project.domain) : `${process.env.VUE_APP_ESTAGE_SITE_HOST}` + `/${this.project.subdomain}`
    },
    async loadProject () {
      await this.$store.dispatch('projects/load', this.project.id)
        .then(() => {
          this.$router.push(`/builder/${this.project.id}`)
          this.$store.commit('filter/SET_BY_NAME', '')
        })
    }
  }
}
</script>

<style lang="scss">
.project-preview {
  width: 55px;
  height: 38px;
  background-position: top;
  background-size: cover;
}
</style>