<template>
  <div class="sid-select w-full">
    <v-select
      v-model="val"
      class="custom-select"
      :options="options"
      v-bind="$attrs"
      :reduce="val => val.value"
    />
  </div>
</template>

<script>
import SidConcept from '@/components/mixins/SidConcept'

export default {
  name: 'SidSelect',
  mixins: [SidConcept],
  props: {
    priority: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      d: null
    }
  },
  computed: {
    val: {
      get () {
        if (this.multiple) {
          this.options.forEach(option => {
            const _val = window.getComputedStyle(document.querySelector(option.selector)).getPropertyValue(option.property)

            if (_val === option.value) {
              this.d = option.value
            }
          })
        }

        return this.d || this.sidValueMutation
      },
      set (val) {
        this.d = val
        const item = this.options.find(o => o.value == val)

        if (this.priority) {
          this.options.forEach(o => {
            if (o.property === item.property) return
            this.addSidRules([[o.selector || this.getSid(), [o.property || this.ruleName, '']]])
          })
        }

        if (item.customProperties) {
          return this.addSidRules(item.customProperties, {
            variable: item.value
          })
        }

        this.addSidRules([[item.selector || this.getSid(), [item.property || this.ruleName, `${val}`]]])
      }
    }
  },
  watch: {
    sid () {
      this.d = this.getSidStyle()
    }
  }
}
</script>

<style lang="scss">
.sid-select {
  .v-select:not([multiple]) {
    height: 29px !important;
  }

  .form-control:not(textarea) {
    height: 29px !important;
  }

  .vs__dropdown-toggle {
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  .vs__search {
    transform: scale(.9);
    margin-left: -5px;
  }
}

.vs__dropdown-option {
  font-size: 14px;
}
</style>