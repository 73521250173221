<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    class="highlight-element"
    section-type="element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="() => sectionSettings('list')"
  >
    <div
      :data-sid="section.sid"
      class="flex"
      :class="`--${section.sid}--list --${section.sid}--1`"
    >
      <ul
        :class="{'element-hidden': !visability('list')}"
        class="list-unstyled list-element list flex flex-column w-full"
      >
        <li
          v-for="(item, index) in section.options.content.list.items"
          :key="item.label"
          class="flex"
        >
          <div class="d-flex align-items-center">
            <div
              v-show="section.options.content.list.customIcon"
              :class="`--lottie-container-${section.sid}`"
            >
              <button-lottie-icon
                :config="section.options.content.list"
              />
            </div>
            <i
              v-show="!section.options.content.list.customIcon"
              :class="section.options.content.list.icon"
              class="list-icon"
            />
          </div>

          <div class="w-full ml-2">
            <content-editable-v2
              ref="content"
              v-model="item.subject.options.content.description.text"
              :customs="item.subject.options.content.description"
              custom-class="text-inherit"
              type="paragraph"
              :section="item.subject"
              @click.native="() => selectSubjectIndex(index)"
            />
          </div>
        </li>
      </ul>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import PropertiesManager from '../../../mixins/PropertiesManager'
import ResolutionMixin from '../../../mixins/ResolutionMixin'
import ButtonLottieIcon from '@/components/builder/elements/9.icon/ButtonLottieIcon'

export default {
  name: 'FeaturedItems1',

  components: {ButtonLottieIcon, Highliter},

  mixins: [SectionMixin, PropertiesManager, ResolutionMixin],

  methods: {
    selectSubjectIndex (index) {
      this.$set(this.section, 'selectedSubjectIndex', index)
      this.sectionSettings('list')
    }
  }
}
</script>

<style>
.list-element .paragraph {
    text-align: left !important;
    font-size: 1rem;
    color: inherit;
}

.list-icon.fa {
    line-height: 1.3;
}
</style>