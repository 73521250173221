<template>
  <div>
    <b-form-group
      v-for="(field, index) in controls"
      :key="index"
    >
      <label>
        {{ field.field_name }} <span class="text-danger">*</span>
      </label>
      <form-mapping-field
        :autoresponder="autoresponder"
        :all-fields="controls"
        :field="field"
        :controls="apiFieldsWithFixFields"
      />
    </b-form-group>
  </div>
</template>

<script>
import FormMappingField from './FormMappingField'

export default {
  name: 'FormMapping',
  components: {FormMappingField},
  props: {
    apiFields: {
      type: [Array, Object],
      required: true
    },
    controls: {
      type: Array,
      required: true
    },
    autoresponder: {
      type: Object,
      required: true
    }
  },
  computed: {
    apiFieldsWithFixFields () {
      if (this.autoresponder.name === 'AWeber') {
        return [
          {
            name: 'email',
            id: 'email'
          },
          ...this.apiFields
        ]
      }

      if (this.autoresponder.name === 'MailChimp') {
        return [
          {
            name: 'email',
            tag: 'email',
            merge_id: 'email'
          },
          ...this.apiFields
        ]
      }

      if (this.autoresponder.name === 'Ontraport') {
        return [
          ...this.apiFields
        ]
      }

      if (this.autoresponder.name === 'ActiveCampaign') {
        return [
          {
            id: 'email',
            title: 'Email',
            perstag: 'email'
          },
          ...this.apiFields
        ]
      }

      if (this.autoresponder.name === 'GetResponse') {
        return [
          {
            customFieldId: 'email',
            name: 'email'
          },
          ...this.apiFields
        ]
      }

      if (this.autoresponder.name === 'ConvertKit') {
        return [
          {
            id: 'email',
            key: 'email'
          },
          ...this.apiFields
        ]
      }

      if (this.autoresponder.name === 'Sendlane') {
        return [
          {
            id: 'email',
            name: 'email'
          },
          ...this.apiFields
        ]
      }

      return []
    }
  }
}
</script>
