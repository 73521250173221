<template>
  <div class="sid-select">
    <div
      class="relative"
      :class="{'vs-tags': $attrs.hasOwnProperty('multiple')}"
    >
      <svg
        v-if="!model.length && $attrs.hasOwnProperty('multiple')"
        class="absolute top-1/2 left-2 -translate-y-1/2"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="16"
          height="17"
          rx="2"
          fill="#3D82EA"
        />
        <path
          d="M11.095 9.455V8.435H8.32V5.435H7.21V8.435H4.435V9.455H7.21V12.56H8.32V9.455H11.095Z"
          fill="white"
        />
      </svg>
      <v-select
        v-model="model"
        v-bind="$attrs"
        class="custom-select"
        :options="filtered"
        :reduce="reduce"
        @open="onOpen"
        @close="onClose"
        @search="onSearch"
      >
        <template #list-footer>
          <li
            v-show="hasNextPage"
            ref="load"
            class="loader"
          >
            Loading more options...
          </li>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TagsSelect',
  props: {
    value: {
      type: [Array, Object, Number, String],
      required: true
    },
    prependOptions: {
      type: Array,
      default: () => []
    },
    reduce: {
      type: Function,
      default: (val) => {
        return val.id
      }
    },
    url: {
      type: String
    }
  },
  data () {
    return {
      observer: null,
      limit: 30,
      page: 1,
      search: '',
      lastPage: 0,
      options: [],
      loading: false
    }
  },
  computed: {
    filtered () {
      if (!this.$attrs.multiple) return [...this.prependOptions, ...this.options]
      return [...this.prependOptions, ...this.options].filter(item => {
        console.log(item,1123)
        return this.value.findIndex(selected => selected.id === item.id) === -1
      })
    },
    hasNextPage () {
      return this.lastPage > this.page
    },
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  mounted () {
    this.fetch()
      .then(({data}) => {
        this.options = data.data.data
        this.lastPage = data.last_page
      })
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    refresh () {
      this.page = 1
      this.search = ''
      return this.fetch()
        .then(({data}) => {
          this.options = data.data.data
          this.lastPage = data.last_page
        })
    },
    async fetch () {
      this.loading = true
      return await axios.get(`${this.url}?page=${this.page}&search=${this.search}`)
        .finally(() => {
          this.loading = false
        })
    },
    onSearch (val) {
      this.page = 1
      this.search = val
      this.fetch()
        .then(({data}) => {
          this.options = data.data.data
          this.lastPage = data.last_page
        })
    },
    async onOpen () {
      this.page = 1
      this.fetch()
        .then(({data}) => {
          this.options = data.data.data
          this.lastPage = data.last_page
        })

      await this.$nextTick()
      this.observer.observe(this.$refs.load)
    },
    onClose () {
      this.observer.disconnect()
    },
    async infiniteScroll ([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.page++
        this.fetch()
          .then(({data}) => {
            this.options = [...this.options, ...data.data]
            this.lastPage = data.last_page
            ul.scrollTop = scrollTop
          })
      }
    }
  }
}
</script>

<style lang="scss">
.sid-select {
  .vs-tags {
    .vs--open {
      background: #fff !important;
    }

    .v-select {
      background: transparent !important;
      height: auto !important;
      padding: 0;
    }

    .vs__dropdown-toggle {
      position: static !important;
    }

    .vs__search {
      transform: scale(0.9);
      margin-left: -5px !important;
    }

    .vs__selected {
      background: #D9D9D9;
      border: none;
      font-size: 13px;
      padding: 2px 6px;
      color: #454545;
    }

    .vs__deselect {
      svg {
        transform: scale(.8);
      }

      path {
        fill: #5F5F5F;
      }
    }
  }
}
</style>