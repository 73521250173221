<template>
  <div
    v-if="products.length === 1 && products[0].prices.length === 0"
    :data-sid="`product-summary-${sid}`"
    class="position-relative text-container"
    :class="`--${section.sid}--margin`"
  >
    <highliter
      v-model="section.name"
      class="highlight-element flex flex-col"
      section-type="element"
      :hide-right="true"
      @move-to="to => $emit('move-to', to)"
      @add-subject="$emit('add-subject')"
    >
      <div
        class="flex flex-col m-0"
        :class="`--${section.sid}--container`"
      >
        <h4
          class="payment-summary-title text-left m-0"
          :class="`--${section.sid}--title`"
        >
          Payment Summary
        </h4>
  
        <div
          class="flex flex-row items-center justify-between"
          :class="`--${section.sid}--item`"
        >
          <p
            class="payment-summary-product text-left m-0"
            :class="`--${section.sid}--product`"
          >
            {{ products[0].name }}
          </p>
          <span
            class="payment-summary-price text-right m-0"
            :class="`--${sid}--price`"
          >
            ${{ products[0].cost }} USD {{ products[0].interval ? `/ ${startCase(products[0].interval)}` : null }}
          </span>
        </div>
      </div>
    </highliter>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import BorderMixin from '@/components/mixins/BorderMixin'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter.vue'
import TextMixins from '@/components/mixins/TextMixins'
export default {
  name: 'ElPaymentSummary',
  mixins: [BorderMixin, SectionMixin, TextMixins],
  components: {Highliter},
  computed: {
    ...mapState('products', {
      products: state => state.selected
    })
  }
}
</script>