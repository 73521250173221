<template>
  <div>
    <highliter
      v-model="section.name"
      :section="section"
      class="highlight-element"
      :class="{'element-hidden': !visability('code')}"
      @delete="$emit('delete')"
      @dublicate="$emit('dublicate')"
      @favorite="$emit('favorite', $el)"
      @flow="handleFlow"
      @move-to="to => $emit('move-to', to)"
      @add-subject="$emit('add-subject')"
      @click.stop.native="sectionSettings('code')"
    >
      <div
        :data-sid="section.sid"
      >
        <div :class="`--${section.sid}--wrapper`">
          <div
            class="flex flex-col items-center justify-center bg-gray-300 h5 p-4 text-center rounded"
          >
            Estage Internal Embed
          </div>
        </div>
      </div>
    </highliter>
    
    <purchase-plan-modal
      v-model="modal"
      business-plus
    />
  </div>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import { findDeep } from '@builder/utils/helpers'
import PurchasePlanModal from '@/components/editor/modals/purchase-plan/PurchasePlanModal.vue'

export default {
  name: 'FeaturedItems1',

  components: {PurchasePlanModal, Highliter},

  mixins: [SectionMixin],

  data () {
    return {
      timeout: null,
      premium: false,
      modal: false
    }
  },

  computed: {
    code () {
      return this.section.options.content.code.code
    },
    isChatSubscription () {
      return this.$store.state.editor.chatSubscription.has_available_subscriptions || this.$store.state.editor.businessPlusSubscription
    },
    isCommunityPlus () {
      return this.$store.state.editor.communityPlusSubscription
    },
    isBusinessPlus () {
      return this.$store.state.editor.businessPlusSubscription
    },
    isPlus () {
      return this.$store.state.editor.communityPlusSubscription || this.$store.state.editor.businessPlusSubscription
    }
  },

  async mounted () {
    await this.checkPremium()
  },

  methods: {
    async checkPremium () {
      let block
      if (this.section.options.content.code.code) {
        await axios.get(`https://api.estage.com/api/projects/favorite-blocks/${this.code}`)
          .then(({data}) => {
            block = data ? JSON.parse(data.data.block) : null
          })
          .catch(() => {
            this.premium = false
          })
        const realtimeChat = findDeep(block, 'component', 'ElRealtimeChat')
        const liveStream = findDeep(block, 'component', 'ElLiveStream')
        if (realtimeChat !== undefined) {
          this.premium = !this.isChatSubscription
        }
        if (liveStream !== undefined) {
          this.premium = !this.isPlus
        }
        if (realtimeChat === undefined && liveStream === undefined) {
          this.premium = false
        }
      }
    },
    prepare () {
      const template = document.createElement('div'),
        wrapperDep = document.createElement('div')

      document.querySelectorAll(`script[uid="${this.section.uid}"]`)
        .forEach(item => item.parentNode.removeChild(item))

      document.querySelectorAll('#wf_script')
        .forEach(item => item.parentNode.removeChild(item))
      delete window._wf

      wrapperDep.setAttribute('custom-embed-wrapper', true)
      wrapperDep.setAttribute('uid', this.section.uid)

      template.innerHTML = this.section.options.content.code.code

      template.querySelectorAll('script')
        .forEach($s => {
          var dEl = document.createElement($s.tagName),
            attrs = $s.attributes

          dEl.innerHTML = $s.innerHTML
          for (var i = attrs.length - 1; i >= 0; i--) {
            dEl.setAttribute(attrs[i].name, attrs[i].value)
          }
          wrapperDep.appendChild(dEl)
        })

      document.head.appendChild(wrapperDep)
    }
  }

  // watch: {
  //   code () {
  //     this.checkPremium()
  //   }
  // }
}
</script>
