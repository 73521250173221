<template>
  <b-modal
    id="new-modal"
    v-model="modal"
    hide-footer
    hide-header
    centered
  >
    <b-form
      @submit.prevent="createMenu"
    >
      <div class="text-right">
        <b-link @click="modal = false">
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.71152 5.5L10.8243 1.38681C10.9374 1.27358 10.9998 1.12252 11 0.961445C11 0.80028 10.9376 0.649042 10.8243 0.535994L10.4639 0.175654C10.3506 0.0621585 10.1995 0 10.0383 0C9.87729 0 9.72624 0.0621585 9.61293 0.175654L5.50018 4.28858L1.38725 0.175654C1.27412 0.0621585 1.12298 0 0.961829 0C0.800854 0 0.649715 0.0621585 0.536585 0.175654L0.176 0.535994C-0.0586667 0.770676 -0.0586667 1.15239 0.176 1.38681L4.28884 5.5L0.176 9.61302C0.0627805 9.72642 0.000447154 9.87748 0.000447154 10.0386C0.000447154 10.1996 0.0627805 10.3507 0.176 10.464L0.536496 10.8243C0.649626 10.9378 0.800854 11 0.96174 11C1.12289 11 1.27403 10.9378 1.38716 10.8243L5.50009 6.71133L9.61284 10.8243C9.72615 10.9378 9.8772 11 10.0382 11H10.0383C10.1994 11 10.3505 10.9378 10.4638 10.8243L10.8242 10.464C10.9373 10.3508 10.9997 10.1996 10.9997 10.0386C10.9997 9.87748 10.9373 9.72642 10.8242 9.6131L6.71152 5.5Z"
              fill="#6E747A"
            />
          </svg>
        </b-link>
      </div>
      
      <div class="my-5 px-5">
        <h4 class="text-center mb-5 font-weight-bold">
          Create A New Mega Menu
        </h4>

        <b-form-group label="Name">
          <b-input
            v-model="form.name"
            required
            autocomplete="off"
          />
        </b-form-group>

        <div class="flex justify-center w-100">
          <b-btn
            type="submit"
            variant="primary"
          >
            Continue
            <svg
              v-if="!loading"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.6 9.3C17.6 13.884 13.884 17.6 9.3 17.6C4.71604 17.6 1 13.884 1 9.3C1 4.71604 4.71604 1 9.3 1C13.884 1 17.6 4.71604 17.6 9.3ZM9.3 16.3C13.166 16.3 16.3 13.166 16.3 9.3C16.3 5.43401 13.166 2.3 9.3 2.3C5.43401 2.3 2.3 5.43401 2.3 9.3C2.3 13.166 5.43401 16.3 9.3 16.3Z"
                fill="white"
              />
              <path
                d="M12.9 9.53198V8.26798H10.036V5.14798H8.64398V8.26798H5.76398V9.53198H8.64398V12.78H10.036V9.53198H12.9Z"
                fill="white"
              />
            </svg>
            <b-spinner
              v-else
              small
              variant="white"
            />
          </b-btn>
        </div>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'NewMegaMenuModal',
  data () {
    return {
      modal: false,
      loading: false,
      form: {
        name: ''
      }
    }
  },
  computed: {
    ...mapState('projects', {
      project: state => state.project
    })
  },
  methods: {
    createMenu () {
      this.loading = true

      axios.post(`api/projects/${this.project.id}/mega-menu`, {
        name: this.form.name,
        css: '@media screen {}\n' +
            '    @media (max-width: 1100px) {}\n' +
            '    @media (max-width: 520px) {}',
        content: JSON.stringify([
          {'uid':'mwVI7E-k5','display':true,'name':'Full Width','sid':'ldujefk9--0KDoy1yd-','hideFromSections':false,'component':'BlankV2','group':'empty-blocks','ignoreGroup':false,'layout':null,'options':{'content':{'items':{'type':null,'children':[{'display':true,'name':'Flex Wrapper','hideFromSections':false,'component':'ElFlexWrapperV2','group':'','layout':null,'options':{'content':{'display':true,'cols':[{'display':true,'name':'Flex Column','elements':[],'sortIndex':{'lg':0,'md':0,'sm':0},'uid':'l5YkaBg78','sid':'ldujefk9--BfPYcK9c-4','highlight':false},{'display':true,'name':'Flex Column','elements':[],'sortIndex':{'lg':1,'md':1,'sm':1},'uid':'BEOwtZmhHg','sid':'ldujefk9--thT2KkB0na','highlight':false}],'animations':{'animation':null,'once':true,'offset':200,'delay':50}}},'uid':'yjH7bz7sq','sid':'ldujefk9--FS_Y3h1apu','temp_id':'SpR-HP7X_'}]},'lottie':{}},'backgroundImage':{'src':''},'backgroundVideo':{'enabled':false,'url':'','loop':true,'ratio':1}},'css':[['.--ldujefk9--0KDoy1yd---container { max-width: 70vw; --width:70vw; padding: 0px; }','.--ldujefk9--0KDoy1yd---bg { background-color: rgb(255, 255, 255); }','.--ldujefk9--0KDoy1yd---wrapper { box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 15px 10px; }','.--ldujefk9--BfPYcK9c-4--flex { max-width: 80vw; --width:80vw; }','.--ldujefk9--BfPYcK9c-4--flex-col-el { width: 100%; --width: 100%; }','.--ldujefk9--thT2KkB0na--flex-col-el { width: 100%; --width: 100%; }','.--ldujefk9--FS_Y3h1apu--flex { max-width: 100%; --width:100%; }'],['.--ldujefk9--0KDoy1yd---container { max-width: 100%; --width:100%;}', '.--ldujefk9--BfPYcK9c-4--flex { max-width: 100%; --width:100%; }','.--ldujefk9--FS_Y3h1apu--flex { max-width: 100%; --width:100%; flex-wrap: nowrap; }'],['.--ldujefk9--BfPYcK9c-4--flex { max-width: 100%; --width:100%; flex-wrap: wrap; }','.--ldujefk9--FS_Y3h1apu--flex { max-width: 100%; --width:100%; flex-wrap: wrap; }']],'sids':['ldujefk9--0KDoy1yd-','ldujefk9--FS_Y3h1apu','ldujefk9--BfPYcK9c-4','ldujefk9--thT2KkB0na']}
        ])
      })
        .catch(() => {
          this.$swal({
            icon: 'error',
            title: 'Server Error',
            showConfirmButton: false,
            timer: 1500
          })
        })
        .finally(() => this.loading = false)
        .then(({data}) => {
          this.modal = false
          this.$router.push({
            name: 'mega-menu.editor',
            params: {
              menu_id: data.data.id
            }
          })
        })
    }
  }
}
</script>