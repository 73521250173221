<template>
  <div>
    <b-dropdown-item
      class="d-flex align-items-center"
      link-class="dashboard-menu-link"
      @click.stop="modal = true"
    >
      <svg
        class="mr-2"
        width="14"
        height="18"
        viewBox="0 0 14 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.5 0.75C5.08579 0.75 4.75 1.08579 4.75 1.5V3H1C0.585786 3 0.25 3.33579 0.25 3.75C0.25 4.16421 0.585786 4.5 1 4.5H13C13.4142 4.5 13.75 4.16421 13.75 3.75C13.75 3.33579 13.4142 3 13 3H9.25V1.5C9.25 1.08579 8.91421 0.75 8.5 0.75H5.5ZM7.75 3H6.25V2.25H7.75V3Z"
          fill="#44474A"
        />
        <path
          d="M5.5 8.25C5.91421 8.25 6.25 8.58579 6.25 9V13.5C6.25 13.9142 5.91421 14.25 5.5 14.25C5.08579 14.25 4.75 13.9142 4.75 13.5V9C4.75 8.58579 5.08579 8.25 5.5 8.25Z"
          fill="#44474A"
        />
        <path
          d="M9.25 9C9.25 8.58579 8.91421 8.25 8.5 8.25C8.08579 8.25 7.75 8.58579 7.75 9V13.5C7.75 13.9142 8.08579 14.25 8.5 14.25C8.91421 14.25 9.25 13.9142 9.25 13.5V9Z"
          fill="#44474A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.00001 5.25C0.782586 5.25 0.575867 5.34435 0.433415 5.5086C0.290961 5.67285 0.226795 5.89083 0.257543 6.10607L1.57356 15.3182C1.73191 16.4267 2.68123 17.25 3.80095 17.25H10.1991C11.3188 17.25 12.2681 16.4267 12.4264 15.3182L13.7425 6.10607C13.7732 5.89083 13.709 5.67285 13.5666 5.5086C13.4241 5.34435 13.2174 5.25 13 5.25H1.00001ZM3.05849 15.1061L1.86476 6.75H12.1352L10.9415 15.1061C10.8887 15.4756 10.5723 15.75 10.1991 15.75H3.80095C3.42771 15.75 3.11127 15.4756 3.05849 15.1061Z"
          fill="#44474A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.5 0.75C5.08579 0.75 4.75 1.08579 4.75 1.5V3H1C0.585786 3 0.25 3.33579 0.25 3.75C0.25 4.16421 0.585786 4.5 1 4.5H13C13.4142 4.5 13.75 4.16421 13.75 3.75C13.75 3.33579 13.4142 3 13 3H9.25V1.5C9.25 1.08579 8.91421 0.75 8.5 0.75H5.5ZM7.75 3H6.25V2.25H7.75V3Z"
          stroke="white"
          stroke-width="0.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.5 8.25C5.91421 8.25 6.25 8.58579 6.25 9V13.5C6.25 13.9142 5.91421 14.25 5.5 14.25C5.08579 14.25 4.75 13.9142 4.75 13.5V9C4.75 8.58579 5.08579 8.25 5.5 8.25Z"
          stroke="white"
          stroke-width="0.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.25 9C9.25 8.58579 8.91421 8.25 8.5 8.25C8.08579 8.25 7.75 8.58579 7.75 9V13.5C7.75 13.9142 8.08579 14.25 8.5 14.25C8.91421 14.25 9.25 13.9142 9.25 13.5V9Z"
          stroke="white"
          stroke-width="0.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.00001 5.25C0.782586 5.25 0.575867 5.34435 0.433415 5.5086C0.290961 5.67285 0.226795 5.89083 0.257543 6.10607L1.57356 15.3182C1.73191 16.4267 2.68123 17.25 3.80095 17.25H10.1991C11.3188 17.25 12.2681 16.4267 12.4264 15.3182L13.7425 6.10607C13.7732 5.89083 13.709 5.67285 13.5666 5.5086C13.4241 5.34435 13.2174 5.25 13 5.25H1.00001ZM3.05849 15.1061L1.86476 6.75H12.1352L10.9415 15.1061C10.8887 15.4756 10.5723 15.75 10.1991 15.75H3.80095C3.42771 15.75 3.11127 15.4756 3.05849 15.1061Z"
          stroke="white"
          stroke-width="0.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      Archive Project
    </b-dropdown-item>

    <b-modal
      v-model="modal"
      class="position-relative"
      lazy
      centered
      body-class="py-5"
      content-class="new-section-modal"
      size="lg"
      hide-footer
      hide-header
    >
      <div class="text-center mb-12">
        <h3 class="font-weight-bold px-0">
          Archive {{ project.name }} Confirmation
        </h3>
        <p>
          The project <span
            class="text-primary"
            v-text="project.name"
          /> will be archived.
        </p>
      </div>

      <b-row>
        <b-col
          offset="2"
          cols="8"
        >
          <b-form @submit.prevent="onsubmit">
            <b-form-group
              class="estage-form-group"
              label="Confirm project subdomain"
            >
              <b-input-group>
                <b-input
                  :value="code"
                  class="bg-white"
                  readonly
                  @focus="$event.target.select()"
                />
                <b-input-group-append>
                  <b-btn
                    v-b-tooltip.top="tooltipText"
                    v-clipboard:copy="code"
                    v-clipboard:success="onCopy"
                    variant="primary"
                  >
                    <i class="fa fa-clipboard" />
                  </b-btn>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <b-form-group
              class="estage-form-group"
              label="Confirm subdomain"
            >
              <b-input
                v-model="confirm"
                v-validate="'required'"
                class="mb-1"
                name="confirm"
                :state="errors.has('confirm') ? false : null"
              />
              <p class="card-text text-muted">
                To confirm, enter/paste the subdomain of your project that you want to
                archive.
              </p>
            </b-form-group>

            <div class="text-center mt-5">
              <b-btn
                type="submit"
                variant="danger"
                class="font-weight-bold font-size-14 px-5"
                :disabled="confirm != code || loading"
              >
                <b-spinner
                  v-if="loading"
                  class="mr-2"
                  variant="light"
                  small
                />
                Archive
              </b-btn>
            </div>
          </b-form>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'DeleteProjectModal',
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      modal: false,
      loading: false,
      code: this.project.subdomain,
      confirm: '',
      tooltipText: 'Copy'
    }
  },
  computed: {
    folder () {
      return this.$store.state.dashboard.folder ? this.$store.state.dashboard.folder : {}
    }
  },
  watch: {
    modal (val) {
      if (val) {
        this.name = JSON.parse(JSON.stringify(this.folder)).name
        this.confirm = ''
      }
    }
  },
  methods: {
    onsubmit () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true
          axios.delete(`api/projects/${this.project.id}`)
            .then(() => {
              this.$store.dispatch('dashboard/loadFolder', this.folder.id || null)
              this.modal = false
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    onCopy () {
      this.tooltipText = 'Copied!'

      setTimeout(() => {
        this.tooltipText = 'Copy'
      }, 800)
    }
  }
}
</script>