<template>
  <div>
    <rule-tabs
      :tabs="['Normal', 'Hover', 'Focus']"
      :stack="true"
    >
      <template #Normal>
        <sid-range-slider
          label="Font Size"
          :sid="`.--${config.sid}--form .form-control.input`"
          rule-name="font-size"
        />

        <sid-font
          prefix="Input"
          :sid="`.--${config.sid}--form .input.form-control`"
        />

        <b-form-group>
          <div class="flex items-center justify-between">
            <label class="pb-0">Input Font Color</label>

            <sid-color
              :sid="`.--${config.sid}--form .input.form-control`"
              rule-name="color"
            />
          </div>
        </b-form-group>

        <b-form-group>
          <div class="flex items-center justify-between">
            <label class="pb-0">Input Placeholder Font Color</label>

            <sid-color
              :sid="`.--${config.sid}--form .input.form-control::placeholder`"
              rule-name="color"
            />
          </div>
        </b-form-group>
      </template>

      <template #Hover>
        <sid-checkbox
          value=".3s"
          unchecked-value=""
          rule-name="transition"
          var="--transition"
          :sid="`.--${config.sid}--form .input.form-control`"
        >
          Transition <span
            class="bg-primary text-center ml-2 px-2 text-white h-[17px] w-[50px] rounded-pill text-[12px] font-normal"
          >BETA</span>
        </sid-checkbox>

        <sid-range-slider
          label="Font Size"
          :sid="`.--${config.sid}--form`"
          presudo=" .input.form-control:hover"
          rule-name="font-size"
        />

        <sid-font
          prefix="Input"
          :sid="`.--${config.sid}--form`"
          presudo=" .input.form-control:hover"
        />

        <b-form-group>
          <div class="flex items-center justify-between">
            <label class="pb-0">Input Font Color</label>

            <sid-color
              :sid="`.--${config.sid}--form`"
              presudo=" .input.form-control:hover"
              rule-name="color"
            />
          </div>
        </b-form-group>

        <b-form-group>
          <div class="flex items-center justify-between">
            <label class="pb-0">Input Placeholder Font Color</label>

            <sid-color
              :sid="`.--${config.sid}--form`"
              presudo=" .input.form-control:hover::placeholder"
              rule-name="color"
            />
          </div>
        </b-form-group>
      </template>

      <template #Focus>
        <sid-checkbox
          value=".3s"
          unchecked-value=""
          rule-name="transition"
          var="--transition"
          :sid="`.--${config.sid}--form .input.form-control:focus`"
        >
          Transition <span
            class="bg-primary text-center ml-2 px-2 text-white h-[17px] w-[50px] rounded-pill text-[12px] font-normal"
          >BETA</span>
        </sid-checkbox>

        <sid-range-slider
          label="Font Size"
          :sid="`.--${config.sid}--form`"
          presudo=" .input.form-control:focus"
          rule-name="font-size"
        />

        <sid-font
          prefix="Input"
          :sid="`.--${config.sid}--form`"
          presudo=" .input.form-control:focus"
        />

        <b-form-group>
          <div class="flex items-center justify-between">
            <label class="pb-0">Input Font Color</label>

            <sid-color
              :sid="`.--${config.sid}--form`"
              presudo=" .input.form-control:focus"
              rule-name="color"
            />
          </div>
        </b-form-group>

        <b-form-group>
          <div class="flex items-center justify-between">
            <label class="pb-0">Input Placeholder Font Color</label>

            <sid-color
              :sid="`.--${config.sid}--form`"
              presudo=" .input.form-control:focus::placeholder"
              rule-name="color"
            />
          </div>
        </b-form-group>
      </template>
    </rule-tabs>

    <hr>

    <sid-checkbox
      :sid="`.--${config.sid}--form`"
      rule-name="flex-wrap"
      value="wrap"
    >
      Wrap
    </sid-checkbox>

    <sid-background
      :sid="`.--${config.sid}--form .input.form-control`"
    />

    <sid-range-slider
      label="Input Height"
      :sid="`.--${config.sid}--form .input.form-control`"
      rule-name="height"
    />

    <sid-range-slider
      label="Horizontal Gap"
      :sid="`.--${config.sid}--form`"
      rule-name="column-gap"
    />

    <sid-range-slider
      label="Vertical Gap"
      :sid="`.--${config.sid}--form`"
      rule-name="row-gap"
    />

    <b-form-group>
      <sid-flex-direction
        :sid="`.--${config.sid}--form`"
        rule-name="flex-direction"
      >
        <template #column>
          <sid-buttons-group
            label="Horizontal Alignment"
            :options="[
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                value: 'start',
                tooltip: 'Start'
              },
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                value: 'center',
                tooltip: 'Center'
              },
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                value: 'end',
                tooltip: 'End'
              }
            ]"
            :sid="`.--${config.sid}--form`"
            rule-name="align-items"
          />
        </template>

        <template #row>
          <sid-buttons-group
            label="Horizontal Alignment"
            :options="[
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                value: 'start',
                tooltip: 'Start'
              },
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                value: 'center',
                tooltip: 'Center'
              },
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                value: 'end',
                tooltip: 'End'
              }
            ]"
            :sid="`.--${config.sid}--form`"
            rule-name="justify-content"
          />

          <sid-buttons-group
            label="Vertical Alignment"
            :options="[
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.54688\' y=\'1.59863\' width=\'4.1387\' height=\'7.67361\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.32251\' y=\'1.59863\' width=\'4.1387\' height=\'5.43547\' fill=\'%237E7E7E\'/%3E%3Crect width=\'15.2814\' height=\'0.959201\' transform=\'matrix(1 0 0 -1 0 0.958984)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                value: 'start',
                tooltip: 'Start'
              },
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'9\' viewBox=\'0 0 16 9\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.87305\' y=\'0.349243\' width=\'4.05866\' height=\'8.46585\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.55664\' y=\'0.976318\' width=\'4.05866\' height=\'6.8981\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9858\' height=\'0.94065\' transform=\'matrix(1 0 0 -1 0.0634766 5.05249)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                value: 'center',
                tooltip: 'Center'
              },
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.0532\' height=\'7.51508\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 3.43945 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.0532\' height=\'5.32318\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 8.11523 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9657\' height=\'0.939385\' transform=\'matrix(1 0 0 -1 0.632812 9.43018)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                value: 'end',
                tooltip: 'End'
              }
            ]"
            :sid="`.--${config.sid}--form`"
            rule-name="align-items"
          />
        </template>
      </sid-flex-direction>
    </b-form-group>

    <b-form-group>
      <div class="flex items-center justify-between">
        <label class="pb-0">Border Type</label>

        <sid-select
          :sid="`.--${config.sid}--form .input.form-control`"
          rule-name="border-style"
          :searchable="false"
          class="w-[118px]"
          :options="[{label: 'None', value: 'none'}, {label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
        />
      </div>
    </b-form-group>

    <b-form-group>
      <sid-linked-group
        :sid="`.--${config.sid}--form .input.form-control`"
        label="Border Width"
        rule-name="border"
        class="!mb-[7px]"
        :angles="[
          {
            text: '',
            value: 'top-width'
          },
          {
            text: '',
            value: 'right-width'
          },
          {
            text: '',
            value: 'bottom-width'
          },
          {
            text: '',
            value: 'left-width'
          }
        ]"
      />

      <div class="flex w-full justify-end">
        <sid-color
          :sid="`.--${config.sid}--form .input.form-control`"
          rule-name="border-color"
        />
      </div>
    </b-form-group>

    <sid-linked-group
      label="Radius"
      :sid="`.--${config.sid}--form .input.form-control`"
      rule-name="border"
      :angles="[
        {
          text: 'Top Left',
          value: 'top-left-radius'
        },
        {
          text: 'Top Right',
          value: 'top-right-radius'
        },
        {
          text: 'Bottom Left',
          value: 'bottom-left-radius'
        },
        {
          text: 'Bottom Right',
          value: 'bottom-right-radius'
        }
      ]"
    />

    <hr>

    <sid-range-slider
      label="Label Font Size"
      :sid="`.--${config.sid}--form legend`"
      rule-name="font-size"
    />

    <sid-font
      prefix="Label"
      :sid="`.--${config.sid}--form legend`"
    />

    <b-form-group>
      <div class="flex items-center justify-between">
        <label class="pb-0">Label Font Color</label>

        <sid-color
          :sid="`.--${config.sid}--form legend`"
          rule-name="color"
        />
      </div>
    </b-form-group>

    <sid-buttons-group
      label="Label Alignment"
      :options="[
        {
          svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
          value: 'start',
          tooltip: 'Start'
        },
        {
          svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
          value: 'center',
          tooltip: 'Center'
        },
        {
          svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
          value: 'end',
          tooltip: 'End'
        }
      ]"
      :sid="`.--${config.sid}--form legend`"
      rule-name="text-align"
    />

    <hr>

    <sid-range-slider
      label="Secondary Font Size"
      :sid="`.--${config.sid}--form .secondary-text`"
      rule-name="font-size"
    />

    <sid-font
      prefix="Secondary"
      :sid="`.--${config.sid}--form .secondary-text`"
    />

    <b-form-group>
      <div class="flex items-center justify-between">
        <label class="pb-0">Secondary Font Color</label>

        <sid-color
          :sid="`.--${config.sid}--form .secondary-text`"
          rule-name="color"
        />
      </div>
    </b-form-group>

    <hr>

    <sid-linked-group
      label="Padding"
      :sid="`.--${config.sid}--form .form-control.input`"
      rule-name="padding"
      :min="0"
      :angles="[
        {
          text: '',
          value: 'top'
        },
        {
          text: '',
          value: 'right'
        },
        {
          text: '',
          value: 'bottom'
        },
        {
          text: '',
          value: 'left'
        }
      ]"
    />

    <sid-linked-group
      label="Margin"
      :sid="`.--${config.sid}--form .form-control.input`"
      rule-name="margin"
      :min="-2000"
      :max="2000"
      :angles="[
        {
          text: '',
          value: 'top'
        },
        {
          text: '',
          value: 'right'
        },
        {
          text: '',
          value: 'bottom'
        },
        {
          text: '',
          value: 'left'
        }
      ]"
    />

    <!--    <sid-translate-->
    <!--      :sid="`.&#45;&#45;${config.sid}&#45;&#45;form legend`"-->
    <!--      rule-name="translate"-->
    <!--    />-->

    <!--    <br>-->
    <!--    DEMO ACTIVE LABEL STATE-->
    <!--    <br>-->

    <!--    <sid-translate-->
    <!--      :sid="`.&#45;&#45;${config.sid}&#45;&#45;form legend.active`"-->
    <!--      rule-name="translate"-->
    <!--    />-->
  </div>
</template>

<script>

import SidCheckbox from '@/components/editor/components/sid-controls/SidCheckbox'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'
import SidButtonsGroup from '@/components/editor/components/sid-controls/SidButtonsGroup'
import SidFlexDirection from '@/components/editor/components/sid-controls/sid-control-pressets/flex/SidFlexDirection'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect'
import SidColor from '@/components/editor/components/sid-controls/SidColor'
import SidFont from '@/components/editor/components/sid-controls/sid-control-pressets/font/SidFont'
import RuleTabs from '@/components/editor/components/RuleTabs.vue'

export default {
  name: 'FormStyleV2',
  components: {
    RuleTabs,
    SidFont,
    SidColor,
    SidSelect, SidLinkedGroup, SidBackground, SidFlexDirection, SidButtonsGroup, SidRangeSlider, SidCheckbox},
  props: {
    config: {
      type: Object,
      required: true
    }
  }
}
</script>
