<template>
  <div
    id="settings-aside"
    class="z-10 bg-white d-flex w-[230px] left-0 overflow-auto"
  >
    <b-nav class=" border-bottom-0 d-block w-full pb-[69px]">
      <div
        v-for="(item,i) in routes"
        v-if="item.hasOwnProperty('condition') ? item.condition : true"
        :key="`${i}-item`"
      >
        <component
          :is="(!item.route && !item.href) ? 'div' : 'b-link'"
          class="text-decoration-none text-nowrap flex items-center hover:bg-[#F9FBFF] cursor-pointer h-[59px] px-[21px] font-[700] hover:!text-[#44474A] text-[#44474A] text-[16px] w-100 justify-between border-bottom"
          :to="item.route ? {name: item.route, ...item} : null"
          v-bind="{...(item.href && { href: item.href, target: '_blank' })}"
          :active="[item.route,...item.actives||[]].includes($route.name)"
          :class="[{'!text-[#BFC3C7] !cursor-default hover:!text-[#BFC3C7] !bg-[#F8F8F8]': item.locked}, {'bg-[#F9FBFF]': [item.route,...item.actives||[]].includes($route.name) || active === i}]"
          @click="() => item.children ? setActive(i) : null"
        >
          <span
            class="text-truncate"
            :class="{'w-[120px]': item.locked}"
          >{{ item.title }}</span>

          <svg
            v-if="item.children"
            :class="{'rotate-[90deg]': active === i}"
            width="6"
            height="11"
            viewBox="0 0 6 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 9.63495L4.85 5.31749L1 1.00003"
              stroke="#6E747A"
              stroke-width="1.5"
            />
          </svg>
          <svg
            v-else-if="item.locked"
            width="13"
            height="14"
            viewBox="0 0 13 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.66667 13L11 13C11.3682 13 11.6667 12.7015 11.6667 12.3333L11.6667 5.66667C11.6667 5.29848 11.3682 5 11 5L1.66667 5C1.29848 5 1 5.29848 1 5.66667L1 12.3333C1 12.7015 1.29848 13 1.66667 13Z"
              stroke="#BFC3C7"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.48716 5L8.17947 5C8.26443 5 8.33331 4.93112 8.33331 4.84615L8.33331 3C8.33331 1.89543 7.43788 1 6.33331 1C5.22874 1 4.33331 1.89543 4.33331 3L4.33331 4.84615C4.33331 4.93112 4.40219 5 4.48716 5Z"
              stroke="#BFC3C7"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </component>

        <template v-if="active === i">
          <b-link
            v-for="(route,i) in item.children"
            :key="`${i}-child`"
            :to="{name: route.route, ...route}"
            class="text-decoration-none flex items-center hover:bg-[#F9FBFF] cursor-pointer h-[43px] pl-[34px] hover:!text-[#44474A] text-[#44474A] text-[14px] w-100 justify-between border-bottom"
            :class="{'!font-[700] !text-[#2C2C2C] bg-[#F9FBFF]': !$route.query || !route.queryKey ? [route.route,...route.actives||[]].includes($route.name) : $route.query[route.queryKey] === route.query[route.queryKey]}"
          >
            {{ route.title }}
          </b-link>
        </template>
      </div>

      <!--      <b-nav-item :active="$route.name === 'settings.not-found-page'" :to="{name: 'settings.not-found-page'}">Not Found Page</b-nav-item>-->
    </b-nav>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'ProjectSettingsNav',
  data () {
    return {
      active: 0
    }
  },
  methods: {
    checkActive () {
      const index = this.routes.findIndex(({group}) => group === this.$route.name)
      if (index > -1) this.active = index
    },
    setActive (index) {
      // if (this.active !== null) return this.active = null

      this.active = index
    }
  },
  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    ...mapState('auth', {
      user: state => state.user
    }),
    routes () {
      return [
        {
          title: 'Project Settings',
          children: [
            {
              title: 'General',
              route: 'settings.general',
              query: this.$route.query
            },
            {
              title: 'Custom Domain',
              route: 'settings.domain',
              query: this.$route.query
            },
            {
              title: 'Cloudflare Enterprise',
              route: 'settings.cloudflare-enterprise',
              statement: this.user.cf_enterprise_status && this.project.cf_token,
              query: this.$route.query
            },
            {
              title: 'Code Injection',
              route: 'settings.injection',
              query: this.$route.query
            },
            {
              title: 'Share',
              route: 'settings.share',
              query: this.$route.query
            },
            {
              title: 'SEO Settings',
              route: 'settings.seo',
              query: this.$route.query
            },
            {
              title: 'Cookie Consent',
              route: 'settings.cookie',
              query: this.$route.query
            },
            {
              title: 'Restrictions',
              route: 'settings.page_restrictions',
              query: this.$route.query
            },
            {
              title: 'Integrations',
              route: 'settings.integrations',
              actives: [
                'settings.integrations.autoresponders',
                'settings.integrations.payment-processing',
                'settings.integrations.marketing',
                'settings.integrations.analytics',
                'settings.integrations.chat'
              ],
              query: this.$route.query
            },
            {
              title: 'Fonts',
              route: 'settings.fonts',
              query: this.$route.query
            },
            {
              title: 'Recaptcha',
              route: 'settings.recaptcha',
              query: this.$route.query
            },
            {
              title: 'Global Variables',
              route: 'settings.variables',
              query: this.$route.query
            },
            {
              title: 'EGT Event Actions',
              route: 'settings.global-tracking',
              query: this.$route.query
            }
          ].filter(link => link.hasOwnProperty('statement') ? link.statement : true)
        },
        {
          title: 'Blog',
          group: 'settings.blog',
          children: [
            {
              title: 'Overview',
              route: 'settings.blog',
              queryKey: 'page',
              query: {
                page: ''
              }
            },
            {
              title: 'Categories',
              route: 'settings.blog',
              queryKey: 'page',
              query: {
                page: 'categories'
              }
            },
            {
              title: 'Authors',
              route: 'settings.blog',
              queryKey: 'page',
              query: {
                page: 'authors'
              }
            },
            {
              title: 'Posts',
              route: 'settings.blog',
              queryKey: 'page',
              query: {
                page: 'posts'
              }
            },
            {
              title: 'Blog Home Templates',
              route: 'settings.blog',
              queryKey: 'page',
              query: {
                page: 'blog-layouts'
              }
            },
            {
              title: 'Post Page Templates',
              route: 'settings.blog',
              queryKey: 'page',
              query: {
                page: 'post-templates'
              }
            },
            {
              title: 'Post Content Templates',
              route: 'settings.blog',
              queryKey: 'page',
              query: {
                page: 'post-content-templates'
              }
            }
          ]
        },
        {
          title: 'Comments',
          route: 'project.comments'
        },
        {
          title: 'Community',
          route: 'project.community'
        },
        {
          title: 'Modal Builder',
          route: 'module.modal'
        },
        {
          title: 'Mega Menu Builder',
          route: 'module.mega-menu'
        },
        {
          title: 'Marketing Funnels',
          href: `https://marketing-funnel.estage.com?project=${this.project.id}`
        },
        {
          title: 'Video Hosting',
          href: 'https://video.estage.com'
        }
        // {
        //   title: 'Affiliates',
        //   locked: true
        // },
        // {
        //   title: 'Contacts',
        //   locked: true
        // },
        // {
        //   title: 'Deadline Redirects',
        //   locked: true
        // },
        // {
        //   title: 'Ecommerce',
        //   locked: true
        // },
        // {
        //   title: 'Interactive Community'
        //   locked: true
        // },
        // {
        //   title: 'Link Shortner',
        //   locked: true
        // },
        // {
        //   title: 'Marketing Automation'
        //   locked: true
        // },
        // {
        //   title: 'Products',
        //   locked: true
        // },
        // {
        //   title: 'Support Desk',
        //   locked: true
        // },
        // {
        //   title: 'Surveys',
        //   locked: true
        // },
        // {
        //   title: 'Webinars',
        //   locked: true
        // }
      ].filter(link => link.hasOwnProperty('statement') ? link.statement : true)
    }
  },
  mounted () {
    this.checkActive()
  }
}
</script>

<style lang="scss" scoped>
.settings-nav {
  border: 1px solid #E2E5EC;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  .nav-link {
    color: #333333;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    padding: 20px 0 !important;
    margin: 0 20px;

    &.active {
      border: unset !important;
      font-weight: bold;
    }
  }
}
</style>
