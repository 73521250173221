<template>
  <b-card
    class="border-0"
    no-body
    @click="() => modal = true"
  >
    <section-item-hover class="d-flex align-items-center cursor-move border-0 hover:bg-[#FAFCFF] hover:text-[#4F83E3]">
      <icon
        class="d-block mr-3"
        icon="move.svg"
        style="display: block !important;"
      />
      <div
        class="mr-3 opacity-50"
        v-html="element.icon"
      />
      <div class="pt-1 opacity-50">
        {{ startCase(element.name) }}
      </div>

      <span class="bg-primary text-center ml-2 mt-[2px] text-white flex items-center justify-center h-[22px] w-[80px] rounded-pill text-[12px] font-bold">
        Activate
      </span>
    </section-item-hover>
    
    <purchase-plan-modal 
      v-model="modal"
      :element="element" 
      business-plus
      @add-element="$emit('add-element')"
    />
  </b-card>
</template>

<script>
import SectionItemHover from '@/components/editor/utils/SectionItemHover.vue'
import Icon from '@/components/editor/utils/Icon.vue'
import TextMixins from '@/components/mixins/TextMixins'
import PurchasePlanModal from '@/components/editor/modals/purchase-plan/PurchasePlanModal.vue'

export default {
  name: 'PurchasePlanItem',
  mixins: [TextMixins],
  components: {
    PurchasePlanModal,
    Icon,
    SectionItemHover
  },
  props: {
    element: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      modal: false
    }
  }
}
</script>