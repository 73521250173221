const sections = require.context('@builder/components/builder/elements', true, /.js/)

let sec = {}

sections.keys().forEach(fileName => {
  if (!sections(fileName).group) return false

  !sec.hasOwnProperty(sections(fileName).group) ? sec[sections(fileName).group] = [] : null
  sec[sections(fileName).group].push({
    ...sections(fileName),
    section: sections(fileName).default
  })
})

export default sec
