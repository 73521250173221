<template>
  <div>
    <b-dropdown-item
      class="d-flex align-items-center"
      link-class="dashboard-menu-link"
      @click.stop="modal = true"
    >
      <svg
        class="mr-2 mb-1"
        width="18"
        height="14"
        viewBox="0 0 18 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_2738_22579)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.583374 2.91669C0.583374 1.95019 1.36688 1.16669 2.33337 1.16669H5.83337C6.02841 1.16669 6.21055 1.26416 6.31874 1.42645L7.31223 2.91669H11.6667C12.6332 2.91669 13.4167 3.70019 13.4167 4.66669V11.0834C13.4167 12.0499 12.6332 12.8334 11.6667 12.8334H2.33337C1.36688 12.8334 0.583374 12.0499 0.583374 11.0834V2.91669ZM2.33337 2.33335C2.01121 2.33335 1.75004 2.59452 1.75004 2.91669V11.0834C1.75004 11.4055 2.01121 11.6667 2.33337 11.6667H11.6667C11.9889 11.6667 12.25 11.4055 12.25 11.0834V4.66669C12.25 4.34452 11.9889 4.08335 11.6667 4.08335H7.00004C6.805 4.08335 6.62287 3.98588 6.51468 3.8236L5.52118 2.33335H2.33337Z"
            fill="#44474A"
          />
          <rect
            x="9.625"
            y="4.11249"
            width="7.875"
            height="7.525"
            fill="white"
          />
        </g>
        <g clip-path="url(#clip1_2738_22579)">
          <path
            d="M13.5661 10.0662C13.3668 10.2655 13.3668 10.5887 13.5661 10.788C13.7655 10.9873 14.0886 10.9873 14.288 10.788L16.84 8.23592C17.0394 8.03659 17.0394 7.71341 16.84 7.51408L14.288 4.962C14.0886 4.76267 13.7655 4.76267 13.5661 4.962C13.3668 5.16133 13.3668 5.48451 13.5661 5.68384L15.2469 7.36458H6.27079C5.9889 7.36458 5.76038 7.59311 5.76038 7.875C5.76038 8.1569 5.9889 8.38542 6.27079 8.38542H15.2469L13.5661 10.0662Z"
            fill="#44474A"
          />
        </g>
        <defs>
          <clipPath id="clip0_2738_22579">
            <rect
              width="14"
              height="14"
              fill="white"
            />
          </clipPath>
          <clipPath id="clip1_2738_22579">
            <rect
              width="12.25"
              height="12.25"
              fill="white"
              transform="translate(5.25 1.75)"
            />
          </clipPath>
        </defs>
      </svg>

      Move Project To Folder
    </b-dropdown-item>

    <b-modal
      v-model="modal"
      class="position-relative"
      lazy
      centered
      body-class="py-5"
      content-class="overflow-visible new-section-modal"
      size="lg"
      hide-footer
      hide-header
    >
      <h3 class="text-center font-weight-bold mb-12 px-0">
        Move Project To Folder
      </h3>

      <b-row>
        <b-col
          offset="2"
          cols="8"
        >
          <b-form @submit.prevent="onsubmit">
            <b-form-group
              class="estage-form-group"
              label="Select Folder"
            >
              <folders-tree-select v-model="folder" />
            </b-form-group>

            <div class="text-center mt-5">
              <b-btn
                type="submit"
                variant="primary"
                class="font-weight-bold font-size-14 px-5"
                :disabled="loading"
              >
                <b-spinner
                  v-if="loading"
                  class="mr-2"
                  variant="light"
                  small
                />
                Move
              </b-btn>
            </div>
          </b-form>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import FoldersTreeSelect from './FoldersTreeSelect'

export default {
  name: 'MoveProjectModal',
  components: {FoldersTreeSelect},
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      modal: false,
      folder: 0,
      loading: false
    }
  },
  watch: {
    modal (val) {
      if (val) {
        this.folder = 0
      }
    }
  },
  methods: {
    onsubmit () {
      this.loading = true
      axios.put(`api/projects/${this.project.id}/move-to-category/${this.folder}`)
        .then(() => {
          this.$store.dispatch('dashboard/loadFolder', this.folder)
          this.modal = false
        })
    }
  }
}
</script>