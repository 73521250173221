<template>
  <div
    :class="{'sidebar-active-group': active}"
    class="ml-[-20px] mr-[-16px] sidebar-group bg-white mb-[10px] sid-select"
  >
    <button
      :class="[buttonClass]"
      class="gap-[15px] !transition w-full hover:bg-[#F9F9F9] h-[43px] !text-[15px] text-[#44474A] font-[700] pl-[23px] pr-[23px] flex items-center sidebar-group-button"
      type="button"
      @click="toggle"
    >
      <span
        v-if="move"
        class="move cursor-move mt-[-2px]"
      >
        <svg
          fill="none"
          height="11"
          viewBox="0 0 11 11"
          width="11"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.57745 0.894104H0.568359V3.8941H3.57745V0.894104Z"
            fill="#3D82EA"
          />
          <path
            d="M10.5984 0.894104H7.58936V3.8941H10.5984V0.894104Z"
            fill="#3D82EA"
          />
          <path
            d="M3.57745 7.8941H0.568359V10.8941H3.57745V7.8941Z"
            fill="#3D82EA"
          />
          <path
            d="M10.5984 7.8941H7.58936V10.8941H10.5984V7.8941Z"
            fill="#3D82EA"
          />
        </svg>
      </span>

      <svg
        :class="{'transform rotate-[180deg]': !open}"
        width="11"
        height="8"
        viewBox="0 0 11 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.783651 6.50804C0.572116 6.2965 0.572116 5.95354 0.783651 5.742L5.11698 1.40867C5.32852 1.19714 5.67148 1.19714 5.88302 1.40867L10.2164 5.742C10.4279 5.95354 10.4279 6.2965 10.2164 6.50804C10.0048 6.71957 9.66185 6.71957 9.45032 6.50804L5.5 2.55772L1.54968 6.50804C1.33815 6.71957 0.995184 6.71957 0.783651 6.50804Z"
          fill="#6E747A"
          stroke="#6E747A"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <slot name="title">
        <div class="flex items-center">
          <span v-text="title" />
          <span
            v-if="beta"
            class="bg-primary ml-2 text-white h-[17px] w-[50px] rounded-pill text-[12px] font-normal"
          >
            BETA
          </span>
          <span
            v-if="isPremium"
            class="bg-primary ml-2 text-white h-[17px] w-[56px] rounded-pill text-[12px] font-normal"
          >
            Activate
          </span>
        </div>
      </slot>
    </button>

    <div
      v-if="open"
      :class="contentClass"
      class="pl-[20px] pr-[21px] pt-[14px] pb-[22px] border-top sidebar-group-content"
    >
      <slot />
    </div>
    
    <purchase-plan-modal 
      v-model="modal"
      business-plus
    />
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import PurchasePlanModal from '@/components/editor/modals/purchase-plan/PurchasePlanModal.vue'

export default {
  name: 'SidbearSettingsGroup',
  components: {PurchasePlanModal},
  props: {
    title: {
      type: String,
      required: false
    },
    active: {
      type: Boolean,
      default: false
    },
    beta: {
      type: Boolean,
      default: false
    },
    premium: {
      type: Boolean,
      default: false
    },
    group: {
      type: Boolean,
      default: false
    },
    move: {
      type: Boolean,
      default: false
    },
    contentClass: {
      type: String
    },
    buttonClass: {
      type: String
    },
    ignoreTriggers: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      open: false,
      modal: false,
      isPremium: this.premium
    }
  },
  computed: {
    ...mapGetters('auth', {
      userRole: 'userRole'
    }),
    ...mapState('editor', {
      expandedGroup: state => state.expandedGroup
    })
  },
  watch: {
    open () {
      this.$emit('changed')
    },
    active (val) {
      this.open = !!val
    },
    expandedGroup ({$el, $parentGroup}) {
      if (this.open) {
        if (this.$el !== $el && this.$el !== $parentGroup) {
          if (this.active && this.$el.parentElement.closest('.sidebar-group')) return
          this.open = false
        }
      }
    }
  },
  created () {
    if (this.active) {
      this.open = true
    }
  },
  methods: {
    toggle () {
      if (this.isPremium) {
        this.modal = true
      } else {
        if (!this.open) {
          this.$store.commit('editor/SET_EXPANDED_GROUP', {
            $el: this.$el,
            $parentGroup: this.$el.parentElement.closest('.sidebar-group')
          })
        }

        setTimeout(() => {
          this.open = !this.open
          this.$emit('toggle', this.open)
          if (this.open) {
            this.$emit('opened')

            setTimeout(() => {
              this.$el.querySelector('button').scrollIntoView({
                behavior: 'smooth',
                block: 'start'
              })
            }, 100)
          }
        })
      }
    }
  },
  mounted () {
    if (this.$store.state.editor.businessPlusSubscription  || this.userRole.includes('support')) this.isPremium = false
  }
}
</script>

<style>
.sidebar-group .sidebar-active-group .sidebar-group-button {
  display: none;
}

.sidebar-group .sidebar-group:last-child .sidebar-group-button {
  border-bottom: none !important;
}

.sidebar-group .sidebar-active-group .sidebar-group-content {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
</style>