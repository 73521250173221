<template>
  <b-modal
    v-model="modal"
    dialog-class="!max-w-[800px]"
    content-class="rounded-[10px]"
    body-class="!px-[64px] !py-[48px]"
    hide-header
    hide-footer
    centered
    lazy
  >
    <i
      class="fa fa-close z-[50] absolute top-[15px] right-[17px] text-[16px] text-[#6E747A] transition cursor-pinter hover:opacity-60"
      @click="modal = false"
    />

    <div class="w-full flex flex-col items-center gap-y-[32px] font-[ProximaNova]">
      <template v-if="!nextChangeBilling">
        <div class="w-full flex flex-col items-center gap-[16px]">
          <div class="max-w-[600px] w-full flex items-center justify-center gap-[12px]">
            <div>
              <div class="w-[40px] h-[40px] flex items-center justify-center rounded-full bg-[#E3F7F5] text-[14px] text-[#57C6BF]">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.5 15V9M14.5 15V9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <h3 class="text-[32px] leading-[36px] font-[700] text-[#000000] mb-0">
              Put Account on Hold
            </h3>
          </div>

          <p class="text-center text-[16px] leading-[24px] font-[400] text-[#9D9D9D] mb-0">
            If you have an unexpected situation, instead of canceling or closing your account, you can put it on a '30-day hold' and still keep all your projects active. <br>
            <span class="text-[13px] leading-[24px] font-[400] text-[#9D9D9D] italic">
              Please note: you can only use this option once per year.
            </span>
          </p>
        </div>

        <div class="w-full flex flex-row items-center justify-center px-[40px] py-[32px] gap-x-[12px] rounded-[10px] border border-[#E3E5EB]">
          <p class="text-[16px] leading-[20px] font-[600] text-[#000000] mb-0">
            Account is currently active
          </p>
        </div>

        <b-btn
          variant="primary"
          size="md"
          class="min-w-[160px] h-[40px] !font-[700]"
          :disabled="loading"
          @click="holdAccount"
        >
          Put My Account on Hold
        </b-btn>
      </template>
      <template v-else-if="nextChangeBilling && onHold">
        <div class="w-full flex flex-col items-center gap-[16px]">
          <div class="max-w-[600px] w-full flex items-center justify-center gap-[12px]">
            <div>
              <div class="w-[40px] h-[40px] flex items-center justify-center rounded-full bg-[#E3F7F5] text-[14px] text-[#57C6BF]">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.5 15V9M14.5 15V9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <h3 class="text-[32px] leading-[36px] font-[700] text-[#000000] mb-0">
              Account on Hold
            </h3>
          </div>

          <p class="text-center text-[16px] leading-[24px] font-[400] text-[#9D9D9D] mb-0">
            You account is currently on hold for 30-days. <br>
            <span class="text-[13px] leading-[24px] font-[400] text-[#9D9D9D] italic">
              Please note: you can only use this option once per year.
            </span>
          </p>
        </div>

        <div class="w-full flex flex-col gap-[16px]">
          <div class="w-full flex flex-row items-center justify-center px-[40px] py-[32px] gap-x-[12px] rounded-[10px] border border-[#E3E5EB]">
            <p class="text-[16px] leading-[20px] font-[400] text-[#000000] mb-0">
              Your account is
              <span class="font-[600] text-[#000000]">
                ON HOLD
              </span>
            </p>
          </div>

          <div class="w-full flex flex-row items-center justify-center px-[40px] py-[32px] gap-x-[12px] rounded-[10px] border border-[#E3E5EB]">
            <p class="text-[16px] leading-[20px] font-[400] text-[#9D9D9D] mb-0">
              Your next billing date is
              <span class="font-[600] text-[#000000]">
                {{ moment(billingDate).format('MMM DD, YYYY') }}
              </span>
            </p>
          </div>
        </div>

        <!--        <b-btn-->
        <!--          variant="outline-dark"-->
        <!--          size="md"-->
        <!--          class="min-w-[160px] h-[40px] !font-[700]"-->
        <!--        >-->
        <!--          Unpause Account-->
        <!--        </b-btn>-->
      </template>
      <template v-else-if="nextChangeBilling && !onHold">
        <div class="w-full flex flex-col items-center gap-[16px]">
          <div class="max-w-[600px] w-full flex items-center justify-center gap-[12px]">
            <div>
              <div class="w-[40px] h-[40px] flex items-center justify-center rounded-full bg-[#FFEAEA] text-[14px] text-[#DB4848]">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 5L5 15M5 5L15 15"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <h3 class="text-[32px] leading-[36px] font-[700] text-[#000000] mb-0">
              Sorry, this Action is Unavailable
            </h3>
          </div>

          <p class="text-center text-[16px] leading-[24px] font-[400] text-[#9D9D9D] mb-0">
            Sorry, it looks like you have already put your account on hold. <br>
            You can only do this once per year.
          </p>
        </div>
      </template>
    </div>
  </b-modal>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: 'HoldAccountModal',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    onHold: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user',
      userSubscription: 'userSubscription'
    }),
    billingDate () {
      return this.userSubscription.plan_period_end
    },
    nextChangeBilling () {
      return this.user.next_change_billing !== null
    },
    modal: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    holdAccount () {
      this.loading = true
      axios.post('/api/user/payment-systems/change-billing', {
        deferment_month: true
      })
        .then(() => {
          this.$swal({
            icon: 'success',
            iconColor: '#4F83E3',
            toast: true,
            position: 'top-right',
            title: 'Success',
            text: 'Your account is on hold',
            showConfirmButton: false,
            timer: 5000
          })
          this.loading = false
          this.modal = false
          this.$emit('success')
        })
        .catch(() => {
          this.loading = false
          this.$swal({
            icon: 'error',
            iconColor: '#4F83E3',
            toast: true,
            position: 'top-right',
            title: 'Error',
            text: 'The something went wrong',
            showConfirmButton: false,
            timer: 5000
          })
        })
    }
  }
}
</script>