import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Headline'
export const group = 'Typography'

export const defaultOptions = {
  'content': {
    'description': {
      'type': 'tiptap-mod-v2',
      'display': true,
      'tag': 'h2',
      'resolutionStyle': {'sm': {}, 'md': {}, 'lg': {}},
      'text': 'Best Headline Ever'
    }
  }
}

export const icon = `<svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path d="M11.8 1.57144H14.4V2.85714H17V-1H4V2.85714H6.60001V1.57144H9.20002V14.4286H6.60001V17H14.3987V14.4286H11.8V1.57144Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="21" height="15.75" fill="white"/>
</clipPath>
</defs>
</svg>
`

export default class ElHeadline extends SectionProvider {
  constructor (options = {}) {
    super('ElHeadlineV2', options)

    this.name = name
    this.group = group
    this.css = [['.--t3x95ha--07LNjU5Qxe-text { padding: 20px; }'], [], []]
    this.sids = ['t3x95ha--07LNjU5Qxe']
    this.sid = 't3x95ha--07LNjU5Qxe'
    this.defaultOptions = defaultOptions
  }
}
