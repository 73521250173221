// import router from '@builder/router/index'
import _ from 'lodash'
import Cookie from 'js-cookie'

const state = () => ({
  authenticated: false,
  user: null
})

const getters = {
  authenticated (state) {
    return state.authenticated
  },
  user (state) {
    return state.user
  },
  userRole (state) {
    return state.user.roles.map(({name}) => name)
  },
  userSubscription (state) {
    return state.user.user_subscription
  }
}

const actions = {
  async signIn ({dispatch}, credentials) {
    if (process.env.NODE_ENV === 'development') {
      await axios.post('api/auth/login', credentials)
        .then(({data}) => {
          localStorage.setItem('sanctum_token', data.access_token)
          const loginFrame = document.getElementById('cross-iframe').contentWindow
          loginFrame.postMessage(JSON.stringify({key: 'sanctum_token', method: 'set', data: data.access_token}), '*')
          console.log('process.env.VUE_APP_MAIN_COOKIE_DOMAIN :>> ', process.env.VUE_APP_MAIN_COOKIE_DOMAIN)
          Cookie.set('cross_estage_token', data.access_token)
          return dispatch('me')
        })
    }
  },

  async forget (_, payload) {
    return await axios.post('api/auth/password/forget', payload)
  },

  async reset (_, payload) {
    return await axios.post('api/auth/password/reset', {
      ...payload,
      token: new URLSearchParams(window.location.search).get('token')
    })
  },

  async signUp ({dispatch}, credentials) {
    await axios.post('api/auth/signup', credentials)
      .then(async ({data}) => {
        localStorage.setItem('sanctum_token', data)
        const loginFrame = document.getElementById('cross-iframe').contentWindow
        loginFrame.postMessage(JSON.stringify({key: 'sanctum_token', method: 'set', data: data}), '*')

        return await dispatch('me')
      })
  },

  async signOut ({commit}) {
    commit('SET_AUTHENTICATED', false)
    commit('SET_USER', null)
    Cookie.remove('cross_estage_token')
    window.location.href = process.env.VUE_APP_AUTH_HOST + '/logout'

    if (process.env.NODE_ENV === 'development') {
      localStorage.setItem('sanctum_token', null)
    }

    // await axios.get('api/auth/logout')
    //   .then(() => {
    //     localStorage.removeItem('sanctum_token')
    //     const loginFrame = document.getElementById('cross-iframe').contentWindow
    //     loginFrame.postMessage(JSON.stringify({key: 'sanctum_token', method: 'remove'}), '*')

    //     commit('SET_AUTHENTICATED', false)
    //     commit('SET_USER', null)

    //     router.push('/login')
    //   }).finally(()=>{
    //     Cookie.remove('cross_estage_token',{ domain: process.env.VUE_APP_MAIN_COOKIE_DOMAIN })
    //   })
  },

  async me ({commit}) {
    if (process.env.NODE_ENV === 'development') {
      if (!localStorage.getItem('sanctum_token')) return false
    } else {
      if (!Cookie.get('cross_estage_token')) return false
    }

    return axios.get('api/auth/user')
      .then(({data}) => {
        commit('SET_AUTHENTICATED', true)
        commit('SET_USER', data)
        const isReactivatePage = window.location.pathname.replace(/\/$/, '') === '/reactivate'
        if (
          data.user_subscription &&
          _.includes(['ESTAGE_SUB', 'BUSINESS_Y', 'BUSINESS_PLUS_M', 'BUSINESS_PLUS_Y'], data.user_subscription.plan.code) &&
          data.user_subscription.status !== 'succeeded' &&
          !isReactivatePage
        ) {
          window.location.href = '/reactivate'
        }
      })
      .catch(() => {
        localStorage.removeItem('sanctum_token')
        const loginFrame = document.getElementById('cross-iframe').contentWindow
        loginFrame.postMessage(JSON.stringify({key: 'sanctum_token', method: 'remove'}), '*')

        commit('SET_AUTHENTICATED', false)
        commit('SET_USER', null)
      })
  }
}

const mutations = {
  SET_AUTHENTICATED (state, paylaod) {
    state.authenticated = paylaod
  },

  SET_USER (state, payload) {
    state.user = payload
  },

  UPDATE_EMAIL (state, payload) {
    state.user.email = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
