<template>
  <div
    class="stack-elements h-[43px] flex align-items-center px-[22px] border-bottom w-full text-[14px] text-[#6E747A] z-[1001] bg-white"
  >
    <button
      v-for="(item, index) in stack"
      :key="index"
      :class="[{'text-primary font-[500]': (index === stack.length - 1 && stack.length > 1) || page === 'page-settings'}, item.page ? 'font-[400]' : 'font-[500]']"
      class="p-0 flex items-center hover:text-[#3D82EA] cursor-pointer"
      @click="() => onClick(item)"
      @mouseenter="() => onMouseenter(item)"
      @mouseleave="() => onMouseleave(item)"
    >
      {{ item.name }}

      <svg
        v-if="index !== stack.length - 1"
        class="ml-[16px] mr-[18px]"
        fill="none"
        height="9"
        viewBox="0 0 9 9"
        width="9"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clip-rule="evenodd"
          d="M3.10983 1.23483C3.25628 1.08839 3.49372 1.08839 3.64017 1.23483L6.64017 4.23484C6.78661 4.38128 6.78661 4.61872 6.64017 4.76516L3.64017 7.76517C3.49372 7.91161 3.25628 7.91161 3.10983 7.76517C2.96339 7.61872 2.96339 7.38128 3.10983 7.23484L5.84467 4.5L3.10983 1.76517C2.96339 1.61872 2.96339 1.38128 3.10983 1.23483Z"
          fill="#6E747A"
          fill-rule="evenodd"
          stroke="#6E747A"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="0.2"
        />
      </svg>
    </button>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'StackElements',
  props: {
    mainCrumbTitle: {
      type: String,
      default: 'Page'
    }
  },
  computed: {
    ...mapState('editor', {
      stackElements: state => state.stackElements,
      page: state => state.page
    }),
    stack () {
      return [{
        name: this.mainCrumbTitle,
        page: true
      }, ...this.stackElements]
    }
  },
  watch: {
    page (val) {
      if (val === 'page-settings') {
        this.$store.commit('editor/CLEAR_ELEMENTS_IN_STACK')
      }
    }
  },
  methods: {
    onClick (item) {
      if (item.page) {
        return this.$store.commit('editor/SET_PAGE', 'page-settings')
      }

      if (item.onClick) {
        return item.onClick()
      }

      const $el = this.getContainer(item)
      $el.click()
      VEvent.fire('minimize', false)
    },
    onMouseenter (item) {
      if (item.page) return
      const $el = this.getContainer(item)
      const $flexCol = $el.closest('.flex-col-el')

      if ($flexCol && $flexCol.firstElementChild) {
        return $flexCol.firstElementChild.classList.add('col-highlight')
      }


      $el.classList.add('active')

      const $container = document.querySelector('.scrollbale-container')
      $container.scrollTo({
        top: ($el.getBoundingClientRect().top + $container.scrollTop) - 200,
        left: 0,
        behavior: 'smooth'
      })
    },
    onMouseleave (item) {
      if (item.page) return
      const $el = this.getContainer(item)
      const $flexCol = $el.closest('.flex-col-el')

      if ($flexCol && $flexCol.firstElementChild) {
        return $flexCol.firstElementChild.classList.remove('col-highlight')
      }

      if ($el.classList.contains('in-stack')) return
      $el.classList.remove('active')
    },
    getContainer (item) {
      let $el = item.$el

      if (!$el.classList.contains('highlight-new')) {
        $el = $el.querySelector('.highlight-new')
      }

      return $el
    }
  }
}
</script>