<template>
  <vue-select
    v-if="scopedSlots"
    ref="select"
    v-model="model"
    class="custom-select"
    v-bind="$attrs"
    append-to-body
    :calculate-position="withPopper"
    @option:selected="e => $emit('option:selected', e)"
    @search:focus="openedSelect"
    @open="e => $emit('open', e)"
    @close="e => $emit('close', e)"
    @search="e => $emit('search', e)"
  >
    <template
      v-for="(_, slot) in scopedSlots"
      v-slot:[slot]="props"
    >
      <slot
        :name="slot"
        v-bind="props"
      />
    </template>
  </vue-select>
</template>

<script>
import popperMixin from '../mixins/popperMixin'

export default {
  name: 'VSelect',

  mixins: [popperMixin],

  props: {
    value: {
      required: true
    },
    width: {
      type: String
    },
    offsetLeft: {
      type: Number,
      default: 0
    },
    dropDownClasses: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      scopedSlots: null
    }
  },

  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },

  mounted () {
    let scopedSlots = {}
    for (let slot in this.$scopedSlots) {
      scopedSlots[slot] = this.$scopedSlots[slot]
    }
    this.scopedSlots = scopedSlots
  },

  methods: {
    openedSelect () {
      this.$nextTick(() => {
        const menu = document.querySelector('.vs__dropdown-menu')
        if (!menu) return
        const active = menu.querySelector('.vs__dropdown-option--selected')
        if (active) {
          menu.scrollTo(0, active.offsetTop - (menu.offsetHeight / 2))
        }
        if (this.width) {
          menu.style.width = this.width
        }
        if(this.dropDownClasses){
          const cls = this.dropDownClasses.split(' ')
          for (const cl of cls) {
            menu.classList.add(cl)
          }
        }
      })
    }
  }
}
</script>
<style>
.vs--loading .vs__spinner{
  background-color: #fff;
}
.select-tags.vs--loading .vs__spinner{
  margin-right: -10px;
}
</style>
