<template>
  <div class="flex gap-[6px]">
    <b-btn
      variant="white"
      class="w-[29px] h-[29px] flex items-center justify-center p-0"
      @click="() => alignMutation = 'normal'"
    >
      <svg
        width="12"
        height="10"
        viewBox="0 0 12 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :style="{fill: alignMutation === 'normal' ? '#7CACFA' : '#333333'}"
      >
        <path
          d="M0.5 0.5C0.5 0.223858 0.723858 0 1 0H11C11.2761 0 11.5 0.223858 11.5 0.5C11.5 0.776142 11.2761 1 11 1H1C0.723858 1 0.5 0.776142 0.5 0.5Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.5 2.5C1.5 2.22386 1.72386 2 2 2H5C5.27614 2 5.5 2.22386 5.5 2.5V9.5C5.5 9.77614 5.27614 10 5 10H2C1.72386 10 1.5 9.77614 1.5 9.5V2.5ZM2.5 3V9H4.5V3H2.5Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7 2C6.72386 2 6.5 2.22386 6.5 2.5V6.5C6.5 6.77614 6.72386 7 7 7H10C10.2761 7 10.5 6.77614 10.5 6.5V2.5C10.5 2.22386 10.2761 2 10 2H7ZM7.5 6V3H9.5V6H7.5Z"
        />
      </svg>
    </b-btn>
    <b-btn
      variant="white"
      class="w-[29px] h-[29px] flex items-center justify-center p-0"
      @click="() => alignMutation = 'center'"
    >
      <svg
        width="11"
        height="12"
        viewBox="0 0 11 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :style="{fill: alignMutation === 'center' ? '#7CACFA' : '#333333'}"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.935065 0.6C0.935065 0.268629 1.14439 0 1.4026 0H4.20779C4.466 0 4.67533 0.268629 4.67533 0.6V5.4H5.61039V3.6C5.61039 3.26863 5.81971 3 6.07792 3H8.88312C9.14133 3 9.35065 3.26863 9.35065 3.6V5.4H9.81818C10.0764 5.4 10.2857 5.66863 10.2857 6C10.2857 6.33137 10.0764 6.6 9.81818 6.6H9.35065V8.4C9.35065 8.73137 9.14133 9 8.88312 9H6.07792C5.81971 9 5.61039 8.73137 5.61039 8.4V6.6H4.67533V11.4C4.67533 11.7314 4.466 12 4.20779 12H1.4026C1.14439 12 0.935065 11.7314 0.935065 11.4V6.6H0.467533C0.209321 6.6 0 6.33137 0 6C0 5.66863 0.209321 5.4 0.467533 5.4H0.935065V0.6ZM6.54546 6.6V7.8H8.41559V6.6H6.54546ZM8.41559 5.4V4.2H6.54546V5.4H8.41559ZM3.74026 1.2V5.4H1.87013V1.2H3.74026ZM1.87013 10.8V6.6H3.74026V10.8H1.87013Z"
        />
      </svg>
    </b-btn>
    <b-btn
      variant="white"
      class="w-[29px] h-[29px] flex items-center justify-center p-0"
      @click="() => alignMutation = 'end'"
    >
      <svg
        width="12"
        height="10"
        viewBox="0 0 12 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :style="{fill: alignMutation === 'end' ? '#7CACFA' : '#333333'}"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.5 0.5C1.5 0.223858 1.72386 0 2 0H5C5.27614 0 5.5 0.223858 5.5 0.5V7.5C5.5 7.77614 5.27614 8 5 8H2C1.72386 8 1.5 7.77614 1.5 7.5V0.5ZM2.5 1V7H4.5V1H2.5Z"
        />
        <path
          d="M0.5 9.5C0.5 9.22386 0.723858 9 1 9H11C11.2761 9 11.5 9.22386 11.5 9.5C11.5 9.77614 11.2761 10 11 10H1C0.723858 10 0.5 9.77614 0.5 9.5Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7 2.5C6.72386 2.5 6.5 2.72386 6.5 3V7.5C6.5 7.77614 6.72386 8 7 8H10C10.2761 8 10.5 7.77614 10.5 7.5V3C10.5 2.72386 10.2761 2.5 10 2.5H7ZM7.5 7V3.5H9.5V7H7.5Z"
        />
      </svg>
    </b-btn>
  </div>
</template>

<script>
import SidConcept from '@/components/mixins/SidConcept'

export default {
  name: 'SidVerticalAlign',
  mixins: [SidConcept],
  data () {
    return {
      align: null
    }
  },
  computed: {
    alignMutation: {
      get () {
        return this.align
      },
      set (val) {
        this.align = val
        this.addSidRules([[this.getSid(), [this.ruleName, val]]])
      }
    }
  },
  mounted () {
    this.align = this.getSidStyle(this.getSid(), this.ruleName)
    if (!this.align) {
      this.align = this.defaultValue ? this.defaultValue : 'normal'
    }
  }
}
</script>