<template>
  <div class="dashboard-space dashboard-background min-vh-100">
    <dashboard-header />

    <!-- Topbar -->
    <dashboard-topbar title="Select a Template for Your Project" />
    <!-- End Topbar -->

    <div class="dashboard-container pt-5">
      <div class="flex">
        <b-link
          to="/templates"
          class="flex items-center text-[16px] text-[#B5BBC5] hover:text-[#B5BBC5] text-decoration-none group mb-[48px]"
        >
          <svg
            width="16"
            height="8"
            viewBox="0 0 16 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="group-hover:-translate-x-1 transition mr-[9px]"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.13803 0.195262C5.39838 0.455612 5.39838 0.877722 5.13803 1.13807L2.94277 3.33333H14.6666C15.0348 3.33333 15.3333 3.63181 15.3333 4C15.3333 4.36819 15.0348 4.66667 14.6666 4.66667H2.94277L5.13803 6.86193C5.39838 7.12228 5.39838 7.54439 5.13803 7.80474C4.87768 8.06509 4.45557 8.06509 4.19522 7.80474L0.861888 4.4714C0.601539 4.21106 0.601539 3.78894 0.861888 3.5286L4.19522 0.195262C4.45557 -0.0650874 4.87768 -0.0650874 5.13803 0.195262Z"
              fill="#B5BBC5"
            />
          </svg>

          <span class="group-hover:opacity-60">Return to Templates</span>
        </b-link>
      </div>

      <!-- Template -->
      <div class="min-vh-100 pb-[100px]">
        <b-row class="gap-[92px]">
          <b-col>
            <b-skeleton-wrapper
              :loading="loading"
              class="mb-[20px]"
            >
              <template #loading>
                <b-skeleton class="h-[649px] w-100" />
              </template>

              <img
                class="rounded-[4px] template-preview mb-[20px] img-fluid"
                :src="template.cover"
                data-aos="fade-up"
                data-aos-delay="50"
                data-aos-duration="600"
                data-aos-easing="ease-in-out"
                data-aos-mirror="true"
                data-aos-once="false"
              >
            </b-skeleton-wrapper>

            <b-skeleton-wrapper
              :loading="loading"
            >
              <template #loading>
                <b-skeleton class="h-[38px] w-100" />
              </template>

              <div class="flex flex-wrap justify-center items-center gap-[13px]">
                <a
                  v-if="template.purchased"
                  class="flex text-decoration-none cursor-pointer items-center justify-between text-primary rounded-[3px] bg-white h-[38px] w-[218px] !px-[11px] shadow-[0_2px_3px_rgba(0,0,0,0.07)] transition hover:shadow-none hover:!opacity-80"
                  data-aos="fade-up"
                  data-aos-delay="50"
                  data-aos-duration="600"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  :href="template.view_link"
                  target="_blank"
                >
                  <span>View Live Demo</span>

                  <svg
                    width="13"
                    height="11"
                    viewBox="0 0 13 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.20833 5.5C9.20833 6.99577 7.99577 8.20833 6.5 8.20833C5.00423 8.20833 3.79167 6.99577 3.79167 5.5C3.79167 4.00423 5.00423 2.79167 6.5 2.79167C7.99577 2.79167 9.20833 4.00423 9.20833 5.5ZM8.125 5.5C8.125 6.39746 7.39746 7.125 6.5 7.125C5.60254 7.125 4.875 6.39746 4.875 5.5C4.875 4.60254 5.60254 3.875 6.5 3.875C7.39746 3.875 8.125 4.60254 8.125 5.5Z"
                      fill="#4F83E3"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M13 5.5C13 6.85417 10.0899 10.375 6.5 10.375C2.91015 10.375 0 7.125 0 5.5C0 3.875 2.91015 0.625 6.5 0.625C10.0899 0.625 13 4.14583 13 5.5ZM11.9164 5.49942L11.9165 5.5L11.9164 5.50058C11.9151 5.51129 11.907 5.57849 11.8564 5.71049C11.8006 5.85591 11.7085 6.04043 11.5734 6.25429C11.3029 6.6824 10.8945 7.17344 10.375 7.63695C9.32259 8.57589 7.94352 9.29167 6.5 9.29167C5.03055 9.29167 3.64734 8.62102 2.6118 7.7269C2.09828 7.28351 1.69763 6.80777 1.43282 6.3785C1.15313 5.9251 1.08333 5.62003 1.08333 5.5C1.08333 5.37997 1.15313 5.0749 1.43282 4.6215C1.69763 4.19223 2.09828 3.71649 2.6118 3.2731C3.64734 2.37898 5.03055 1.70833 6.5 1.70833C7.94352 1.70833 9.32259 2.42411 10.375 3.36305C10.8945 3.82656 11.3029 4.3176 11.5734 4.74571C11.7085 4.95957 11.8006 5.14409 11.8564 5.28951C11.907 5.42151 11.9151 5.48871 11.9164 5.49942Z"
                      fill="#4F83E3"
                    />
                  </svg>
                </a>
                <div
                  v-if="!template.purchased"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="600"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                >
                  <purchase-button
                    v-if="false"
                    :template="template"
                    @click="purchase"
                  />
                </div>
                <a
                  v-else
                  class="flex items-center text-decoration-none justify-between hover:bg-[#6795EB] text-white !px-[11px] rounded-[3px] bg-[#4F83E3] h-[38px] w-[218px]"
                  data-aos-delay="100"
                  data-aos="fade-up"
                  data-aos-duration="600"
                  data-aos-easing="ease-in-out"
                  :href="hash[template.id]"
                >
                  <span>Use Template</span>

                  <svg
                    width="18"
                    height="10"
                    viewBox="0 0 18 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.2197 8.21967C11.9268 8.51256 11.9268 8.98744 12.2197 9.28033C12.5126 9.57322 12.9874 9.57322 13.2803 9.28033L17.0303 5.53033C17.3232 5.23744 17.3232 4.76256 17.0303 4.46967L13.2803 0.71967C12.9874 0.426777 12.5126 0.426777 12.2197 0.71967C11.9268 1.01256 11.9268 1.48744 12.2197 1.78033L14.6893 4.25H1.5C1.08579 4.25 0.75 4.58579 0.75 5C0.75 5.41421 1.08579 5.75 1.5 5.75H14.6893L12.2197 8.21967Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
            </b-skeleton-wrapper>
          </b-col>

          <b-col>
            <b-skeleton-wrapper
              :loading="loading"
              class="!mb-[22px]"
            >
              <template #loading>
                <b-skeleton class="w-[200px] h-[26px]" />
              </template>

              <h2
                class="text-[26px] text-dark font-[600] !mb-[22px]"
                data-aos="fade-left"
                data-aos-delay="50"
                data-aos-duration="600"
                data-aos-easing="ease-in-out"
                data-aos-mirror="true"
                data-aos-once="false"
                v-html="template.name"
              />
            </b-skeleton-wrapper>

            <b-skeleton-wrapper
              :loading="loading"
              class="mb-[43px]"
            >
              <template #loading>
                <b-skeleton class="h-[40px]" />
              </template>

              <div class="flex items-center flex-wrap gap-[20px] justify-between mb-[43px]">
                <div class="flex gap-[10px] flex-wrap max-w-[400px]">
                  <div
                    v-for="(category, index) in template.categories"
                    :key="`categories-${index}`"
                    class="flex items-center justify-center bg-[#E2E5EC] text-[12px] text-[#939BAE] h-[28px] px-[18px] rounded-pill"
                    data-aos="fade-up"
                    :data-aos-delay="index * 50"
                    data-aos-duration="600"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    v-text="category.category.name"
                  />

                  <div
                    v-for="(theme, index) in template.themes"
                    :key="`themes-${index}`"
                    class="flex items-center justify-center bg-[#E2E5EC] text-[12px] text-[#939BAE] h-[28px] px-[18px] rounded-pill"
                    data-aos="fade-up"
                    :data-aos-delay="index * 50"
                    data-aos-duration="600"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    v-text="theme.theme.name"
                  />
                </div>

                <!--                <purchase-button-->
                <!--                  v-if="!template.purchased"-->
                <!--                  :template="template"-->
                <!--                  data-aos-delay="50"-->
                <!--                  data-aos="fade-in"-->
                <!--                  data-aos-duration="600"-->
                <!--                  data-aos-easing="ease-in-out"-->
                <!--                  @click="purchase"-->
                <!--                />-->
                <a
                  v-if="template.purchased"
                  class="flex items-center text-decoration-none justify-between hover:bg-[#6795EB] text-white !px-[11px] rounded-[3px] bg-[#4F83E3] h-[38px] w-[218px]"
                  data-aos-delay="50"
                  data-aos="fade-up"
                  data-aos-duration="600"
                  data-aos-easing="ease-in-out"
                  :href="hash[template.id]"
                >
                  <span>Use Template</span>

                  <svg
                    width="18"
                    height="10"
                    viewBox="0 0 18 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.2197 8.21967C11.9268 8.51256 11.9268 8.98744 12.2197 9.28033C12.5126 9.57322 12.9874 9.57322 13.2803 9.28033L17.0303 5.53033C17.3232 5.23744 17.3232 4.76256 17.0303 4.46967L13.2803 0.71967C12.9874 0.426777 12.5126 0.426777 12.2197 0.71967C11.9268 1.01256 11.9268 1.48744 12.2197 1.78033L14.6893 4.25H1.5C1.08579 4.25 0.75 4.58579 0.75 5C0.75 5.41421 1.08579 5.75 1.5 5.75H14.6893L12.2197 8.21967Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
            </b-skeleton-wrapper>

            <b-skeleton-wrapper
              :loading="loading"
              class="mb-[43px]"
            >
              <template #loading>
                <b-skeleton class="h-[200px]" />
              </template>

              <p
                class="text-[#3C4043] text-[16px] leading-[26px] mb-[43px]"
                data-aos-delay="50"
                data-aos="fade-up"
                data-aos-duration="600"
                data-aos-easing="ease-in-out"
                v-html="template.description"
              />
            </b-skeleton-wrapper>


            <b-skeleton-wrapper
              :loading="loading"
              class="mb-[43px]"
            >
              <template #loading>
                <div class="flex gap-[50px]">
                  <b-skeleton class="h-[120px] w-[120px]" />
                  <b-skeleton class="h-[120px] w-[120px]" />
                  <b-skeleton class="h-[120px] w-[120px]" />
                  <b-skeleton class="h-[120px] w-[120px]" />
                </div>
              </template>

              <h5
                class="text-dark text-[18px] !mb-[29px]"
                data-aos="fade-up"
                data-aos-delay="50"
                data-aos-duration="600"
                data-aos-easing="ease-in-out"
                data-aos-mirror="true"
              >
                Includes:
              </h5>

              <div class="flex items-center gap-[50px] flex-wrap">
                <div
                  v-for="(page, index) in template.pages"
                  :key="index"
                  data-aos="fade-up"
                  :data-aos-delay="index * 50"
                  data-aos-duration="600"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                >
                  <div
                    class="h-[120px] w-[120px] bg-top include-page rounded-[3px] mb-[9px] bg-no-repeat bg-cover"
                    :style="{backgroundImage: `url(${page.cover})`}"
                  />
                  <h6
                    class="text-[14px] text-dark font-normal mb-0"
                    v-text="page.name"
                  />
                </div>
              </div>
            </b-skeleton-wrapper>
          </b-col>
        </b-row>
      </div>
      <!-- End Template -->
    </div>

    <create-project-modal v-model="selectedTemplateData" />
  </div>
</template>

<script>
import DashboardHeader from '../../layouts/dashboard/DashboardHeader'
import DashboardTopbar from '../../layouts/dashboard/DashboardTopbar'
import PurchaseButton from '@/components/editor/components/PurchaseButton'
import CreateProjectModal from '@/layouts/dashboard/compontens/templates/CreateProjectModal'
import _ from 'lodash'

export default {
  name: 'Dashboard',
  components: {CreateProjectModal, PurchaseButton, DashboardTopbar, DashboardHeader},
  data () {
    return {
      loading: true,
      selectedTemplateData: {},
      template: {
        name: '',
        purchased: false,
        price: 0,
        categories: [],
        description: '',
        pages: []
      },
      hash: {
        22: 'https://app.estage.com/project-import/cc3813e27e0b63051a8ce5d4ae1bf2cc?saveMode=1',
        23: 'https://app.estage.com/project-import/a6cc1c3ffc1e99bde19d50431eb1361a?saveMode=1',
        24: 'https://app.estage.com/project-import/08228008b894a18fa274c7456e9dc3e3?saveMode=1',
        26: 'https://app.estage.com/project-import/478ecc3a71df85bcf96e99899121f606?saveMode=1',
        27: 'https://app.estage.com/project-import/950206bb715ee0467200ff11045fb7d1?saveMode=1',
        28: 'https://app.estage.com/project-import/7b09e903a3bf47517946e00d77b308d7?saveMode=1',
        29: 'https://app.estage.com/project-import/9d1cdaa3e88806662385abd660c55620?saveMode=1',
        30: 'https://app.estage.com/project-import/3692b2336910ec1c02e820d542c79ced?saveMode=1',
        31: 'https://app.estage.com/project-import/5f37363eb4f9aa0303f5469cf67c31b3?saveMode=1',
        33: 'https://app.estage.com/project-import/cb9c7d980f04f3656d3c1f84131c0e24?saveMode=1',
        34: 'https://app.estage.com/project-import/375cd32a7c7a3eb2a9cdae98bb4928b9?saveMode=1',
        35: 'https://app.estage.com/project-import/c4ff10dc7ba596265c8d1b45ba487a0e?saveMode=1',
        36: 'https://app.estage.com/project-import/78db45e103b708e3cee1c7a339c8538f?saveMode=1',
        37: ''
      }
    }
  },
  async created () {
    await axios.get(`api/templates/${this.$route.params.id}`)
      .then(({data}) => {
        this.template.id = data.data.id
        this.template.name = data.data.name
        this.template.cover = data.data.cover
        this.template.description = data.data.description
        this.template.categories = data.data.categories_rel
        this.template.themes = _.uniqBy(data.data.themes, 'theme_id')
        this.template.purchased = data.data.is_free
        this.template.price = data.data.amount
        this.template.view_link = data.data.view_link
        this.template.pages = data.data.pages
      })
      .finally(() => {
        this.loading = false
      })
  },
  mounted () {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  },
  methods: {
    purchase () {

    },
    useTemplate () {
      this.selectedTemplateData = {id: this.template.id, name: this.template.name}
    }
  }
}
</script>

<style lang="scss">
.include-page {
  box-shadow: 0px 1.93548px 5.41935px rgba(0, 0, 0, 0.05);
}
</style>

