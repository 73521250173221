<template>
  <div class="ml-[-20px] mr-[-15px] pl-[20px] pr-[21px] sid-select">
    <SidbearSettingsGroup
      active
      title="Timer"
      content-class="pb-0"
    >
      <b-form-group v-if="linked">
        <button
          class="flex p-0 text-[11px] hover:bg-gray-100 items-center text-dark border rounded-[4px] w-[114px] h-[30px] justify-content-center gap-[5px] cursor-pointer font-[500] hover:text-[#3D82EA] text-[#7E7E7E]"
          @click="() => unlinkSid()"
        >
          Unlink Styles
          <i class="fa fa-link text-primary" />
        </button>
      </b-form-group>

      <b-form-group label="Timezone">
        <v-select
          v-model="timeZone"
          :options="timezones"
        />
      </b-form-group>

      <b-form-group>
        <b-checkbox
          v-model="countdownType"
          value="date"
        >
          Set Countdown End Date & Time
        </b-checkbox>
      </b-form-group>

      <b-form-group>
        <date-picker
          v-model="fromDate"
          :clearable="false"
          :disabled-date="disabledBeforeToday"
          class="w-100"
          format="YYYY-MM-DD HH:mm"
          input-class="form-control"
          type="datetime"
          value-type="format"
        />
      </b-form-group>

      <b-form-group>
        <b-checkbox
          v-model="countdownType"
          value="time"
        >
          Set Countdown Duration hh:mm:ss
        </b-checkbox>
      </b-form-group>

      <b-form-group v-if="countdownType === 'time'">
        <b-checkbox
          v-model="section.options.content.time.onPageLoad"
        >
          Start On Page Load
        </b-checkbox>
      </b-form-group>

      <b-form-group>
        <date-picker
          v-model="fromTime"
          :clearable="false"
          class="w-100"
          format="HH:mm:ss"
          input-class="form-control"
          type="time"
          value-type="format"
        />
      </b-form-group>

      <b-form-group>
        <sid-checkbox
          unchecked-value="block"
          value="none"
          rule-name="display"
          :sid="`.--${section.sid}-label`"
        >
          Hide Label
        </sid-checkbox>
      </b-form-group>

      <checklist-customs-v2 :config="section.options.customize.style.dontShow" />

      <timer-expire-actions
        v-model="actions"
        :config="config"
      />

      <sid-buttons-group
        label="Horizontal Alignment"
        :options="[
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            value: 'start',
            tooltip: 'Start'
          },
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            value: 'center',
            tooltip: 'Center'
          },
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            value: 'end',
            tooltip: 'End'
          },
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'14\' height=\'13\' viewBox=\'0 0 14 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.02612\' height=\'8.41616\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 4.4834 2.28711)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.02612\' height=\'8.41616\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 11.7031 2.28711)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.1734\' height=\'0.701346\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.701172 12.0225)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.1734\' height=\'0.701346\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 13.1602 12.0225)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            value: 'space-between',
            tooltip: 'Between'
          },
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'13\' height=\'14\' viewBox=\'0 0 13 14\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.26467\' height=\'9.07958\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 5.48535 2.51978)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.26467\' height=\'9.07958\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 10.0303 2.51978)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'12.0541\' height=\'0.756631\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.756836 13.0225)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'12.0541\' height=\'0.756631\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 12.25 13.0225)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            value: 'space-around',
            tooltip: 'Around'
          }
        ]"
        :sid="`.--${section.sid}-wrapper`"
        rule-name="justify-content"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Dial Style">
      <b-form-group>
        <div class="flex items-center gap-[10px]">
          <sid-color
            :sid="`.--${section.sid}-dial`"
            rule-name="color"
          />

          <label class="mb-0 pb-0">Color</label>
        </div>
      </b-form-group>

      <sid-font
        :sid="`.--${section.sid}-dial`"
      />
      
      <sid-range-slider
        label="Font Size"
        :sid="`.--${section.sid}-wrapper`"
        rule-name="font-size"
        default-dimension="px"
        var="--counter-size"
        :dimensions="[]"
      />

      <sid-range-slider
        label="Gap"
        :sid="`.--${section.sid}-wrapper`"
        rule-name="gap"
        default-dimension="px"
        :dimensions="[]"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Labels Style">
      <b-form-group>
        <div class="flex items-center gap-[10px]">
          <sid-color
            :sid="`.--${section.sid}-label`"
            rule-name="color"
          />

          <label class="mb-0 pb-0">Color</label>
        </div>
      </b-form-group>

      <sid-font
        :sid="`.--${section.sid}-label`"
      />

      <sid-range-slider
        label="Font Size"
        :sid="`.--${section.sid}-label`"
        rule-name="font-size"
        default-dimension="px"
        :dimensions="[]"
      />

      <sid-range-slider
        label="Gap"
        :sid="`.--${section.sid}-label`"
        rule-name="margin-top"
        default-dimension="px"
        :dimensions="[]"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Separator"
    >
      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="mb-0 pb-0">Separator</label>

          <b-checkbox
            v-model="enabledSeparator"
            size="lg"
            switch
            class="mt-[-6px]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <div class="flex items-center gap-[10px]">
          <sid-color
            :sid="`.--${section.sid}-dial.separator`"
            rule-name="color"
          />

          <label class="mb-0 pb-0">Color</label>
        </div>
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Background"
    >
      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="mb-0 pb-0">Background</label>

          <b-checkbox
            v-model="shapeEnabled"
            size="lg"
            switch
            class="mt-[-6px]"
          />
        </div>
      </b-form-group>

      <template v-if="shapeEnabled">
        <sid-background :sid="`.--${section.sid}--digit.bg .dial`" />

        <sid-range-slider
          label="Height"
          :sid="`.--${section.sid}--digit.bg .dial`"
          rule-name="--counter-height"
          var="--counter-height"
        />

        <sid-range-slider
          label="Width"
          :sid="`.--${section.sid}--digit.bg .dial`"
          rule-name="--counter-width"
          var="--counter-width"
        />

        <SidbearSettingsGroup title="Border & Radius">
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--digit.bg .dial`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--digit.bg .dial`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--digit.bg .dial`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--digit.bg .dial`"
            rule-name="border"
            class="mb-0"
            :angles="[
              {
                text: '',
                value: 'top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius'
              }
            ]"
          />
        </SidbearSettingsGroup>
        <SidbearSettingsGroup title="Drop Shadow">
          <b-form-group
            label="Shadow Color"
            class="mb-0"
          >
            <sid-box-shadow
              :sid="`.--${section.sid}--digit.bg .dial`"
              rule-name="box-shadow"
            />
          </b-form-group>
        </SidbearSettingsGroup>
      </template>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid}-wrapper`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
      
      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}-wrapper`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import {mapGetters, mapState} from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import _ from 'lodash'
import TimezonesMixin from '../../mixins/TimezonesMixin'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import TimerExpireActions from '@/components/editor/components/timer/TimerExpireActions'
import SidStyles from '@/components/mixins/SidStyles'
import SidButtonsGroup from '@/components/editor/components/sid-controls/SidButtonsGroup'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'
import SidFont from '@/components/editor/components/sid-controls/sid-control-pressets/font/SidFont'
import SidColor from '@/components/editor/components/sid-controls/SidColor'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground'
import SidBoxShadow from '@/components/editor/components/sid-controls/sid-control-pressets/box-shadow/SidBoxShadow'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup'
import moment from 'moment-timezone'
import SidCheckbox from '@/components/editor/components/sid-controls/SidCheckbox.vue'

export default {
  name: 'ButtonMod',
  components: {
    SidCheckbox,
    SidLinkedGroup,
    SidBoxShadow,
    SidBackground,
    SidSelect,
    SidColor,
    SidFont,
    SidRangeSlider,
    SidButtonsGroup,
    TimerExpireActions, SidbearSettingsGroup, DatePicker
  },
  mixins: [ModMixin, TimezonesMixin, SidStyles],

  data () {
    return {
      fromDate: this.moment().add(1, 'days').format('YYYY-MM-DD HH:mm'),
      fromTime: this.config.offsetTime || this.moment().add(1, 'hours').format('HH:mm:ss')
    }
  },

  computed: {
    shapeEnabled: {
      get () {
        return _.get(this.section.options.content.time, ['shapeEnabled', this.resolutioner], _.get(this.section.options.content.time, ['shapeEnabled', 'lg'], true))
      },
      set (val) {
        this.$set(this.section.options.content.time, 'shapeEnabled', {..._.set(this.section.options.content.time.shapeEnabled, [this.resolutioner], val)})
      }
    },
    shapeHeight: {
      get () {
        return _.get(this.section.options.content.time, ['shapeHeight', this.resolutioner], _.get(this.section.options.content.time, ['shapeHeight', 'lg'], parseInt(this.dialSize + 20)))
      },
      set (val) {
        this.$set(this.section.options.content.time, 'shapeHeight', {..._.set(this.section.options.content.time['shapeHeight'] || {}, [this.resolutioner], val)})
      }
    },
    shapeWidth: {
      get () {
        return _.get(this.section.options.content.time, ['shapeWidth', this.resolutioner], _.get(this.section.options.content.time, ['shapeWidth', 'lg'], parseInt(this.dialSize + 20)))
      },
      set (val) {
        this.$set(this.section.options.content.time, 'shapeWidth', {..._.set(this.section.options.content.time['shapeWidth'] || {}, [this.resolutioner], val)})
      }
    },
    enabledSeparator: {
      get () {
        return _.get(this.section.options.content.time.separator, ['enabled', this.resolutioner], _.get(this.section.options.content.time.separator.enabled, ['lg'], true))
      },
      set (val) {
        this.$set(this.section.options.content.time.separator, 'enabled', {..._.set(this.section.options.content.time.separator.enabled, [this.resolutioner], val)})
      }
    },
    separatorColor: {
      get () {
        return _.get(this.section.options.content.time, ['separatorColor', this.resolutioner], _.get(this.section.options.content.time, ['separatorColor', 'lg'], this.section.options.content.time.dial.color))
      },
      set (val) {
        this.$set(this.section.options.content.time, 'separatorColor', {..._.set(this.section.options.content.time['separatorColor'] || {}, [this.resolutioner], val)})
      }
    },
    horizontalAlign: {
      get () {
        return _.get(this.section.options.content.time, ['horizontalAlign', this.resolutioner], _.get(this.section.options.content.time, ['horizontalAlign', 'lg'], 'center'))
      },
      set (val) {
        this.$set(this.section.options.content.time, 'horizontalAlign', {..._.set(this.section.options.content.time['horizontalAlign'] || {}, [this.resolutioner], val)})
      }
    },
    hideElUID () {
      if (!this.config.hideElUID) {
        this.$set(this.config, 'hideElUID', {})
      }

      return this.config.hideElUID
    },
    dialGap: {
      get () {
        return _.get(this.section.options.content.time.dial, ['gap', this.resolutioner], _.get(this.section.options.content.time.dial, ['gap', 'lg'], 30))
      },
      set (val) {
        this.$set(this.section.options.content.time.dial, 'gap', {..._.set(this.section.options.content.time.dial['gap'] || {}, [this.resolutioner], val)})
      }
    },
    dialSize: {
      get () {
        return _.get(this.section.options.content.time.dial, ['size', this.resolutioner], _.get(this.section.options.content.time.dial, ['size', 'lg'], 30))
      },
      set (val) {
        this.$set(this.section.options.content.time.dial, 'size', {..._.set(this.section.options.content.time.dial['size'] || {}, [this.resolutioner], val)})
      }
    },
    titleGap: {
      get () {
        return _.get(this.section.options.content.time.title, ['gap', this.resolutioner], _.get(this.section.options.content.time.title, ['gap', 'lg'], 30))
      },
      set (val) {
        this.$set(this.section.options.content.time.title, 'gap', {..._.set(this.section.options.content.time.title['gap'] || {}, [this.resolutioner], val)})
      }
    },
    titleSize: {
      get () {
        return _.get(this.section.options.content.time.title, ['size', this.resolutioner], _.get(this.section.options.content.time.title, ['size', 'lg'], 30))
      },
      set (val) {
        this.$set(this.section.options.content.time.title, 'size', {..._.set(this.section.options.content.time.title['size'] || {}, [this.resolutioner], val)})
      }
    },
    ...mapState({
      globalStyles: state => state.editor.globalStyles
    }),
    margin () {
      if (!this.section.options.customize.style.margin) {
        this.$set(this.section.options.customize.style, 'margin', {})
      }

      return this.section.options.customize.style.margin
    },
    ...mapState('editor', {
      resolution: state => state.resolution,
      tablet: state => state.tablet
    }),
    ...mapState('projects', {
      project: state => state.project
    }),
    countdownType: {
      get () {
        return this.config.countdownType || 'date'
      },
      set (val) {
        this.$set(this.config, 'countdownType', val)
      }
    },
    timeZone: {
      get () {
        return this.config.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      set (val) {
        this.$set(this.config, 'timeZone', val)
        this.$set(this.config, 'origTimezone', Intl.DateTimeFormat().resolvedOptions().timeZone)
      }
    },
    ...mapGetters('pages', {
      pages: 'getPages'
    }),
    action: {
      get () {
        return this.config.action || null
      },
      set (val) {
        this.$set(this.config, 'action', val)
      }
    },
    actions: {
      get () {
        return this.config.actions || []
      },
      set (val) {
        this.$set(this.config, 'actions', val)
      }
    },
    onPageLoad: {
      get () {
        return this.config.onPageLoad || false
      },
      set (val) {
        this.$set(this.config, 'onPageLoad', val)
      }
    },
    getTargetDate () {
      if (this.countdownType === 'date') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.onPageLoad = false
        return moment(this.fromDate).format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.$set(this.config, 'offsetTime', this.fromTime)
        return new Date().getTime() + this.moment.duration(this.fromTime).asMilliseconds()
      }
    }
  },

  watch: {
    timeZone () {
      this.update(this.getTargetDate)
    },
    getTargetDate (val) {
      this.update(val)
    }
  },

  mounted () {
    if (this.config.end_date) {
      this.fromDate = this.config.end_date
    }
  },

  methods: {
    selectLayout (layout) {
      const section = layout
      this.$set(this.section, 'component', section.component)
      this.$set(this.section, 'name', section.name)
      this.$set(this.section, 'options', section.options)
    },
    disabledBeforeToday (date) {
      const today = this.moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm')

      return this.moment(date).isBefore(today)
    },
    update (val) {
      this.$set(this.config, 'origTimezone', Intl.DateTimeFormat().resolvedOptions().timeZone)
      this.$set(this.section.options.content.time, 'end_date', val)

      axios.put(`api/projects/${this.project.id}/timers/${this.config.id}`, {
        start_date: new Date().getTime(),
        end_date: val,
        orig_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        content: '[]',
        timezone: this.timeZone,
        is_active: true
      })
        .then(() => {
          VEvent.fire('timer-update')
          this.$set(this.config, 'time', val)
        })
    }
  }
}
</script>

<style lang="scss">
.section-timer-card {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.26);
  border-radius: 3px;
  height: 150px;
  margin-bottom: 30px;
  transition: .4s;

  &.active,
  &:hover {
    box-shadow: 0 0 0 3px #7CACFA;
  }
}
</style>
