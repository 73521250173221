<template>
  <div class="sid-select">
    <b-form-group
      label="Entrance Animation"
    >
      <v-select
        v-model="animations.animation"
        :calculate-position="withPopper"
        :options="animationOptions"
        :reduce="option => option.key"
        :searchable="false"
        append-to-body
        class="custom-select"
        placeholder="Select Animation"
        left
        top
        @option:selected="selected"
      />
    </b-form-group>

    <template v-if="animations.animation">
      <b-form-group label="Animation delay">
        <b-input
          v-model="animations.delay"
          type="number"
        />
      </b-form-group>

      <b-form-group label="Animation duration (ms)">
        <b-input
          v-model="duration"
          type="number"
        />
      </b-form-group>

      <b-form-group label="Animation offset (px)">
        <b-input
          v-model="offset"
          type="number"
        />
      </b-form-group>

      <b-form-group>
        <b-checkbox
          v-model="animations.repeat"
          type="number"
        >
          Repeat
        </b-checkbox>
      </b-form-group>
    </template>
  </div>
</template>

<script>
import popperMixin from '../mixins/popperMixin'

export default {
  name: 'Animations',

  mixins: [popperMixin],

  props: {
    sid: {
      type: String,
      default: null
    },
    animations: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      animationOptions: [
        {
          label: 'Select Animation',
          key: null
        },
        {
          label: 'Fade In',
          key: 'fade-in'
        },
        {
          label: 'Fade Up',
          key: 'fade-up'
        },
        {
          label: 'Fade Right',
          key: 'fade-right'
        },
        {
          label: 'Fade Left',
          key: 'fade-left'
        },
        {
          label: 'Fade Up Right',
          key: 'fade-up-right'
        },
        {
          label: 'Fade Up Left',
          key: 'fade-up-left'
        },
        {
          label: 'Slide Up',
          key: 'slide-up'
        },
        {
          label: 'Slide Left',
          key: 'slide-left'
        },
        {
          label: 'Slide Right',
          key: 'slide-right'
        },
        {
          label: 'Zoom In',
          key: 'zoom-in'
        },
        {
          label: 'Zoom In Up',
          key: 'zoom-in-up'
        },
        {
          label: 'Zoom In Left',
          key: 'zoom-in-left'
        },
        {
          label: 'Zoom In Right',
          key: 'zoom-in-right'
        }
      ]
    }
  },

  computed: {
    duration: {
      get () {
        return this.animations.duration || 400
      },
      set (val) {
        this.animations.duration = val
      }
    },
    offset: {
      get () {
        return this.animations.offset || 200
      },
      set (val) {
        this.$set(this.animations, 'offset', val)
      }
    }
  },

  methods: {
    selected () {
      if (this.sid) this.previewAnimate(this.sid)
    },
    previewAnimate (selector) {
      const $el = document.querySelector(`[data-uid="${selector}"]`)
      $el.setAttribute('data-aos', this.animations.animation)
      $el.setAttribute('data-aos-duration', '1000')

      setTimeout(() => {
        $el.classList.add('aos-animate')

        setTimeout(() => {
          $el.classList.remove('aos-animate')
          $el.removeAttribute('data-aos')
          $el.removeAttribute('data-aos-duration')
        }, 1000)
      }, 1000)
    }
  }
}
</script>
