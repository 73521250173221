<template>
  <div
    v-if="history.length"
    class="overflow-hidden rounded-[4px] border border-[#E3E5EB]"
  >
    <table class="border-collapse table-auto min-w-full text-[16px] line-height-[24px] font-[400] color-[#000000]">
      <tbody class="bg-transparent">
        <tr
          v-for="(item, index) in history"
          :key="index"
          class="border-b border-[#E3E5EB] last:border-b-0"
        >
          <td class="w-[160px] pl-[32px] pr-[16px] py-[16px]">
            {{ item.created | moment('MM/DD/YY') }}
          </td>
          <td class="p-[16px] font-[500]">
            {{ item.price }}
          </td>
          <td class="w-[200px] text-right pl-[16px] pr-[32px] py-[16px]">
            USD ${{ item.total / 100 }}
          </td>
          <td class="w-[160px] text-right pl-[16px] pr-[32px] py-[16px]">
            <a
              class="color-[#4F83E3] hover:underline"
              :href="item.hosted_invoice_url"
              target="_blank"
            >
              Download
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'BillingHistoryTable',
  data () {
    return {
      history: []
    }
  },
  mounted () {
    this.fetchHistory()
  },
  methods: {
    fetchHistory () {
      axios.get('api/user/settings/payment-methods/history')
        .then(({data}) => {
          this.history = data.data.invoices
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }
}
</script>