<template>
  <highliter
    v-model="section.name"
    :class="{'element-hidden': !visability('menu')}"
    :dublicate="section"
    :dublicate-deep="parentUID"
    class="highlight-element"
    section-type="element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('playlist')"
  >
    <div
      :data-sid="section.sid"
    >
      <div
        v-show="false"
        :class="[`--${section.sid}--playlist-item`]"
        class="video-playlist-item flex items-center transitioner overflow-hidden"
      >
        <div
          class="video-playlist-preview rounded-[inherit] relative overflow-hidden flex align-items-center justify-content-center"
          :class="`--${section.sid}--preview`"
        >
          <div class="block-bg" />
          <i class="fa fa-play z-index-1" />
        </div>
        <div class="w-100">
          <div
            class="playlist-item-title"
            :class="`--${section.sid}--title`"
          />
          <div
            class="playlist-item-description"
            :class="`--${section.sid}--description`"
          />
        </div>
      </div>

      <div
        class="video-playlist flex"
        :class="`--${section.sid}--playlist`"
      >
        <div
          v-for="(item, index) in section.options.content.playlist.items"
          :key="index"
          :class="[`--${section.sid}--playlist-item`, {'active': index === 0}]"
          class="video-playlist-item flex items-center transitioner overflow-hidden"
        >
          <div
            class="video-playlist-preview rounded-[inherit] relative overflow-hidden flex align-items-center justify-content-center"
            :class="`--${section.sid}--preview`"
            :style="{backgroundImage: `url(${item.src})`}"
          >
            <div class="block-bg" />
            <i class="fa fa-play z-index-1" />
          </div>
          <div class="w-100">
            <div
              class="playlist-item-title"
              :class="`--${section.sid}--title`"
              v-text="item.title"
            />
            <div
              class="playlist-item-description"
              :class="`--${section.sid}--description`"
              v-html="item.description"
            />
          </div>
        </div>
      </div>

      <div
        v-show="false"
        :class="`video-playlist-item --${section.sid}--playlist-item active`"
      />
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'

export default {
  name: 'ElCollapsableMenuLinks',
  components: {Highliter},
  mixins: [SectionMixin],
  data () {
    return {
      items: 10
    }
  }
}
</script>

<style lang="scss">
.video-playlist {
  --width: 100%;
  padding: 0 20px;
  gap: 10px;
  flex-direction: column;
}

.video-playlist-preview {
  --width: 200px;
  --height: 100px;
  width: 200px;
  height: 100px;
  background-size: cover;

  .block-bg {
    background-color: rgba(131, 131, 131, 0.10);
  }
}

.video-playlist-item {
  --width: 100%;
  --height: 100%;
  width: 100%;
  border: 1px solid #DEE2E6;
  border-radius: 7px;
  gap: 30px;

  i {
    transition: .4s;
  }

  &:hover {
    i {
      transform: scale(1.5);
    }
  }
}

.playlist-item-title {
  font-size: 20px;
}

.playlist-item-description {

}
</style>
