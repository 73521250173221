<template>
  <div class="dashboard-space dashboard-background min-vh-100">
    <dashboard-header />

    <!-- Topbar -->
    <dashboard-topbar title="Select a Template for Your Project" />
    <!-- End Topbar -->

    <div class="dashboard-container flex gap-[60px] w-full pt-5">
      <!-- Category Filter -->
      <template-filters
        @selected="category => filter(category)"
      />
      <!-- End Category Filter -->

      <div class="pb-[100px] w-100">
        <div class="flex flex-wrap items-center justify-between w-100 mb-[49px]">
          <h4 class="text-[16px] font-[600]">
            <span class="text-[#44474A]">{{ getTemlates.length }} Template Packs</span> <span class="text-[#B5BBC5]"> ({{ templatesData.total }} Total Template Packs)</span>
          </h4>

          <templates-search />
        </div>

        <div class="grid 2md:grid-cols-2 2lg:grid-cols-3 3xl:grid-cols-4 gap-[55px]">
          <blank-template-card
            @click.native="() => selectedTemplateData= {id: 1, name: 'Blank'}"
          />

          <template>
            <div
              v-for="template in getTemlates"
              :key="template.id"
              data-aos="zoom-in"
            >
              <template-card
                :updateding="ids.includes(template.id)"
                :name="template.name"
                :is-free="template.is_free"
                :page-count="template.pages_count"
                :preview="template.cover"
                :price="template.amount"
                :class="{'template-loading': transitionClass}"
                @click.native="() => ids.includes(template.id) ? $router.push({name: 'template', params: {id: template.id}}) : null"
              />
              <b-skeleton
                class="w-full template-skeleton absolute top-0 left-0 h-[298px] rounded-[4px] shadow-[0_5px_6px_rgba(0,0,0,0.13)]"
                :class="{'template-loading': loading}"
              />
            </div>
          </template>

          <template>
            <div
              v-for="n in 8"
              :key="n"
            >
              <b-skeleton
                class="w-full template-skeleton h-[298px] rounded-[4px] shadow-[0_5px_6px_rgba(0,0,0,0.13)]"
                :class="{'template-loading': loading}"
              />
            </div>
          </template>
        </div>
      </div>
      <!-- End Templates -->

      <create-project-modal v-model="selectedTemplateData" />
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import DashboardHeader from '../../layouts/dashboard/DashboardHeader'
import DashboardTopbar from '../../layouts/dashboard/DashboardTopbar'
import TemplateCard from '../../layouts/dashboard/compontens/templates/TemplateCard'
import TemplateFilters from '../../layouts/dashboard/compontens/templates/TemplateFilters'
import CreateProjectModal from '../../layouts/dashboard/compontens/templates/CreateProjectModal'
import BlankTemplateCard from '@/layouts/dashboard/compontens/templates/BlankTemplateCard'
import TemplatesSearch from '@/layouts/dashboard/compontens/templates/TemplatesSearch'

export default {
  name: 'Dashboard',
  components: {
    TemplatesSearch,
    BlankTemplateCard,
    CreateProjectModal, TemplateFilters, TemplateCard, DashboardTopbar, DashboardHeader
  },
  data () {
    return {
      templatesLoading: true,
      selectedTemplateData: {},
      transitionClass: false,
      ids: [22, 23, 24, 26, 27, 28, 29, 30, 31, 33, 34, 35, 36, 37]
    }
  },
  computed: {
    ...mapState('templates', {
      templates: state => state.templates,
      templatesData: state => state.templatesData,
      loading: state => state.loading
    }),
    getTemlates () {
      return this.templates.filter(template => template.id !== 1)
    }
  },
  watch: {
    loading (val) {
      if (!val) {
        return setTimeout(() => {
          this.transitionClass = false
        }, 500)
      }

      this.transitionClass = true
    }
  },
  created () {
    this.filter()
  },
  methods: {
    filter (category) {
      this.templatesLoading = true

      if (category === 'my-templates') {
        return this.$store.dispatch('templates/getMyTemplates')
          .finally(() => {
            this.templatesLoading = false
          })
      }

      this.$store.dispatch('templates/getTemplates', category)
        .finally(() => {
          this.templatesLoading = false
        })
    }
  }
}
</script>

<style lang="scss">
.dashboard-background {
  background-color: #F7F8F9;
  padding-top: 0px;
}

.block-spacing-47 {
  padding-top: 47px;
  padding-bottom: 47px;
}

.template-skeleton {
  transition: .4s;
  transform: scale(0);
  opacity: 0;

  &.template-loading {
    transform: scale(1);
    opacity: 1;
  }
}

.card-template {
  transition: .4s;

  &.template-loading {
    transform: scale(0);
    opacity: 0;
  }
}
</style>
