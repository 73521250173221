<template>
  <b-modal
    v-model="modal"
    dialog-class="!max-w-[800px]"
    content-class="rounded-[10px]"
    body-class="!px-[64px] !py-[48px]"
    hide-header
    hide-footer
    centered
    lazy
  >
    <i
      class="fa fa-close z-[50] absolute top-[15px] right-[17px] text-[16px] text-[#6E747A] transition cursor-pinter hover:opacity-60"
      @click="modal = false"
    />
    
    <div class="w-full flex flex-col items-center gap-y-[32px] font-[ProximaNova]">
      <div class="w-full flex flex-col items-center gap-[16px]">
        <div class="max-w-[600px] w-full flex items-center justify-center gap-[12px]">
          <div>
            <div class="w-[40px] h-[40px] flex items-center justify-center rounded-full bg-[#FFF8EB] text-[14px] text-[#DBAA48]">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5 3.33317H14.6667C11.8664 3.33317 10.4663 3.33317 9.39671 3.87814C8.4559 4.3575 7.691 5.12241 7.21163 6.06322C6.66667 7.13278 6.66667 8.53291 6.66667 11.3332V16.6665M6.66667 16.6665L10.8333 12.4998M6.66667 16.6665L2.5 12.4998"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <h3 class="text-[32px] leading-[36px] font-[700] text-[#000000] mb-0">
            Downgrade Account
          </h3>
        </div>

        <p class="text-center text-[16px] leading-[24px] font-[400] text-[#9D9D9D] mb-0">
          If you downgrade your account, you will not be able to use Business Plus features.
        </p>
      </div>

      <div class="relative w-full flex flex-row items-center justify-center px-[40px] py-[32px] gap-x-[12px] rounded-[10px] border border-[#E3E5EB]">
        <div class="absolute top-[-20px] left-[30px] p-[10px] bg-[#FFFFFF]">
          <p class="text-[16px] leading-[20px] font-[600] text-[#000000] mb-0">
            Your Losing This Features:
          </p>
        </div>

        <ul class="w-full columns-2 list-none">
          <li
            v-for="(feature, index) in features"
            :key="index"
            class="relative w-full inline-block mt-[8px] first:mt-0 text-[14px] leading-[24px] font-[400] text-[#A9A9A9]"
          >
            <i class="fa fa-x text-[#dc3545] mr-[8px]" />
            <span class="w-full">
              {{ feature }}
            </span>
          </li>
        </ul>
      </div>
        
      <b-btn
        variant="danger"
        size="md"
        class="min-w-[160px] h-[40px] !font-[700]"
        :disabled="loading"
        @click="downgradePlan"
      >
        Downgrade My Account
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import _ from 'lodash'
import {mapGetters} from 'vuex'
export default {
  name: 'DowngradePlanModal',
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      plan: null,
      features: [
        'Unlimited Video Hosting',
        'Real-time CTA',
        'ESTAGE Public Live Streaming',
        'ESTAGE Private Live Streaming',
        'Live Streaming Instant Replays',
        'Community Live Video Posts',
        'ESTAGE Native Live Chat',
        'Community Private Events',
        'Courses - Daily Challenge Mode',
        'Courses - A/R Integration for Lessons',
        'Community - Opportunities',
        'Community - MSI Ecosystem',
        'Community - IPS (Integrated Product Suite) Licensing and Management',
        'Community - Featured Ad Widget (in feed)',
        'Community - Upcoming Events Widget (in feed)'
      ]
    }
  },
  computed: {
    ...mapGetters('auth', {
      userSubscription: 'userSubscription'
    }),
    modal: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    async downgradePlan () {
      this.loading = true
      const {data} = await axios.get('api/user/settings/payment-methods')
      const pm_id = _.get(data,'data.user.invoice_settings.default_payment_method')
      if (this.userSubscription.plan.code === 'BUSINESS_PLUS_Y') this.plan = 'BUSINESS_Y'
      if (this.userSubscription.plan.code === 'BUSINESS_PLUS_M') this.plan = 'ESTAGE_SUB'
      await axios.post('api/user/payment-systems/change-plan', {
        plan: this.plan,
        source: pm_id
      })
        .then(() => {
          this.loading = false
          this.$swal({
            icon: 'success',
            iconColor: '#4F83E3',
            toast: true,
            position: 'top-right',
            title: 'Plan has been successfully updated.',
            showConfirmButton: false,
            timer: 3000
          })
        })
        .catch(() => {
          this.loading = false
          this.$swal({
            icon: 'error',
            iconColor: '#4F83E3',
            toast: true,
            position: 'top-right',
            title: 'Payment Declined.',
            text: 'The card information was declined',
            showConfirmButton: false,
            timer: 5000
          })
        })
    }
  }
}
</script>