<template>
  <div class="dashboard-grid-folders">
    <draggable
      group="folders"
      :list="folders"
      :delay="200"
      filter=".static"
      @end="end"
    >
      <transition-group
        tag="div"
        name="shufle"
        class="grid grid-cols-1 lg:grid-cols-2 2lg:grid-cols-3 3xl:grid-cols-4 gap-10"
      >
        <div
          v-for="folder in folders"
          :key="folder.id"
          class="folder-wrapper"
        >
          <div
            class="folder cursor-pinter hover:bg-blue-50"
            @click="$store.dispatch('dashboard/loadFolder', folder.id)"
          >
            <div class="separate">
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2738_21989)">
                  <path
                    d="M28.7528 32.5862H0.971348C0.332038 32.5862 -0.132859 31.9792 0.0341065 31.3616L6.34404 14.0623C6.45824 13.639 6.84245 13.3448 7.28128 13.3448H35.0627C35.702 13.3448 36 13.9556 36 14.5694L29.69 31.8687C29.5758 32.292 29.1916 32.5862 28.7528 32.5862Z"
                    fill="#94B8FC"
                  />
                  <path
                    d="M31.2008 7.75862H15.5172L12.4138 3.41379H1.07503C0.481655 3.41379 0 3.89482 0 4.48882V31.0096C0.0428276 31.0109 0.0856552 31.0134 0.127241 31.0159L6.34345 14.0623C6.45766 13.6384 6.84186 13.3448 7.28069 13.3448H32.2759V8.83365C32.2759 8.23965 31.7942 7.75862 31.2008 7.75862Z"
                    fill="#4F83E3"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2738_21989">
                    <rect
                      width="36"
                      height="36"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>

            <div class="folder-details mt-1 mr-auto">
              <h5
                class="folder-name text-truncate mb-1"
                v-text="folder.name"
              />
              <h6 class="folder-sites-count mb-0">
                <div
                  class="mr-1"
                  v-text="folder.countProjects"
                />
                <div>Sites</div>
              </h6>
            </div>

            <b-dropdown
              variant="folder-dropdown"
              menu-class="project-menu py-0"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <svg
                  width="24"
                  height="5"
                  viewBox="0 0 24 5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="2.5"
                    cy="2.5"
                    r="2.5"
                    fill="#4F83E3"
                  />
                  <circle
                    cx="12.0714"
                    cy="2.5"
                    r="2.5"
                    fill="#4F83E3"
                  />
                  <circle
                    cx="21.3572"
                    cy="2.5"
                    r="2.5"
                    fill="#4F83E3"
                  />
                </svg>
              </template>

              <move-folder-modal :folder="folder" />
              <edit-folder-modal :folder="folder" />
              <delete-folder-modal :folder="folder" />
            </b-dropdown>
          </div>
        </div>

        <div
          :key="'archive'"
          class="static"
          lg="6"
          xl="3"
          @click="$router.push({name: 'archive-projects'})"
        >
          <div
            class="folder cursor-pinter hover:bg-blue-50"
          >
            <div class="separate">
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2738_21989)">
                  <path
                    d="M28.7528 32.5862H0.971348C0.332038 32.5862 -0.132859 31.9792 0.0341065 31.3616L6.34404 14.0623C6.45824 13.639 6.84245 13.3448 7.28128 13.3448H35.0627C35.702 13.3448 36 13.9556 36 14.5694L29.69 31.8687C29.5758 32.292 29.1916 32.5862 28.7528 32.5862Z"
                    fill="#94B8FC"
                  />
                  <path
                    d="M31.2008 7.75862H15.5172L12.4138 3.41379H1.07503C0.481655 3.41379 0 3.89482 0 4.48882V31.0096C0.0428276 31.0109 0.0856552 31.0134 0.127241 31.0159L6.34345 14.0623C6.45766 13.6384 6.84186 13.3448 7.28069 13.3448H32.2759V8.83365C32.2759 8.23965 31.7942 7.75862 31.2008 7.75862Z"
                    fill="#4F83E3"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2738_21989">
                    <rect
                      width="36"
                      height="36"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>

            <div class="folder-details mt-1 mr-auto">
              <h5 class="folder-name text-truncate mb-1">
                Archive
              </h5>
              <h6 class="folder-sites-count mb-0">
                <div
                  class="mr-1"
                  v-text="totalArchiveProjects"
                />
                <div>Sites</div>
              </h6>
            </div>
          </div>
        </div>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import EditFolderModal from '../templates/EditFolderModal'
import DeleteFolderModal from '../templates/DeleteFolderModal'
import MoveFolderModal from '../templates/MoveFolderModal'
import {mapState} from 'vuex'

export default {
  name: 'DashboardGridFolders',
  components: {
    MoveFolderModal,
    DeleteFolderModal,
    EditFolderModal,
    draggable
  },
  props: {
    folders: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      totalArchiveProjects: 0
    }
  },
  computed: {
    ...mapState('dashboard', {
      folder: state => state.folder
    })
  },
  created () {
    axios.get('api/projects/trash')
      .then(({data}) => {
        this.totalArchiveProjects = data.data.total
      })
  },
  methods: {
    end () {
      axios.put(
        'api/projects/categories/sort',
        this.folders.map((folder, index) => {
          return {
            id: folder.id,
            order: index + 1
          }
        })
      )
    }
  }
}
</script>

<style lang="scss">
@import "@builder/assets/scss/_variables.scss";

.dashboard-grid-folders {
  .folder {
    font-family: "Raleway";
    min-width: 372px;
    display: flex;
    align-items: center;
    position: relative;
    height: 90px;
    background-color: #fff;
    border: 1px solid #e2e5ec;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.03);
    border-radius: 7px;
    padding-left: 26px;
    padding-right: 26px;

    & {
      .btn-folder-dropdown {
        background-color: unset !important;
        border: unset !important;
      }

      .folder-details {
        margin-left: 27px;
      }

      .folder-name {
        font-weight: 600;
        font-size: 17px;
        color: #44474a;
        margin-bottom: 3px;
        max-width: 200px;
      }

      .folder-sites-count {
        font-size: 15px;
        color: #9fa9ba;
        display: flex;
        align-items: center;
      }

      .separate {
        position: relative;

        svg {
          margin-right: 30px;
        }

        &:after {
          content: "";
          position: absolute;
          right: 2px;
          top: 50%;
          transform: translateY(-50%);
          height: 90px;
          width: 1px;
          background-color: #dddfe2;
        }
      }
    }
  }

  .sortable-chosen {
    .folder {
      border: 3px solid $primary;
    }
  }
}

.project-menu {
  transform: translate3d(-120px, 20px, 0px) !important;
}
</style>