<template>
  <div>
    <b-form-group>
      <div class="btn-group flex items-center w-full">
        <button
          :class="{'bg-gray-150': (val || sidValueMutation) === 'column'}"
          class="flex transition justify-center items-center h-[33px] !px-[20px] py-0 !text-[12px] !font-[600] border border-right-0 rounded-l-[4px] !text-[#44474A] hover:!bg-gray-150"
          @click="() => apply(0)"
        >
          Vertical
        </button>
        <button
          :class="{'bg-gray-150': (val || sidValueMutation) === 'row'}"
          class="flex transition justify-center items-center h-[33px] !px-[20px] py-0 !text-[12px] !font-[600] border !text-[#44474A] hover:!bg-gray-150"
          @click="() => apply(1)"
        >
          Horiztonal
        </button>
        <button
          v-if="!disableReverse"
          :class="{'!bg-gray-150': reverse}"
          class="btn !transition !flex justify-center items-center h-[33px] !px-[10px] py-0 !text-[12px] !font-[600] border rounded-[4px] !text-[#44474A] hover:!bg-gray-150"
          @click="() => apply(2)"
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.5613 7.91296H2.41289C2.07555 7.91296 1.80176 8.18675 1.80176 8.5241C1.80176 8.86144 2.07555 9.13523 2.41289 9.13523H10.5613C10.8987 9.13523 11.1725 8.86144 11.1725 8.5241C11.1725 8.18675 10.8987 7.91296 10.5613 7.91296Z"
              fill="#333333"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.783009 3.43125H8.93146C9.2688 3.43125 9.54259 3.15746 9.54259 2.82012C9.54259 2.48277 9.2688 2.20898 8.93146 2.20898H0.783009C0.445663 2.20898 0.171875 2.48277 0.171875 2.82012C0.171875 3.15746 0.445663 3.43125 0.783009 3.43125Z"
              fill="#333333"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.09117 6.91877L9.6956 8.52402L8.09117 10.1293C7.85242 10.3672 7.85242 10.7551 8.09117 10.993C8.32911 11.2317 8.71697 11.2317 8.95491 10.993L10.992 8.95589C11.2308 8.71714 11.2308 8.3309 10.992 8.09215L8.95491 6.05504C8.71697 5.81629 8.32911 5.81629 8.09117 6.05504C7.85242 6.29297 7.85242 6.68084 8.09117 6.91877Z"
              fill="#333333"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.25179 4.42541L1.64736 2.82016L3.25179 1.21492C3.49054 0.976982 3.49054 0.589116 3.25179 0.351181C3.01385 0.112432 2.62598 0.112432 2.38805 0.351181L0.350937 2.38829C0.112188 2.62704 0.112188 3.01328 0.350937 3.25203L2.38805 5.28914C2.62598 5.52789 3.01385 5.52789 3.25179 5.28914C3.49054 5.05121 3.49054 4.66334 3.25179 4.42541Z"
              fill="#333333"
            />
          </svg>
        </button>
      </div>
    </b-form-group>

    <slot :name="sidValueMutation" />
  </div>
</template>

<script>
import SidConcept from '@/components/mixins/SidConcept'

export default {
  name: 'SidFlexDirection',
  mixins: [SidConcept],

  props: ['verticalProperties', 'horizontalProperties', 'disableReverse'],

  data () {
    return {
      val: null,
      reverse: false
    }
  },

  created () {
    const value = this.getSidStyle(this.getSid(), this.ruleName).split('-')
    this.val = value[0] || 'column'
    this.reverse = value.length > 1
  },
  methods: {
    horizontal () {
      this.addSidRules(this.horizontalProperties || [[this.getSid(), [this.ruleName, this.reverse ? 'row-reverse' : 'row']]])
      this.val = 'row'
    },
    vertical () {
      this.addSidRules(this.verticalProperties || [[this.getSid(), [this.ruleName, this.reverse ? 'column-reverse' : 'column']]])
      this.val = 'column'
    },

    apply (index) {
      if (index === 0) {
        return this.vertical()
      }

      if (index === 1) {
        return this.horizontal()
      }

      if (index === 2) {
        this.reverse ? this.reverse = false : this.reverse = true
        if (this.val === 'row') return this.horizontal()
        if (this.val === 'column') return this.vertical()
      }
    }
  }
}
</script>