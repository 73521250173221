<template>
  <div>
    <b-dropdown-item
      class="d-flex align-items-center"
      link-class="dashboard-menu-link"
      @click.stop="modal = true"
    >
      <svg
        class="mr-2"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.8048 0.528575C10.5444 0.268226 10.1223 0.268226 9.86197 0.528575L0.528636 9.86191C0.403612 9.98693 0.333374 10.1565 0.333374 10.3333V13C0.333374 13.3682 0.631851 13.6666 1.00004 13.6666H3.66671C3.84352 13.6666 4.01309 13.5964 4.13811 13.4714L13.4714 4.13805C13.7318 3.8777 13.7318 3.45559 13.4714 3.19524L10.8048 0.528575ZM11 4.72384L9.27619 2.99998L10.3334 1.94279L12.0572 3.66665L11 4.72384ZM8.33338 3.94279L10.0572 5.66665L4.33337 11.3905L2.60952 9.66665L8.33338 3.94279ZM1.66671 10.6095L3.39057 12.3333H1.66671V10.6095Z"
          fill="#44474A"
        />
        <path
          d="M7.66671 12.3333C7.29852 12.3333 7.00004 12.6318 7.00004 13C7.00004 13.3682 7.29852 13.6666 7.66671 13.6666H13C13.3682 13.6666 13.6667 13.3682 13.6667 13C13.6667 12.6318 13.3682 12.3333 13 12.3333H7.66671Z"
          fill="#44474A"
        />
      </svg>

      Rename
    </b-dropdown-item>

    <b-modal
      v-model="modal"
      class="position-relative"
      lazy
      centered
      body-class="py-5"
      content-class="new-section-modal"
      size="lg"
      hide-footer
      hide-header
    >
      <h3 class="text-center font-weight-bold mb-12 px-0">
        Edit {{ folder.name }}
      </h3>

      <b-row>
        <b-col
          offset="2"
          cols="8"
        >
          <b-form @submit.prevent="onsubmit">
            <b-form-group
              class="estage-form-group"
              label="Enter Folder Name"
            >
              <b-input
                v-model="name"
                v-validate="'required'"
                name="name"
                :state="errors.has('name') ? false : null"
              />
            </b-form-group>

            <div class="text-center mt-5">
              <b-btn
                type="submit"
                variant="primary"
                class="font-weight-bold font-size-14 px-5"
                :disabled="loading"
              >
                <b-spinner
                  v-if="loading"
                  class="mr-2"
                  variant="light"
                  small
                />
                Update
              </b-btn>
            </div>
          </b-form>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'EditFolderModal',
  props: {
    folder: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      modal: false,
      name: '',
      loading: false
    }
  },
  computed: {
    ...mapState('dashboard', {
      staticFolder: state => state.folder
    })
  },
  watch: {
    modal (val) {
      if (val) {
        this.name = JSON.parse(JSON.stringify(this.folder)).name
      }
    }
  },
  methods: {
    onsubmit () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true
          axios.put(`api/projects/categories/${this.folder.id}`, {
            name: this.name
          })
            .then(() => {

              this.$store.dispatch('dashboard/loadFolder', this.staticFolder ? this.staticFolder.id : null)
              this.modal = false
            })
            .catch(error => {
              const errors = error.response.data.errors
              if (!errors.hasOwnProperty('name')) {
                this.$swal({
                  icon: 'warning',
                  iconColor: '#4F83E3',
                  toast: true,
                  position: 'top-right',
                  title: 'A folder with the same name already exists.',
                  showConfirmButton: false,
                  timer: 3000
                })
              }
              this.loading = false
            })
        }
      })
    }
  }
}
</script>