<template>
  <div
    v-if="products.length >= 1"
    :data-sid="`billing-terms-${sid}`"
    :class="`text-container-${section.sid}`"
  >
    <highliter
      v-model="section.name"
      class="highlight-element"
      section-type="element"
      :hide-right="true"
      @move-to="to => $emit('move-to', to)"
      @add-subject="$emit('add-subject')"
      @click.stop.native="sectionSettings('description')"
      @highlight-click="contentClick"
    >
      <content-editable-v2
        ref="content"
        v-model="section.options.content.description.text"
        :customs="section.options.content.description"
        custom-class="billing-terms"
        :section="section"
        type="paragraph"
      />
    </highliter>
  </div>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import ContentEditableV2 from '@/components/builder/utils/ContentEditableV2.vue'
import Highliter from '@/components/builder/utils/Highliter.vue'
import {mapState} from 'vuex'
export default {
  name: 'ElBillingTerms',
  mixins: [SectionMixin],
  components: {
    ContentEditableV2,
    Highliter
  },
  computed: {
    ...mapState('products', {
      products: state => state.selected
    })
  },
  methods: {
    contentClick () {
      this.$refs.content.initer()
    }
  }
}
</script>