<template>
  <div class="d-flex">
    <b-nav class="settings-nav border-bottom-0">
      <b-nav-item
        :active="$route.name === 'account.profile'"
        :to="{name: 'account.profile'}"
      >
        Profile
      </b-nav-item>
      <b-nav-item
        :active="$route.name === 'account.login-info'"
        :to="{name: 'account.login-info'}"
      >
        Login Info
      </b-nav-item>
      <b-nav-item
        v-if="user.user_subscription && user.user_subscription.plan.code !== null"
        :active="$route.name === 'account.billing'"
        :to="{name: 'account.billing'}"
      >
        Billing
      </b-nav-item>
    </b-nav>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'AccountSettingsNav',
  computed: {
    ...mapState('auth', {
      user: state => state.user
    })
  }
}
</script>

<style lang="scss">
.settings-nav {
  border: 1px solid #E2E5EC;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  .nav-link {
    color: #43474A;
    font-size: 16px;
    padding: 20px 0 !important;
    margin: 0 20px;

    &.active {
      border-bottom: 2px solid #43474A !important;
      font-weight: bold;
    }
  }
}
</style>
