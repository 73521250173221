import Cookie from 'js-cookie'
import axios from 'axios'

const instance  = axios.create({
  baseURL: 'https://video.estage.com',
  headers: {
    'Authorization': `Bearer ${Cookie.get('cross_estage_token')}`
  }
})

const state = () => ({
  loading: false,
  poster: '',
  type: 'Streaming Software',
  token: null,
  isLive: false,
  isOpen: false,
  rtmp_server_stream_key: null,
  server: null
})

const actions = {
  async rotateServers () {
    let servers = [
      'https://estagestream.com:1990',
      'https://edge1.estagelive.com:1990',
      'https://edge2.estagelive.com:1990'
    ]
  
    const selectedServer = servers.shift()
    servers.push(selectedServer)
    return selectedServer
  },
  async getServer ({state}) {
    await instance.get('https://estagesocial.com/api/stream/server', {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then((res) => {
        state.server = res.data
        return res.data
      })
  },
  async getLiveStreamToken ({state, rootState}) {
    await instance.get(`api/live/${rootState.projects.project.id}/${state.rtmp_server_stream_key}/token`)
      .then((res) => {
        state.token = res.data.token
      })
  },
  async postLiveStream ({state, rootState}, payload) {
    return await instance.post(`api/live/${rootState.projects.project.id}`)
      .then((res) => {
        state.rtmp_server_stream_key = res.data.rtmp_server_stream_key
        payload.rtmp_server_stream_key = res.data.rtmp_server_stream_key
      })
  },
  putLiveStream ({state}) {
    state.isOpen = !state.isOpen
    instance.put(`api/live/hls/${state.rtmp_server_stream_key}?is_open=${state.isOpen}`)
      .then((res) => {
        state.isOpen = res.data.isOpen
      })
  },
  async putLiveStreamPreview ({state, rootState}, payload) {
    await instance.put(`api/live/${rootState.projects.project.id}/${state.rtmp_server_stream_key}/poster`, {
      poster: payload
    })
    state.poster = payload
  }
}

const mutations = {
  SET_POSTER (state, payload) {
    state.poster = payload
  },
  SET_LOADING (state, payload) {
    state.loading = payload
  },
  SET_LIVE (state, payload) {
    state.isLive = payload
  },
  SET_OPEN (state, payload) {
    state.isOpen = payload
  },
  SET_RTMP_SERVER_STREAM_KEY (state, payload) {
    state.rtmp_server_stream_key = payload
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}