<template>
  <div class="sid-select">
    <draggable
      :list="control"
      handle=".move"
    >
      <div 
        v-for="(item, index) in control"
        :key="index"
        class="border-b mt-[-1px] gap-y-[12px]"
      >
        <div 
          class="group rounded-[2px] h-[38px] flex items-center pl-[16px] hover:bg-[#F1F5FD] cursor-pinter"
          :class="[{'opacity-50': item.is_hide }, active === index ? 'border-b rounded-b-0' : null]"
          @click.stop="active === index ? active = null : active = index"
        >
          <svg
            class="move cursor-move mr-[14px]"
            fill="none"
            height="10"
            viewBox="0 0 10 10"
            width="10"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.57745 0H0.568359V3H3.57745V0Z"
              fill="#3D82EA"
            />
            <path
              d="M10.5984 0H7.58936V3H10.5984V0Z"
              fill="#3D82EA"
            />
            <path
              d="M3.57745 7H0.568359V10H3.57745V7Z"
              fill="#3D82EA"
            />
            <path
              d="M10.5984 7H7.58936V10H10.5984V7Z"
              fill="#3D82EA"
            />
          </svg>

          <span class="text-truncate max-w-[165px] text-[15px] group-hover:text-[#3D82EA] text-[#7E7E7E]">
            {{ item.text ? 'Login Link' : item.label }}
          </span>

          <button
            title="Column Settings"
            class="flex w-[38px] h-[38px] items-center justify-center ml-auto p-0 bg-transparent hover:bg-[#E2E5EC]"
            @click.stop="active === index ? active = null : active = index"
          >
            <svg
              fill="none"
              height="10"
              viewBox="0 0 7 10"
              width="7"
              xmlns="http://www.w3.org/2000/svg"
              :class="{'rotate-90': active === index}"
            >
              <path
                d="M0.879944 9.23245L4.98805 4.89924L0.879944 0.566031"
                stroke="#6E747A"
                stroke-width="1.5"
              />
            </svg>
          </button>

          <button
            v-if="!disableRemove"
            class="flex w-[38px] h-[38px] items-center justify-center border-left p-0 bg-transparent hover:bg-[#E2E5EC]"
            @click.stop="hideControl(index)"
          >
            <svg
              fill="none"
              height="10"
              viewBox="0 0 10 10"
              width="10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.32913 0.170839C1.10132 -0.0569667 0.731973 -0.0569667 0.504167 0.170839C0.276362 0.398645 0.276362 0.767991 0.504167 0.995797L4.17507 4.6667L0.504167 8.33759C0.276362 8.5654 0.276362 8.93475 0.504167 9.16255C0.731973 9.39036 1.10132 9.39036 1.32913 9.16255L5.00002 5.49165L8.67083 9.16246C8.89864 9.39027 9.26799 9.39027 9.49579 9.16246C9.7236 8.93466 9.7236 8.56531 9.49579 8.33751L5.82498 4.6667L9.49579 0.995885C9.7236 0.768079 9.7236 0.398733 9.49579 0.170927C9.26799 -0.0568786 8.89864 -0.0568786 8.67083 0.170927L5.00002 3.84174L1.32913 0.170839Z"
                fill="#6E747A"
              />
            </svg>
          </button>
        </div>

        <template v-if="item.text">
          <template v-if="active === index">
            <div
              class="!py-[14px] !px-[16px]"
            >
              <b-form-group label="Message Text">
                <b-textarea v-model="item.text" />
              </b-form-group>

              <b-form-group label="Link Text">
                <b-textarea v-model="item.link" />
              </b-form-group>

              <sid-range-slider
                label="Width"
                :sid="`.--${element.sid}--login`"
                :dimensions="['%', 'px']"
                default-dimension="%"
                rule-name="width"
                var="--width"
                measurement="width"
                class="mt-[12px]"
              />
            </div>
            <SidbearSettingsGroup
              title="Message Styles"
              button-class="!px-[16px]"
              content-class="!py-[14px] !px-[16px]"
              :style="{margin: 0}"
            >
              <sid-font
                prefix="Message"
                :sid="`.--${element.sid}--form-fields .--${element.sid}--login`"
              />

              <sid-range-slider
                label="Message Font Size"
                :sid="`.--${element.sid}--form-fields .--${element.sid}--login`"
                rule-name="font-size"
              />

              <b-form-group>
                <div class="flex items-center justify-between">
                  <label class="pb-0">Message Text Color</label>

                  <sid-color
                    :sid="`.--${element.sid}--form-fields .--${element.sid}--login`"
                    rule-name="color"
                  />
                </div>
              </b-form-group>
            </SidbearSettingsGroup>
            <SidbearSettingsGroup
              title="Link Styles"
              button-class="!px-[16px]"
              content-class="!py-[14px] !px-[16px]"
              :style="{margin: 0}"
            >
              <sid-font
                prefix="Link"
                :sid="`.--${element.sid}--form-fields .--${element.sid}--login-link`"
              />

              <sid-range-slider
                label="Link Size"
                :sid="`.--${element.sid}--form-fields .--${element.sid}--login-link`"
                rule-name="font-size"
              />

              <b-form-group>
                <div class="flex items-center justify-between">
                  <label class="pb-0">Link Color</label>

                  <sid-color
                    :sid="`.--${element.sid}--form-fields .--${element.sid}--login-link`"
                    rule-name="color"
                  />
                </div>
              </b-form-group>
            </SidbearSettingsGroup>
            <SidbearSettingsGroup
              title="Padding, Margins"
              button-class="!px-[16px]"
              content-class="!py-[14px] !px-[16px]"
              :style="{margin: 0}"
            >
              <sid-linked-group
                label="Padding"
                :sid="`.--${element.sid}--form-fields .--${element.sid}--login`"
                rule-name="padding"
                :min="0"
                :angles="[
                  {
                    text: '',
                    value: 'top'
                  },
                  {
                    text: '',
                    value: 'right'
                  },
                  {
                    text: '',
                    value: 'bottom'
                  },
                  {
                    text: '',
                    value: 'left'
                  }
                ]"
              />

              <sid-linked-group
                label="Margin"
                :sid="`.--${element.sid}--form-fields .--${element.sid}--login`"
                rule-name="margin"
                :min="-2000"
                :max="2000"
                :angles="[
                  {
                    text: '',
                    value: 'top'
                  },
                  {
                    text: '',
                    value: 'right'
                  },
                  {
                    text: '',
                    value: 'bottom'
                  },
                  {
                    text: '',
                    value: 'left'
                  }
                ]"
              />
            </SidbearSettingsGroup>
          </template>
        </template>
        <template v-else>
          <FormControlsFieldSettingsV2
            v-if="active === index"
            :element="element"
            :control="item"
            :sid-class="`.--${element.sid}--form-field.--${element.sid}--form-field-${element.options.content.control[index].field_name}`"
            static-type
          />
        </template>
      </div>
    </draggable>

    <SidbearSettingsGroup
      title="Padding, Margins & Gaps"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: 0}"
    >
      <sid-linked-group
        label="Padding"
        :sid="`.--${element.sid}--form-fields`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="`.--${element.sid}--margin`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-range-slider
        label="Gap"
        :sid="`.--${element.sid}--margin .--${element.sid}--form-fields`"
        rule-name="gap"
        :dimensions="[]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import FormControlsFieldSettingsV2 from '@/components/editor/components/form/FormControlsFieldSettingsV2.vue'
import SidFont from '@/components/editor/components/sid-controls/sid-control-pressets/font/SidFont.vue'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider.vue'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup.vue'
import SidColor from '@/components/editor/components/sid-controls/SidColor.vue'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
export default {
  name: 'AuthSettings',
  components: {
    draggable,
    FormControlsFieldSettingsV2,
    SidbearSettingsGroup,
    SidColor,
    SidLinkedGroup,
    SidRangeSlider,
    SidFont
  },
  props: {
    control: {
      type: Array,
      required: true
    },
    disableRemove: {
      type: Boolean,
      required: true,
      default: false
    },
    element: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      active: null
    }
  },
  mounted () {
    this.control.forEach((item, index) => {
      if (item.is_hide === undefined || item.is_hide === null) return this.control[index].is_hide = false
    })
  },
  methods: {
    hideControl (index) {
      return this.control[index].is_hide = !this.control[index].is_hide
    }
  }
}
</script>