<template>
  <div>
    <b-form-group>
      <div class="flex items-center justify-between">
        <label class="pb-0">Vertical Align</label>

        <div class="flex gap-[6px]">
          <b-btn
            variant="white"
            class="w-[29px] h-[29px] flex items-center justify-center p-0"
            @click="() => translateYMutation = 0"
          >
            <svg
              width="12"
              height="10"
              viewBox="0 0 12 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.5 0.5C0.5 0.223858 0.723858 0 1 0H11C11.2761 0 11.5 0.223858 11.5 0.5C11.5 0.776142 11.2761 1 11 1H1C0.723858 1 0.5 0.776142 0.5 0.5Z"
                :fill="translateYMutation === 0 ? '#7CACFA' : '#333333'"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.5 2.5C1.5 2.22386 1.72386 2 2 2H5C5.27614 2 5.5 2.22386 5.5 2.5V9.5C5.5 9.77614 5.27614 10 5 10H2C1.72386 10 1.5 9.77614 1.5 9.5V2.5ZM2.5 3V9H4.5V3H2.5Z"
                :fill="translateYMutation === 0 ? '#7CACFA' : '#333333'"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7 2C6.72386 2 6.5 2.22386 6.5 2.5V6.5C6.5 6.77614 6.72386 7 7 7H10C10.2761 7 10.5 6.77614 10.5 6.5V2.5C10.5 2.22386 10.2761 2 10 2H7ZM7.5 6V3H9.5V6H7.5Z"
                :fill="translateYMutation === 0 ? '#7CACFA' : '#333333'"
              />
            </svg>
          </b-btn>
          <b-btn
            variant="white"
            class="w-[29px] h-[29px] flex items-center justify-center p-0"
            @click="() => translateYMutation = 50"
          >
            <svg
              width="10"
              height="12"
              viewBox="0 0 10 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.5 1C5.5 0.723858 5.27614 0.5 5 0.5C4.72386 0.5 4.5 0.723858 4.5 1V1.5H3C2.72386 1.5 2.5 1.72386 2.5 2V5C2.5 5.27614 2.72386 5.5 3 5.5H4.5V6.5H0.5C0.223858 6.5 0 6.72386 0 7V10C0 10.2761 0.223858 10.5 0.5 10.5H4.5V11C4.5 11.2761 4.72386 11.5 5 11.5C5.27614 11.5 5.5 11.2761 5.5 11V10.5H9.5C9.77614 10.5 10 10.2761 10 10V7C10 6.72386 9.77614 6.5 9.5 6.5H5.5V5.5H7C7.27614 5.5 7.5 5.27614 7.5 5V2C7.5 1.72386 7.27614 1.5 7 1.5H5.5V1ZM4.5 2.5H3.5V4.5H4.5V2.5ZM5.5 4.5V2.5H6.5V4.5H5.5ZM4.5 7.5H1V9.5H4.5V7.5ZM5.5 9.5V7.5H9V9.5H5.5Z"
                :fill="translateYMutation === 50 ? '#7CACFA' : '#333333'"
              />
            </svg>
          </b-btn>
          <b-btn
            variant="white"
            class="w-[29px] h-[29px] flex items-center justify-center p-0"
            @click="() => translateYMutation = 100"
          >
            <svg
              width="12"
              height="10"
              viewBox="0 0 12 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.5 0.5C1.5 0.223858 1.72386 0 2 0H5C5.27614 0 5.5 0.223858 5.5 0.5V7.5C5.5 7.77614 5.27614 8 5 8H2C1.72386 8 1.5 7.77614 1.5 7.5V0.5ZM2.5 1V7H4.5V1H2.5Z"
                :fill="translateYMutation === 100 ? '#7CACFA' : '#333333'"
              />
              <path
                d="M0.5 9.5C0.5 9.22386 0.723858 9 1 9H11C11.2761 9 11.5 9.22386 11.5 9.5C11.5 9.77614 11.2761 10 11 10H1C0.723858 10 0.5 9.77614 0.5 9.5Z"
                :fill="translateYMutation === 100 ? '#7CACFA' : '#333333'"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7 2.5C6.72386 2.5 6.5 2.72386 6.5 3V7.5C6.5 7.77614 6.72386 8 7 8H10C10.2761 8 10.5 7.77614 10.5 7.5V3C10.5 2.72386 10.2761 2.5 10 2.5H7ZM7.5 7V3.5H9.5V7H7.5Z"
                :fill="translateYMutation === 100 ? '#7CACFA' : '#333333'"
              />
            </svg>
          </b-btn>
        </div>
      </div>
    </b-form-group>

    <b-form-group>
      <div class="flex items-center justify-between">
        <label class="pb-0">Horizontal Align</label>

        <div class="flex gap-[6px]">
          <b-btn
            variant="white"
            class="w-[29px] h-[29px] flex items-center justify-center p-0"
            @click="() => translateXMutation = 0"
          >
            <svg
              width="10"
              height="11"
              viewBox="0 0 10 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.4645 0C0.721036 0 0.929 0.220568 0.929 0.492651V10.3457C0.929 10.6178 0.721036 10.8383 0.4645 10.8383C0.207964 10.8383 0 10.6178 0 10.3457V0.492651C0 0.220568 0.207964 0 0.4645 0Z"
                :fill="translateXMutation === 0 ? '#7CACFA' : '#333333'"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.858 1.47795C1.858 1.20587 2.06596 0.985303 2.3225 0.985303H8.8255C9.08204 0.985303 9.29 1.20587 9.29 1.47795V4.43386C9.29 4.70595 9.08204 4.92651 8.8255 4.92651H2.3225C2.06596 4.92651 1.858 4.70595 1.858 4.43386V1.47795ZM2.787 1.97061V3.94121H8.361V1.97061H2.787Z"
                :fill="translateXMutation === 0 ? '#7CACFA' : '#333333'"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.3225 5.91182C2.06596 5.91182 1.858 6.13238 1.858 6.40447V9.36038C1.858 9.63246 2.06596 9.85303 2.3225 9.85303H5.1095C5.36604 9.85303 5.574 9.63246 5.574 9.36038V6.40447C5.574 6.13238 5.36604 5.91182 5.1095 5.91182H2.3225ZM2.787 8.86773V6.89712H4.645V8.86773H2.787Z"
                :fill="translateXMutation === 0 ? '#7CACFA' : '#333333'"
              />
            </svg>
          </b-btn>
          <b-btn
            variant="white"
            class="w-[29px] h-[29px] flex items-center justify-center p-0"
            @click="() => translateXMutation = 50"
          >
            <svg
              width="11"
              height="12"
              viewBox="0 0 11 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.935065 0.6C0.935065 0.268629 1.14439 0 1.4026 0H4.20779C4.466 0 4.67533 0.268629 4.67533 0.6V5.4H5.61039V3.6C5.61039 3.26863 5.81971 3 6.07792 3H8.88312C9.14133 3 9.35065 3.26863 9.35065 3.6V5.4H9.81818C10.0764 5.4 10.2857 5.66863 10.2857 6C10.2857 6.33137 10.0764 6.6 9.81818 6.6H9.35065V8.4C9.35065 8.73137 9.14133 9 8.88312 9H6.07792C5.81971 9 5.61039 8.73137 5.61039 8.4V6.6H4.67533V11.4C4.67533 11.7314 4.466 12 4.20779 12H1.4026C1.14439 12 0.935065 11.7314 0.935065 11.4V6.6H0.467533C0.209321 6.6 0 6.33137 0 6C0 5.66863 0.209321 5.4 0.467533 5.4H0.935065V0.6ZM6.54546 6.6V7.8H8.41559V6.6H6.54546ZM8.41559 5.4V4.2H6.54546V5.4H8.41559ZM3.74026 1.2V5.4H1.87013V1.2H3.74026ZM1.87013 10.8V6.6H3.74026V10.8H1.87013Z"
                :fill="translateXMutation === 50 ? '#7CACFA' : '#333333'"
              />
            </svg>
          </b-btn>
          <b-btn
            variant="white"
            class="w-[29px] h-[29px] flex items-center justify-center p-0"
            @click="() => translateXMutation = 100"
          >
            <svg
              width="11"
              height="12"
              viewBox="0 0 11 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 1.63636C0 1.33512 0.230254 1.09091 0.514286 1.09091H7.71429C7.99832 1.09091 8.22857 1.33512 8.22857 1.63636V4.90909C8.22857 5.21034 7.99832 5.45455 7.71429 5.45455H0.514286C0.230254 5.45455 0 5.21034 0 4.90909V1.63636ZM1.02857 2.18182V4.36364H7.2V2.18182H1.02857Z"
                :fill="translateXMutation === 100 ? '#7CACFA' : '#333333'"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.77143 0C10.0555 0 10.2857 0.244208 10.2857 0.545455V11.4545C10.2857 11.7558 10.0555 12 9.77143 12C9.4874 12 9.25714 11.7558 9.25714 11.4545V0.545455C9.25714 0.244208 9.4874 0 9.77143 0Z"
                :fill="translateXMutation === 100 ? '#7CACFA' : '#333333'"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.11429 7.09091C4.11429 6.78966 4.34454 6.54545 4.62857 6.54545H7.71429C7.99832 6.54545 8.22857 6.78966 8.22857 7.09091V10.3636C8.22857 10.6649 7.99832 10.9091 7.71429 10.9091H4.62857C4.34454 10.9091 4.11429 10.6649 4.11429 10.3636V7.09091ZM5.14286 7.63636V9.81818H7.2V7.63636H5.14286Z"
                :fill="translateXMutation === 100 ? '#7CACFA' : '#333333'"
              />
            </svg>
          </b-btn>
        </div>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import SidConcept from '@/components/mixins/SidConcept'

export default {
  name: 'SidPositionButtons',
  mixins: [SidConcept],
  data () {
    return {
      translateX: 0,
      translateY: 0
    }
  },
  computed: {
    translateXMutation: {
      get () {
        return this.translateX
      },
      set (val) {
        this.translateX = val

        this.addSidRules([[this.getSid(), ['left', `${this.translateX}%`]]])
        this.addSidRules([[this.getSid(), [this.ruleName, `-${this.translateX}% -${this.translateYMutation}%`]]])
      }
    },
    translateYMutation: {
      get () {
        return this.translateY
      },
      set (val) {
        this.translateY = val

        this.addSidRules([[this.getSid(), ['top', `${this.translateY}%`]]])
        this.addSidRules([[this.getSid(), [this.ruleName, `-${this.translateXMutation}% -${this.translateY}%`]]])
      }
    }
  },
  created () {
    this.translateX = parseInt(this.getSidStyle(this.getSid(), 'left'))
    this.translateY = parseInt(this.getSidStyle(this.getSid(), 'top'))
  }
}
</script>