import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Live Chat'
export const group = 'Premium / Licensed'
export const paid = true
// export const dev = true
// export const access_by = 'chat_roll_status'
export const elementCode = 'CHAT'

export const defaultOptions = {
  'content': {
    chat: {
      type: 'chat-mod',
      roomID: null,
      status: '0',
      banMessage: 'You have been banned!'
    }
  }
}

export const icon = `<svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14 5H18C18.5523 5 19 5.44772 19 6V17L15.6667 14.2308C15.4872 14.0817 15.2611 14 15.0277 14H7C6.44771 14 6 13.5523 6 13V10" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13 1H2C1.44772 1 1 1.44772 1 2V13L4.33329 10.2308C4.51283 10.0817 4.73889 10 4.97231 10H13C13.5523 10 14 9.5523 14 9V2C14 1.44772 13.5523 1 13 1Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

`
export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('ElRealtimeChat', options)

    this.name = name
    this.group = group
    this.defaultOptions = defaultOptions
  }
}

