<template>
  <highliter
    v-model="section.name"
    section-type="element"
    class="highlight-element"
    :uid="section.uid"
    :dublicate-deep="parentUID"
    :dublicate="section"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="$emit('delete')"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('video')"
  >
    <div
      class="flex w-full"
      :data-sid="section.sid"
      :class="[`--${section.sid}--container`, `--${section.sid}--wrapper`]"
    >
      <img
        ref="image"
        :class="[`--${section.sid}--image`]"
        class="el-image img-fluid transition"
        :style="{content: section.options.content.video.placeholder ? `url(${section.options.content.video.placeholder})` : null}"
        alt="placeholder image"
      >

      <div
        :class="`--${section.sid}--play-btn`"
        class="play-btn"
      >
        <i
          :class="[`--${section.sid}--play-icon`, section.options.content.video.playIcon.icon]"
        />
      </div>
      <div v-show="false">
        <div class="el-overlay video-placeholder-overlay" />
      </div>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'

export default {
  name: 'FeaturedItems1',

  components: {Highliter},

  mixins: [SectionMixin]
}
</script>
