<template>
  <div class="pt-[20px]">
    <b-form-group class="pl-[3px]">
      <b-radio
        v-model="section.options.content.promo.dockTo.lg"
        value="top"
        class="cursor-pinter"
      >
        <div class="mt-[5px]">
          Dock to top
        </div>
      </b-radio>
    </b-form-group>

    <b-form-group class="pl-[3px]">
      <b-radio
        v-model="section.options.content.promo.dockTo.lg"
        value="bottom"
        class="cursor-pinter"
      >
        <div class="mt-[5px]">
          Dock to bottom
        </div>
      </b-radio>
    </b-form-group>

    <b-form-group class="pl-[3px]">
      <b-checkbox
        v-model="section.options.content.promo.closeIcon.enabled.lg"
      >
        Close icon
      </b-checkbox>
    </b-form-group>

    <div class="relative">
      <b-form-group>
        <div class="flex items-center gap-[10px]">
          <color-picker
            :color="section.options.content.promo.closeIcon.color.lg"
            @input="event => section.options.content.promo.closeIcon.color.lg = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
          />

          <label class="mb-0 pb-0">Close icon color</label>
        </div>
      </b-form-group>

      <b-form-group
        label="Set Cookie (optional)"
      >
        <div class="relative">
          <b-input
            v-model="section.options.content.promo.maxAge"
            placeholder="In minutes"
          />

          <div class="absolute top-1/2 right-[10px] -translate-y-1/2">
            <i
              v-b-tooltip.hover
              title="Determines how long the banner will be visible to the user again after closing it when visiting the site again."
              class="fa fa-question-circle cursor-pointer hover:text-blue-500 ml-1"
            />
          </div>
        </div>
      </b-form-group>

      <div
        v-if="!section.options.content.promo.closeIcon.enabled.lg"
        class="absolute top-0 left-0 bg-white w-100 h-100 z-index-100 opacity-80"
      />
    </div>
    <component
      :is="section.options.content.promo.subject.mod || 'flex-mod'"
      component-name="Flex Wrapper"
      :config="section.options.content.promo.subject"
      :hide="['visibility']"
      :local-section="section.options.content.promo.subject"
      :custom-back-options="{openedItem: 'promo-mod', section}"
    />
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin'
import FlexMod from '@/components/editor/mods/FlexMod'

export default {
  name: 'PromoMod',
  components: {FlexMod},
  mixins: [ModMixin],
  computed: {
    dockTo () {
      return this.section.options.content.promo.dockTo.lg
    }
  },
  watch: {
    dockTo (val) {
      const $scrollContainer = document.querySelector('.scrollbale-container')
      if (!$scrollContainer) return

      $scrollContainer.scrollTo({
        top: val === 'top' ? 0 : $scrollContainer.scrollHeight,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>