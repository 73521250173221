import _ from 'lodash'

export const toJSON = (value) => {
  if (value && typeof value === 'object') {
    return value
  }

  if (value && typeof value === 'string') {
    return JSON.parse(value)
  }

  return {}
}

export const toStringify = (value) => {
  if (value && typeof value === 'object') {
    return JSON.stringify(value)
  }

  return '{}'
}

export const clearContent = (content, safeMode = false) => {
  let contentDraft = content
  if (!content) {
    return '[]'
  }

  contentDraft = contentDraft.replace(/"id":\d+,?/g, '')
    .replace(/"form_id":\d+,?/g, '')


  if (!safeMode) {
    contentDraft = contentDraft.replace(/"action":".*?",?/g, '')
      .replace(/"customLink":".*?",?/g, '')
  }

  contentDraft = contentDraft.replace(/"actions":\[.*?\](,?)/g, '"actions": []$1')
    .replace(/"action":\{.*?\}(,?)/g, '"action": null$1')

  return contentDraft.replace(/,\}/g, '}')
}


export const deepUnset = (obj, keyToRemove) => {
  if (!_.isObject(obj)) {
    return
  }

  // Check and unset the key at the current level
  if (_.has(obj, keyToRemove)) {
    _.unset(obj, keyToRemove)
  }

  // Recur for all nested objects and arrays
  _.forOwn(obj, value => {
    if (_.isObject(value)) {
      deepUnset(value, keyToRemove)
    }
  })

  return obj
}

export const findDeep = (obj, key, value) => {
  if (_.get(obj, key) === value) {
    return obj
  }

  for (const k in obj) {
    if (_.isObject(obj[k])) {
      const found = findDeep(obj[k], key, value)
      if (found !== undefined) {
        return found
      }
    }
  }
  return undefined
}