<template>
  <b-container
    fluid
    class="py-5"
  >
    <b-row>
      <b-col
        cols="8"
        offset="2"
      >
        <b-form-group>
          <b-input
            v-model="search"
            placeholder="Search"
            class="max-w-[300px]"
          />
        </b-form-group>

        <b-table
          outlined
          :items="items"
          :fields="fields"
          :busy="loading"
        >
          <template #cell(details)="row">
            <b-button
              size="sm"
              class="mr-2"
              @click="row.toggleDetails"
            >
              {{ row.detailsShowing ? 'Hide' : 'Show' }} Details
            </b-button>
          </template>

          <template #row-details="row">
            <b-card>
              <div class="bg-light p-4 mb-4">
                <div
                  v-for="userDetailsKey in Object.keys(row.item).filter(item => typeof row.item[item] !== 'object')"
                  :key="userDetailsKey"
                  class="text-[16px] mb-2"
                >
                  <b>{{ userDetailsKey }}:</b> {{ row.item[userDetailsKey] }}
                </div>

                <template v-if="row.item.user_subscription">
                  <div class="text-[16px] mb-2">
                    <b>Plan Name:</b> {{ row.item.user_subscription.plan.name }}
                  </div>
                  <div class="text-[16px] mb-2">
                    <b>Plan End:</b> {{ row.item.user_subscription.plan_period_end }}
                  </div>
                  <div class="text-[16px] mb-2">
                    <b>Plan Start:</b> {{ row.item.user_subscription.plan_period_start }}
                  </div>
                </template>

                <b-btn
                  variant="primary"
                  class="mt-2"
                  @click="getAccess(row.item.id)"
                >
                  {{ !copied ? 'Get Access Link' : 'Access Link Copied!' }}
                </b-btn>
              </div>

              <h6 class="mb-3">
                Projects:
              </h6>

              <div class="grid grid-cols-3 gap-3">
                <div
                  v-for="project in row.item.projects"
                  :key="project.id"
                  class="text-[16px] mb-2 border rounded p-3"
                >
                  <div
                    class="text-[16px] mb-2"
                  >
                    <b>ID:</b> {{ project.id }}
                  </div>
                  <div
                    class="text-[16px] mb-2"
                  >
                    <b>Name:</b> {{ project.name }}
                  </div>
                  <div
                    class="text-[16px] mb-2"
                  >
                    <b>Subdomain:</b> <a
                      :href="`https://${project.subdomain}.estage.site`"
                      target="_blank"
                    >{{ `https://${project.subdomain}.estage.site` }}</a>
                  </div>
                  <div
                    class="text-[16px] mb-2"
                  >
                    <b>Domain:</b> <span v-if="!project.domain">No Domain</span> <a
                      v-else
                      :href="`https://${project.domain}`"
                      target="_blank"
                    >{{ project.domain }}</a>
                  </div>
                </div>
              </div>
            </b-card>
          </template>
        </b-table>

        <b-pagination
          v-model="currentPage"
          size="md"
          :total-rows="tableContent.total"
          :per-page="tableContent.per_page"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'Dev',
  data () {
    return {
      fields: ['id', 'name', 'email', 'details'],
      tableContent: {},
      currentPage: 1,
      loading: true,
      search: '',
      timer: null,
      copied: false
    }
  },
  computed: {
    items () {
      return this.tableContent.data || []
    }
  },
  watch: {
    currentPage () {
      this.fetchUsers()
      window.scrollTo({top: 0, behavior: 'smooth'})
    },
    search () {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => this.fetchUsers(), 400)
    }
  },
  mounted () {
    this.fetchUsers()
  },
  methods: {
    async getAccess (id) {
      this.loading = true
      const hash = await axios.get(`api/admin/support/users/${id}`)
      this.loading = false

      await navigator.clipboard.writeText(`https://app.estage.com?auth_token=${hash.data.data.access_token}`)
      this.copied = true

      setTimeout(() => {
        this.copied = false
      }, 800)
    },
    async fetchUsers () {
      this.loading = true
      const items = await axios.get(`api/admin/support/users?page=${this.currentPage}&q=${this.search}`)
      this.tableContent = items.data.data
      this.loading = false
    }
  }
}
</script>