
const state = () => ({
  templates: [],
  templatesData: {},
  categories: [],
  themes: [],
  isFree: [],
  search: '',
  loading: false
})

const getters = {

}

const actions = {
  async getTemplates ({state}) {
    state.loading = true
    const categories = state.categories.length ? '&category_id[]=' + state.categories.join('&category_id[]=') : ''
    const themes = state.themes.length ? '&themes[]=' + state.themes.join('&themes[]=') : ''
    const isFree = state.isFree.length ? '&is_free[]=' + state.isFree.join('&is_free[]=') : ''

    return await axios.get(`api/templates?search=${state.search}${categories}${themes}${isFree}&per_page=30`)
      .then(({data}) => {
        state.templatesData = data.data
        state.templates = data.data.data
      })
      .finally(() => {
        state.loading = false
      })
  },

  async getMyTemplates ({state}) {
    return await axios.get('api/templates/my-templates')
      .then(({data}) => {
        state.templates = data.data
      })
  }
}

const mutations = {}

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
}
