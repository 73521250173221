import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import storeRoot from './../store'
import Cookie from 'js-cookie'

Vue.use(VueRouter)


const router = new VueRouter({ mode: 'history',  routes })

router.beforeEach((to, from, next) => {
  if (process.env.NODE_ENV === 'development') {
    if(
      storeRoot.getters['auth/authenticated'] &&
      (process.env.NODE_ENV === 'development' ? localStorage.getItem('sanctum_token') : Cookie.get('cross_estage_token'))
    ) {
      next()
    } else {
      if (to.query.auth_token) {
        localStorage.setItem('sanctum_token', to.query.auth_token)
        window.location = '/'
        next()
      } else {
        if([ '/login' ].indexOf(to.path) === -1) {
          next('/login')
        } else {
          next()
        }
      }
    }
  }

  next()
})

export default router

