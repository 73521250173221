<template>
  <div
    v-if="products.length === 1 && products[0].prices.length === 0"
    :data-sid="`product-description-${section.sid}`"
    class="position-relative text-container"
    :class="`--${section.sid}-margin`"
  >
    <highliter
      v-model="section.name"
      class="highlight-element"
      section-type="element"
      :hide-right="true"
      @move-to="to => $emit('move-to', to)"
      @add-subject="$emit('add-subject')"
    >
      <p
        :class="`--${section.sid}-text`"
        class="product-description m-0"
        style="overflow-wrap: anywhere;"
      >
        {{ products[0].description }}
      </p>
    </highliter>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter.vue'
export default {
  name: 'ElProductDescription',
  mixins: [SectionMixin],
  components: {Highliter},
  computed: {
    ...mapState('products', {
      products: state => state.selected
    })
  }
}
</script>