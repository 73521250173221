<template>
  <div class="w-full h-full flex items-center justify-start px-[40px] py-[32px] gap-x-[20px] rounded-[5px] font-[ProximaNova] border border-[#E3E5EB] shadow-[0_2px_5px_0_rgba(0,0,0,0.07)]">
    <div>
      <slot name="before" />
    </div>

    <div class="w-full flex flex-col">
      <p class="text-[16px] leading-[24px] font-[600] text-[#000000] mb-0">
        {{ title }}
      </p>
      <p class="text-[16px] leading-[24px] font-[400] text-[#9D9D9D] mb-0">
        {{ description }}
      </p>
    </div>

    <b-btn
      variant="primary"
      size="md"
      class="min-w-[160px] h-[48px] !font-[700]"
      @click="openState"
    >
      {{ cta }}
    </b-btn>

    <modify-plan-modal v-model="modify" />
    <purchase-plan-modal
      v-model="upgrade"
      :business-plus="businessPlus"
      year
    />
  </div>
</template>

<script>
import ModifyPlanModal from '@/layouts/dashboard/compontens/account/billing/components/ModifyPlanModal.vue'
import PurchasePlanModal from '@/components/editor/modals/purchase-plan/PurchasePlanModal.vue'
export default {
  name: 'ModifyPlan',
  components: {
    PurchasePlanModal,
    ModifyPlanModal
  },
  props: {
    title: {
      type: String,
      default: 'Want to modify / cancel your subscription?'
    },
    description: {
      type: String,
      default: 'Upgrade / Downgrade or cancel your plan'
    },
    cta: {
      type: String,
      default: 'Modify Plan'
    },
    state: {
      type: String,
      default: null
    },
    businessPlus: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      modify: false,
      upgrade: false
    }
  },
  methods: {
    openState () {
      if (this.state === 'upgrade') {
        this.upgrade = true
      } else {
        this.modify = true
      }
    }
  }
}
</script>