var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.element)?_c('button-mod-v2',{staticClass:"!py-[14px] !pl-[20px] !pr-[16px]",attrs:{"config":_vm.element.options.content.button,"local-section":_vm.element,"hide":['align', 'actions', 'realTimeVisibility'],"component-name":"Button","group-prefix":"Button","button-class":"!px-[16px]"},scopedSlots:_vm._u([{key:"button-align",fn:function(){return [_c('sid-buttons-group',{attrs:{"label":"Alignment","options":[
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            customProperties: [[(".--" + (_vm.element.sid) + "--wrapper"), ['width', 'auto']], [(".--" + (_vm.element.sid) + "--wrapper"), ['margin-left', '']], [(".--" + (_vm.element.sid) + "--wrapper"), ['--align', 'start']]],
            value: 'start',
            tooltip: 'Start'
          },
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            customProperties: [[(".--" + (_vm.element.sid) + "--wrapper"), ['width', 'auto']], [(".--" + (_vm.element.sid) + "--wrapper"), ['margin-left', 'auto']], [(".--" + (_vm.element.sid) + "--wrapper"), ['margin-right', 'auto']], [(".--" + (_vm.element.sid) + "--wrapper"), ['--align', 'center']]],
            value: 'center',
            tooltip: 'Center'
          },
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            customProperties: [[(".--" + (_vm.element.sid) + "--wrapper"), ['width', 'auto']], [(".--" + (_vm.element.sid) + "--wrapper"), ['margin-left', 'auto']], [(".--" + (_vm.element.sid) + "--wrapper"), ['margin-right', '']], [(".--" + (_vm.element.sid) + "--wrapper"), ['--align', 'end']]],
            value: 'end',
            tooltip: 'End'
          },
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'13\' height=\'14\' viewBox=\'0 0 13 14\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cg clip-path=\'url(%23clip0_336_14)\'%3E%3Cpath d=\'M2.64185 6.88083L2.64185 3.76855L11.2976 3.76855L11.2976 6.88083L2.64185 6.88083Z\' fill=\'%237E7E7E\'/%3E%3Cpath d=\'M2.64185 10.4726L2.64185 7.36035L11.2976 7.36035L11.2976 10.4726L2.64185 10.4726Z\' fill=\'%237E7E7E\'/%3E%3Cpath d=\'M11.7787 12.8665L11.7787 1.375L12.5 1.375L12.5 12.8665L11.7787 12.8665Z\' fill=\'%237E7E7E\'/%3E%3Cpath d=\'M1.44006 12.8665L1.44006 1.375L2.16138 1.375L2.16138 12.8665L1.44006 12.8665Z\' fill=\'%237E7E7E\'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id=\'clip0_336_14\'%3E%3Crect width=\'13\' height=\'12\' fill=\'white\' transform=\'translate(12.5 0.5) rotate(90)\'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A',
            customProperties: [[(".--" + (_vm.element.sid) + "--wrapper"), ['width', '100%']], [(".--" + (_vm.element.sid) + "--wrapper"), ['margin-left', '']], [(".--" + (_vm.element.sid) + "--wrapper"), ['margin-right', '']], [(".--" + (_vm.element.sid) + "--wrapper"), ['--align', 'stretch']]],
            value: 'stretch',
            tooltip: 'Stretch'
          }
        ],"sid":(".--" + (_vm.element.sid) + "--wrapper"),"rule-name":"--align"}})]},proxy:true}],null,false,2254506047)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }