<template>
  <div>
    <SidbearSettingsGroup
      title="Links"
      active
    >
      <b-form-group v-if="linked">
        <button
          class="flex p-0 text-[11px] hover:bg-gray-100 items-center text-dark border rounded-[4px] w-[114px] h-[30px] justify-content-center gap-[5px] cursor-pointer font-[500] hover:text-[#3D82EA] text-[#7E7E7E]"
          @click="() => unlinkSid()"
        >
          Unlink Styles
          <i class="fa fa-link text-primary" />
        </button>
      </b-form-group>

      <draggable
        v-model="config.links"
        handle=".move"
      >
        <div
          v-for="(item, index) in config.links"
          :key="index"
          class="border group rounded-[2px] h-[29px] flex items-center pl-[13px] hover:bg-[#F1F5FD] cursor-pinter mb-[12px]"
        >
          <div class="mr-[11px] move cursor-move">
            <svg
              fill="none"
              height="11"
              viewBox="0 0 11 11"
              width="11"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.57745 0.894104H0.568359V3.8941H3.57745V0.894104Z"
                fill="#3D82EA"
              />
              <path
                d="M10.5984 0.894104H7.58936V3.8941H10.5984V0.894104Z"
                fill="#3D82EA"
              />
              <path
                d="M3.57745 7.8941H0.568359V10.8941H3.57745V7.8941Z"
                fill="#3D82EA"
              />
              <path
                d="M10.5984 7.8941H7.58936V10.8941H10.5984V7.8941Z"
                fill="#3D82EA"
              />
            </svg>
          </div>

          <i
            :class="item.icon"
            class="text-[#3D82EA] ml-[2px]"
          />

          <button
            class="w-[38px] h-[26px] text-center border-left bg-[#fff] p-0 ml-auto"
            @click="newListItemModal = index"
          >
            <svg
              fill="none"
              height="12"
              viewBox="0 0 12 12"
              width="12"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clip-rule="evenodd"
                d="M8.34918 0.18252C8.57698 -0.0452861 8.94633 -0.0452861 9.17414 0.18252L11.5075 2.51585C11.7353 2.74366 11.7353 3.11301 11.5075 3.34081L3.3408 11.5075C3.23141 11.6169 3.08303 11.6783 2.92832 11.6783H0.594991C0.272825 11.6783 0.0116577 11.4172 0.0116577 11.095V8.76167C0.0116577 8.60696 0.0731159 8.45858 0.182512 8.34919L8.34918 0.18252ZM7.83662 2.345L9.34499 3.85337L10.27 2.92833L8.76166 1.41996L7.83662 2.345ZM8.52003 4.67833L7.01166 3.16996L2.00328 8.17833L3.51166 9.68671L8.52003 4.67833ZM2.6867 10.5117L1.17832 9.00329V10.5117H2.6867Z"
                fill="#6E747A"
                fill-rule="evenodd"
              />
            </svg>
          </button>
          <button
            class="w-[38px] h-[26px] text-center border-left bg-[#fff] p-0"
            @click="config.links.splice(index, 1)"
          >
            <svg
              fill="none"
              height="10"
              viewBox="0 0 10 10"
              width="10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.32913 0.170839C1.10132 -0.0569667 0.731973 -0.0569667 0.504167 0.170839C0.276362 0.398645 0.276362 0.767991 0.504167 0.995797L4.17507 4.6667L0.504167 8.33759C0.276362 8.5654 0.276362 8.93475 0.504167 9.16255C0.731973 9.39036 1.10132 9.39036 1.32913 9.16255L5.00002 5.49165L8.67083 9.16246C8.89864 9.39027 9.26799 9.39027 9.49579 9.16246C9.7236 8.93466 9.7236 8.56531 9.49579 8.33751L5.82498 4.6667L9.49579 0.995885C9.7236 0.768079 9.7236 0.398733 9.49579 0.170927C9.26799 -0.0568786 8.89864 -0.0568786 8.67083 0.170927L5.00002 3.84174L1.32913 0.170839Z"
                fill="#6E747A"
              />
            </svg>
          </button>
        </div>
      </draggable>

      <button
        class="flex items-center border gap-[5px] rounded-[4px] font-[600] text-dark !text-[11px] h-[29px] px-[18px] shadow-[0_2px_3px_rgba(0, 0, 0, 0.06)] mb-[25px]"
        @click="newListItemModal = true"
      >
        Add Menu Item
        <span class="text-[#3D82EA] text-[20px]">+</span>
      </button>

      <sid-flex-direction
        :sid="`.--${section.sid}--wrapper`"
        rule-name="flex-direction"
      />

      <sid-range-slider
        label="Gap"
        :sid="`.--${section.sid}--wrapper`"
        rule-name="gap"
        :dimensions="[]"
      />

      <sid-range-slider
        :sid="`.--${section.sid}--link`"
        rule-name="font-size"
        label="Size"
        var="--social-size"
        class="mb-4"
      />


      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="mb-0">Alignment</label>

          <sid-horizontal-align
            :sid="`.--${section.sid}--container`"
            rule-name="justify-content"
          />
        </div>
      </b-form-group>

      <sid-background
        :sid="`.--${section.sid}--wrapper`"
      />
      
      <b-modal
        v-model="getNewListItemModal"
        hide-footer
        hide-header
      >
        <icon
          class="cursor-pinter ml-3"
          icon="close.svg"
          @click.native="newListItemModal = false"
        />

        <h4 class="h2 font-weight-bold text-center my-4">
          Add Link
        </h4>

        <div class="p-5">
          <b-form-group
            v-if="typeof newListItemModal !== 'number'"
            label="Icon"
          >
            <v-select
              v-model="icon"
              :options="options"
              :reduce="social => social.key"
              class="w-100 position-relative custom-select"
              placeholder="Select Icon"
            >
              <template v-slot:selected-option="option">
                <div class="d-flex align-items-center">
                  <i
                    :class="option.key"
                    class="text-primary mr-2"
                  />
                  <div>{{ option.label }}</div>
                </div>
              </template>

              <template v-slot:option="option">
                <div class="d-flex align-items-center">
                  <i
                    :class="option.key"
                    class="text-primary mr-3"
                  />
                  <div>{{ option.label }}</div>
                </div>
              </template>
            </v-select>
          </b-form-group>

          <b-form-group
            v-else
            label="Icon"
          >
            <v-select
              v-model="config.links[newListItemModal].icon"
              :options="options"
              :reduce="social => social.key"
              class="w-100 position-relative custom-select"
              placeholder="Select Icon"
            >
              <template v-slot:selected-option="option">
                <div class="d-flex align-items-center">
                  <i
                    :class="option.key"
                    class="text-primary mr-2"
                  />
                  <div>{{ option.label }}</div>
                </div>
              </template>

              <template v-slot:option="option">
                <div class="d-flex align-items-center">
                  <i
                    :class="option.key"
                    class="text-primary mr-3"
                  />
                  <div>{{ option.label }}</div>
                </div>
              </template>
            </v-select>
          </b-form-group>

          <b-row>
            <b-col cols="5">
              <b-form-group label="Background Color">
                <color-picker
                  v-if="typeof newListItemModal !== 'number'"
                  :color="background"
                  @input="event => background = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
                />
                <color-picker
                  v-else
                  :color="config.links[newListItemModal].background"
                  @input="event => config.links[newListItemModal].background = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
                />
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group label="Color">
                <color-picker
                  v-if="typeof newListItemModal !== 'number'"
                  :color="color"
                  @input="event => color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
                />
                <color-picker
                  v-else
                  :color="config.links[newListItemModal].color"
                  @input="event => config.links[newListItemModal].color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-group label="Shape">
            <v-select
              v-if="typeof newListItemModal !== 'number'"
              v-model="shape"
              :options="shapes"
              :reduce="shape => shape.key"
              :searchable="false"
              class="w-100 position-relative custom-select"
            />
            <v-select
              v-else
              v-model="config.links[newListItemModal].shape"
              :options="shapes"
              :reduce="shape => shape.key"
              :searchable="false"
              class="w-100 position-relative custom-select"
            />
          </b-form-group>

          <b-form-group label="Link">
            <b-input
              v-if="typeof newListItemModal !== 'number'"
              v-model="href"
            />
            <b-input
              v-else
              v-model="config.links[newListItemModal].href"
            />
          </b-form-group>

          <!--        <b-form-group v-if="typeof newListItemModal !== 'number'">-->
          <!--          <b-checkbox v-model="blank">Open in new tab</b-checkbox>-->
          <!--        </b-form-group>-->

          <!--        <b-form-group v-else>-->
          <!--          <b-checkbox v-model="config.links[newListItemModal].blank">Open in new tab</b-checkbox>-->
          <!--        </b-form-group>-->

          <div
            v-if="typeof newListItemModal !== 'number'"
            class="d-flex justify-content-center"
          >
            <b-btn
              variant="primary"
              @click="addListItem"
            >
              Add Link
            </b-btn>
          </div>
        </div>
      </b-modal>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Border & Radius">
      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Border Type</label>

          <sid-select
            :sid="`.--${section.sid}--wrapper`"
            rule-name="border-style"
            :searchable="false"
            class="w-[118px]"
            :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <sid-linked-group
          :sid="`.--${section.sid}--wrapper`"
          label="Border Width"
          rule-name="border"
          class="!mb-[7px]"
          :angles="[
            {
              text: '',
              value: 'top-width'
            },
            {
              text: '',
              value: 'right-width'
            },
            {
              text: '',
              value: 'bottom-width'
            },
            {
              text: '',
              value: 'left-width'
            }
          ]"
        />

        <div class="flex w-full justify-end">
          <sid-color
            :sid="`.--${section.sid}--wrapper`"
            rule-name="border-color"
          />
        </div>
      </b-form-group>

      <sid-linked-group
        label="Radius"
        :sid="`.--${section.sid}--wrapper`"
        rule-name="border"
        :angles="[
          {
            text: '',
            value: 'top-left-radius'
          },
          {
            text: '',
            value: 'top-right-radius'
          },
          {
            text: '',
            value: 'bottom-left-radius'
          },
          {
            text: '',
            value: 'bottom-right-radius'
          }
        ]"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid}--wrapper`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}--wrapper`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import draggable from 'vuedraggable'
import {mapGetters} from 'vuex'
import ColorPicker from '../utils/ColorPicker'
import _ from 'lodash'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect'
import SidColor from '@/components/editor/components/sid-controls/SidColor'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground'
import SidStyles from '@/components/mixins/SidStyles'
import SidHorizontalAlign from '@/components/editor/components/sid-controls/SidHorizontalAlign'
import SidFlexDirection
  from '@/components/editor/components/sid-controls/sid-control-pressets/flex/SidFlexDirection.vue'

export default {
  name: 'ListMod',
  components: {
    SidFlexDirection,
    SidHorizontalAlign,
    SidBackground,
    SidRangeSlider, SidColor, SidSelect, SidLinkedGroup, SidbearSettingsGroup, ColorPicker, draggable},
  mixins: [ModMixin, SidStyles],
  data () {
    return {
      newListItemModal: false,
      icon: '',
      href: '',
      blank: '',
      shape: 'None',
      color: '#ffffff',
      background: '#1f3b9c',
      shapes: [
        {
          key: null,
          label: 'None'
        },
        {
          key: 'circle',
          label: 'Circle'
        }
      ],
      options: [
        {
          key: 'fa fa-google',
          label: 'Google'
        },
        {
          key: 'fa fa-flickr',
          label: 'Flickr'
        },
        {
          key: 'fa fa-facebook',
          label: 'Facebook'
        },
        {
          key: 'fab fa-facebook',
          label: 'Facebook Circle'
        },
        {
          key: 'fab fa-whatsapp',
          label: 'Whatsapp'
        },
        {
          key: 'fab fa-skype',
          label: 'Skype'
        },
        {
          key: 'fas fa-sms',
          label: 'SMS'
        },
        {
          key: 'fa-brands fa-threads',
          label: 'Threads'
        },
        {
          key: 'fa fa-twitter',
          label: 'Twitter'
        },
        {
          key: 'fa-brands fa-x-twitter',
          label: 'X Twitter'
        },
        {
          key: 'fa-brands fa-reddit',
          label: 'Reddit'
        },
        {
          key: 'fa fa-telegram',
          label: 'Telegram'
        },
        {
          key: 'fa fa-vk',
          label: 'VKontakte'
        },
        {
          key: 'fa fa-youtube',
          label: 'YouTube'
        },
        {
          key: 'fa fa-instagram',
          label: 'Instagram'
        },
        {
          key: 'fab fa-linkedin-in',
          label: 'LinkedIn'
        },
        {
          key: 'fab fa-pinterest',
          label: 'Pinterest'
        },
        {
          key: 'fab fa-tiktok',
          label: 'TikTok'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('pages', {
      pages: 'getPages'
    }),
    margin () {
      if (!this.config.margin) {
        this.$set(this.config, 'margin', {})
      }

      return this.config.margin
    },
    align: {
      get () {
        return _.get(this.config.resolutionStyle, [this.resolutioner, 'align'], 'start')
      },
      set (val) {
        this.$set(this.config.resolutionStyle, this.resolutioner, {
          ..._.get(this.config.resolutionStyle, [this.resolutioner], {}),
          align: val
        })
      }
    },
    getNewListItemModal: {
      get () {
        if (this.newListItemModal === 0) {
          return true
        }

        return !!this.newListItemModal
      },
      set (val) {
        this.newListItemModal = val
      }
    },
    fontSize: {
      get () {
        return parseInt(this.config.style.fontSize)
      },
      set (val) {
        this.config.style.fontSize = `${val}px`
      }
    }
  },
  methods: {
    addListItem () {
      this.config.links.push({
        icon: this.icon,
        href: this.href,
        blank: this.blank,
        color: this.color,
        shape: this.shape,
        background: this.background
      })

      this.newListItemModal = false
    }
  }
}
</script>
