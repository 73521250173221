<template>
  <div>
    <SidbearSettingsGroup
      title="Playlist"
      active
    >
      <b-form-group v-if="linked">
        <button
          class="flex p-0 text-[11px] hover:bg-gray-100 items-center text-dark border rounded-[4px] w-[114px] h-[30px] justify-content-center gap-[5px] cursor-pointer font-[500] hover:text-[#3D82EA] text-[#7E7E7E]"
          @click="() => unlinkSid()"
        >
          Unlink Styles
          <i class="fa fa-link text-primary" />
        </button>
      </b-form-group>

      <b-form-group>
        <draggable
          v-model="section.options.content.playlist.items"
          handle=".move"
        >
          <transition-group name="shufle">
            <mod-drag-item
              v-for="(item, index) in section.options.content.playlist.items"
              :key="`key-${index}`"
              :item="item"
              :active="active === index"
              @open="() => active = index"
              @delete="() => deleteItem(index)"
            >
              <template #active>
                <rule-tabs :tabs="['Simple', 'Advanced']">
                  <template #Simple>
                    <div class="pt-2">
                      <b-form-group label="Insert Video">
                        <v-select
                          v-model="item.videoType"
                          :searchable="false"
                          :options="[
                            'ESTAGE',
                            'External URL'
                          ]"
                        />
                      </b-form-group>
                      
                      <sid-upload-video
                        v-if="item.videoType === 'ESTAGE'"
                        :index="index"
                        :sid="`.--${section.sid}--playlist-item`"
                        rule-name="url"
                      />

                      <b-form-group
                        v-else-if="item.videoType === 'External URL'"
                        label="Video URL"
                      >
                        <b-input v-model="item.url" />
                      </b-form-group>

                      <b-form-group label="Start Time">
                        <b-input
                          v-model="item.start"
                          v-mask="timeMask"
                          placeholder="00:00:00"
                        />
                      </b-form-group>

                      <b-form-group label="Title">
                        <b-input v-model="item.title" />
                      </b-form-group>

                      <b-form-group label="Description">
                        <b-textarea
                          v-model="item.description"
                          class="min-h-[150px]"
                        />
                      </b-form-group>

                      <b-form-group
                        label="Preview"
                      >
                        <image-upload
                          :image="item"
                          disable-settigns
                        />
                      </b-form-group>
                    </div>
                  </template>
                  <template #Advanced>
                    <b-form-group label="Connect tabs element">
                      <pick-element
                        v-model="tabs"
                        :allow-picks="['ElGridCollapseV3']"
                        :grab-all="true"
                      />
                    </b-form-group>

                    <v-select
                      v-if="tabs.subject"
                      v-model="item.custom_url"
                      :options="tabs.subject.gridCollapse.items.filter(item => item.hash)"
                      :reduce="tab => tab.hash"
                      label="title"
                    />
                  </template>
                </rule-tabs>
              </template>
            </mod-drag-item>
          </transition-group>
        </draggable>
      </b-form-group>

      <button
        class="flex items-center border gap-[5px] rounded-[4px] font-[600] text-dark !text-[11px] h-[29px] px-[18px] shadow-[0_2px_3px_rgba(0, 0, 0, 0.06)] mb-[25px]"
        @click="addSlide"
      >
        Add Item
        <span class="text-[#3D82EA] text-[20px]">+</span>
      </button>

      <b-form-group label="Connect video element">
        <pick-element
          v-model="section"
          :allow-picks="['ElVideoV2']"
        />
      </b-form-group>

      <sid-flex-direction
        :sid="`.--${section.sid}--playlist`"
        rule-name="flex-direction"
      />

      <b-form-group>
        <sid-range-slider
          rule-name="width"
          :sid="`.--${section.sid}--playlist`"
          default-dimension="%"
          :dimensions="['%', 'vw', 'px']"
          label="Width"
          var="--width"
          measurement="width"
        />
      </b-form-group>

      <sid-range-slider
        rule-name="height"
        :sid="`.--${section.sid}--playlist`"
        default-dimension="%"
        :dimensions="['%', 'vh', 'px']"
        label="Height"
        var="--height"
        measurement="height"
      />

      <sid-range-slider
        label="Gap"
        :sid="`.--${section.sid}--playlist`"
        rule-name="gap"
        :dimensions="[]"
      />

      <b-form-group>
        <label class="mb-0">
          Overflow
          <i
            v-b-tooltip.topright.hover
            title="Relevant for fixed height only."
            class="fa fa-question-circle cursor-pointer hover:text-blue-500 ml-1"
          />
        </label>

        <b-form-group>
          <sid-select
            :sid="`.--${section.sid}--playlist`"
            rule-name="overflow"
            :options="[{label: 'Scroll', value: 'auto'}, {label: 'Visible', value: ''}]"
          />
        </b-form-group>
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Playlist Item"
    >
      <rule-tabs :tabs="['Normal', 'Hover', 'Active']">
        <template #Normal>
          <b-form-group>
            <sid-range-slider
              rule-name="width"
              :sid="`.--${section.sid}--playlist-item`"
              default-dimension="%"
              :dimensions="['%', 'vw', 'px']"
              label="Width"
              var="--width"
              measurement="width"
            />
          </b-form-group>

          <sid-range-slider
            rule-name="height"
            :sid="`.--${section.sid}--playlist-item`"
            default-dimension="%"
            :dimensions="['%', 'vh', 'px']"
            label="Height"
            var="--height"
            measurement="height"
          />

          <sid-flex-direction
            :sid="`.--${section.sid}--playlist-item`"
            rule-name="flex-direction"
          />

          <b-form-group>
            <sid-buttons-group
              label="Alignment"
              :options="[
                {
                  svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.54688\' y=\'1.59863\' width=\'4.1387\' height=\'7.67361\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.32251\' y=\'1.59863\' width=\'4.1387\' height=\'5.43547\' fill=\'%237E7E7E\'/%3E%3Crect width=\'15.2814\' height=\'0.959201\' transform=\'matrix(1 0 0 -1 0 0.958984)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                  value: 'start',
                  tooltip: 'Start'
                },
                {
                  svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'9\' viewBox=\'0 0 16 9\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.87305\' y=\'0.349243\' width=\'4.05866\' height=\'8.46585\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.55664\' y=\'0.976318\' width=\'4.05866\' height=\'6.8981\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9858\' height=\'0.94065\' transform=\'matrix(1 0 0 -1 0.0634766 5.05249)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                  value: 'center',
                  tooltip: 'Center'
                },
                {
                  svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.0532\' height=\'7.51508\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 3.43945 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.0532\' height=\'5.32318\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 8.11523 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9657\' height=\'0.939385\' transform=\'matrix(1 0 0 -1 0.632812 9.43018)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                  value: 'end',
                  tooltip: 'End'
                }
              ]"
              :sid="`.--${section.sid}--playlist-item`"
              rule-name="align-items"
            />
          </b-form-group>

          <sid-align
            :sid="`.--${section.sid}--playlist-item`"
            rule-name="text-align"
            label="Text Alignment"
          />

          <sid-range-slider
            label="Gap"
            :sid="`.--${section.sid}--playlist-item`"
            rule-name="gap"
            :dimensions="[]"
          />

          <sid-background
            :sid="`.--${section.sid}--playlist-item`"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--playlist-item`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--playlist-item`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              css-var="--border-size"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--playlist-item`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--playlist-item`"
            rule-name="border"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />

          <b-form-group
            label="Shadow"
          >
            <sid-box-shadow
              :sid="`.--${section.sid}--playlist-item`"
              rule-name="box-shadow"
            />
          </b-form-group>

          <sid-linked-group
            label="Padding"
            :sid="`.--${section.sid}--playlist-item`"
            rule-name="padding"
            :min="0"
            :angles="[
              {
                text: '',
                value: 'top'
              },
              {
                text: '',
                value: 'right'
              },
              {
                text: '',
                value: 'bottom'
              },
              {
                text: '',
                value: 'left'
              }
            ]"
          />

          <sid-linked-group
            label="Margin"
            :sid="`.--${section.sid}--playlist-item`"
            rule-name="margin"
            :min="-2000"
            :max="2000"
            :angles="[
              {
                text: '',
                value: 'top'
              },
              {
                text: '',
                value: 'right'
              },
              {
                text: '',
                value: 'bottom'
              },
              {
                text: '',
                value: 'left'
              }
            ]"
          />
        </template>

        <template #Hover>
          <sid-background
            :sid="`.--${section.sid}--playlist-item`"
            presudo=":hover"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--playlist-item`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                presudo=":hover"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--playlist-item`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              css-var="--border-size"
              presudo=":hover"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--playlist-item`"
                rule-name="border-color"
                presudo=":hover"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--playlist-item`"
            rule-name="border"
            presudo=":hover"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />

          <b-form-group
            label="Shadow"
          >
            <sid-box-shadow
              :sid="`.--${section.sid}--playlist-item`"
              rule-name="box-shadow"
              presudo=":hover"
            />
          </b-form-group>
        </template>

        <template #Active>
          <sid-background
            :sid="`.--${section.sid}--playlist-item.active`"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--playlist-item.active`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--playlist-item.active`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              css-var="--border-size"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--playlist-item.active`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--playlist-item.active`"
            rule-name="border"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />

          <b-form-group
            label="Shadow"
          >
            <sid-box-shadow
              :sid="`.--${section.sid}--playlist-item.active`"
              rule-name="box-shadow"
            />
          </b-form-group>

          <sid-linked-group
            label="Margin"
            :sid="`.--${section.sid}--playlist-item.active`"
            rule-name="margin"
            :min="-2000"
            :max="2000"
            :angles="[
              {
                text: '',
                value: 'top'
              },
              {
                text: '',
                value: 'right'
              },
              {
                text: '',
                value: 'bottom'
              },
              {
                text: '',
                value: 'left'
              }
            ]"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Title Style">
      <rule-tabs :tabs="['Normal', 'Active']">
        <template #Normal>
          <sid-checkbox
            :sid="`.--${section.sid}--title`"
            rule-name="display"
            value="none"
            unchecked-value=""
          >
            Hide
          </sid-checkbox>

          <b-form-group>
            <div class="flex gap-[10px] items-center">
              <label class="pb-0">Color</label>

              <sid-color
                :sid="`.--${section.sid}--title`"
                rule-name="color"
              />
            </div>
          </b-form-group>

          <sid-font
            :sid="`.--${section.sid}--title`"
            rule-name="color"
          />

          <sid-range-slider
            label="Font Size"
            :sid="`.--${section.sid}--title`"
            default-dimension="px"
            :px-range="[0, 300]"
            rule-name="font-size"
          />

          <sid-range-slider
            label="Line-Height"
            :sid="`.--${section.sid}--title`"
            rule-name="line-height"
            :default-dimension="''"
            :min="0"
            :max="5"
            :step=".1"
            var="--line-height"
            :dimensions="[]"
          />

          <sid-linked-group
            label="Margin"
            :sid="`.--${section.sid}--title`"
            rule-name="margin"
            :min="-2000"
            :max="2000"
            :angles="[
              {
                text: '',
                value: 'top'
              },
              {
                text: '',
                value: 'right'
              },
              {
                text: '',
                value: 'bottom'
              },
              {
                text: '',
                value: 'left'
              }
            ]"
          />
        </template>

        <template #Active>
          <b-form-group>
            <div class="flex gap-[10px] items-center">
              <label class="pb-0">Color</label>

              <sid-color
                :sid="`.video-playlist-item.active .--${section.sid}--title`"
                rule-name="color"
              />
            </div>
          </b-form-group>

          <sid-font
            :sid="`.video-playlist-item.active .--${section.sid}--title`"
            rule-name="color"
          />

          <sid-range-slider
            label="Font Size"
            :sid="`.video-playlist-item.active .--${section.sid}--title`"
            default-dimension="px"
            :px-range="[0, 300]"
            rule-name="font-size"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Description Style">
      <rule-tabs :tabs="['Normal', 'Active']">
        <template #Normal>
          <sid-checkbox
            :sid="`.--${section.sid}--description`"
            rule-name="display"
            value="none"
            unchecked-value=""
          >
            Hide
          </sid-checkbox>

          <b-form-group>
            <div class="flex gap-[10px] items-center">
              <label class="pb-0">Color</label>

              <sid-color
                :sid="`.--${section.sid}--description`"
                rule-name="color"
              />
            </div>
          </b-form-group>

          <sid-font
            :sid="`.--${section.sid}--description`"
            rule-name="color"
          />

          <sid-range-slider
            label="Font Size"
            :sid="`.--${section.sid}--description`"
            default-dimension="px"
            :px-range="[0, 300]"
            rule-name="font-size"
          />

          <sid-range-slider
            label="Line-Height"
            :sid="`.--${section.sid}--description`"
            rule-name="line-height"
            :default-dimension="''"
            :min="0"
            :max="5"
            :step=".1"
            var="--line-height"
            :dimensions="[]"
          />

          <sid-linked-group
            label="Margin"
            :sid="`.--${section.sid}--description`"
            rule-name="margin"
            :min="-2000"
            :max="2000"
            :angles="[
              {
                text: '',
                value: 'top'
              },
              {
                text: '',
                value: 'right'
              },
              {
                text: '',
                value: 'bottom'
              },
              {
                text: '',
                value: 'left'
              }
            ]"
          />
        </template>

        <template #Active>
          <b-form-group>
            <div class="flex gap-[10px] items-center">
              <label class="pb-0">Color</label>

              <sid-color
                :sid="`.video-playlist-item.active .--${section.sid}--description`"
                rule-name="color"
              />
            </div>
          </b-form-group>

          <sid-font
            :sid="`.video-playlist-item.active .--${section.sid}--description`"
            rule-name="color"
          />

          <sid-range-slider
            label="Font Size"
            :sid="`.video-playlist-item.active .--${section.sid}--description`"
            default-dimension="px"
            :px-range="[0, 300]"
            rule-name="font-size"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Thumbnail">
      <rule-tabs :tabs="['Normal', 'Active']">
        <template #Normal>
          <sid-checkbox
            :sid="`.--${section.sid}--preview`"
            rule-name="display"
            value="none"
            unchecked-value=""
          >
            Hide
          </sid-checkbox>

          <sid-background
            :sid="`.--${section.sid}--preview .block-bg`"
          />

          <b-form-group>
            <div class="flex gap-[10px] items-center">
              <label class="pb-0">Icon Color</label>

              <sid-color
                :sid="`.--${section.sid}--preview`"
                rule-name="color"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-range-slider
              rule-name="font-size"
              :sid="`.--${section.sid}--preview`"
              default-dimension="px"
              :dimensions="['px']"
              label="Icon Size"
            />
          </b-form-group>

          <b-form-group>
            <sid-range-slider
              rule-name="width"
              :sid="`.--${section.sid}--preview`"
              default-dimension="%"
              :dimensions="['%', 'vw', 'px']"
              label="Width"
              var="--width"
              measurement="width"
            />
          </b-form-group>

          <sid-range-slider
            rule-name="height"
            :sid="`.--${section.sid}--preview`"
            default-dimension="%"
            :dimensions="['%', 'vh', 'px']"
            label="Height"
            var="--height"
            measurement="height"
          />

          <b-form-group label="Background Image Size">
            <sid-select
              priority
              :searchable="false"
              :options="[
                {
                  label: 'Cover',
                  value: 'cover',
                  customProperties: [[`.--${section.sid}--preview`, ['background-size', 'cover']], [`.--${section.sid}--preview`, ['background-attachment', 'unset']]]
                },
                {
                  label: 'Contain',
                  value: 'contain',
                  customProperties: [[`.--${section.sid}--preview`, ['background-size', 'contain']], [`.--${section.sid}--preview`, ['background-attachment', 'unset']]]
                }
              ]"
              :sid="`.--${section.sid}--preview`"
              var="--background-size"
              rule-name="background-size"
            />
          </b-form-group>

          <b-form-group label="Background Image Position">
            <sid-select
              :searchable="false"
              :options="[
                {label: 'Left Top', value: 'left top'},
                {label: 'Left Center', value: 'left center'},
                {label: 'Left Bottom', value: 'left bottom'},
                {label: 'Right Top', value: 'right top'},
                {label: 'Right Center', value: 'right center'},
                {label: 'Right Bottom', value: 'right bottom'},
                {label: 'Center Top', value: 'center top'},
                {label: 'Center Center', value: 'center center'},
                {label: 'Center Bottom', value: 'center bottom'},
              ]"
              :sid="`.--${section.sid}--preview`"
              rule-name="background-position"
            />
          </b-form-group>

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--preview`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--preview`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              css-var="--border-size"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--preview`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--preview`"
            rule-name="border"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />

          <sid-linked-group
            label="Margin"
            :sid="`.--${section.sid}--preview`"
            rule-name="margin"
            :min="-2000"
            :max="2000"
            :angles="[
              {
                text: '',
                value: 'top'
              },
              {
                text: '',
                value: 'right'
              },
              {
                text: '',
                value: 'bottom'
              },
              {
                text: '',
                value: 'left'
              }
            ]"
          />
        </template>

        <template #Active>
          <sid-background
            :sid="`.video-playlist-item.active .--${section.sid}--preview .block-bg`"
          />

          <b-form-group>
            <div class="flex gap-[10px] items-center">
              <label class="pb-0">Icon Color</label>

              <sid-color
                :sid="`.video-playlist-item.active .--${section.sid}--preview`"
                rule-name="color"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-range-slider
              rule-name="font-size"
              :sid="`.video-playlist-item.active .--${section.sid}--preview`"
              default-dimension="px"
              :dimensions="['px']"
              label="Icon Size"
            />
          </b-form-group>

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.video-playlist-item.active .--${section.sid}--preview`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.video-playlist-item.active .--${section.sid}--preview`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              css-var="--border-size"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.video-playlist-item.active .--${section.sid}--preview`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.video-playlist-item.active .--${section.sid}--preview`"
            rule-name="border"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Drop Shadow">
      <rule-tabs>
        <template #Normal>
          <b-form-group
            label="Shadow"
            class="mb-0"
          >
            <sid-box-shadow
              :sid="`.--${section.sid}--playlist`"
              rule-name="box-shadow"
            />
          </b-form-group>
        </template>

        <template #Hover>
          <b-form-group
            label="Shadow"
            class="mb-0"
          >
            <sid-box-shadow
              :sid="`.--${section.sid}--playlist`"
              rule-name="box-shadow"
              presudo=":hover"
            />
          </b-form-group>
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Border & Radius">
      <rule-tabs>
        <template #Normal>
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--playlist`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--playlist`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              css-var="--border-size"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--playlist`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--playlist`"
            rule-name="border"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />
        </template>

        <template #Hover>
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--playlist`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                presudo=":hover"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--playlist`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              css-var="--border-size"
              presudo=":hover"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--playlist`"
                rule-name="border-color"
                presudo=":hover"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--playlist`"
            rule-name="border"
            presudo=":hover"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid}--playlist`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}--playlist`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>

import ModMixin from '@/components/mixins/ModMixin'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup'
import RuleTabs from '@/components/editor/components/RuleTabs'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect'
import SidColor from '@/components/editor/components/sid-controls/SidColor'
import SidBoxShadow from '@/components/editor/components/sid-controls/sid-control-pressets/box-shadow/SidBoxShadow'
import SidFlexDirection from '@/components/editor/components/sid-controls/sid-control-pressets/flex/SidFlexDirection'
import SidButtonsGroup from '@/components/editor/components/sid-controls/SidButtonsGroup'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground'
import SidFont from '@/components/editor/components/sid-controls/sid-control-pressets/font/SidFont'
import SidCheckbox from '@/components/editor/components/sid-controls/SidCheckbox'
import PickElement from '@/components/editor/controls/PickElement'
import ModDragItem from '@/components/editor/components/ModDragItem'
import draggable from 'vuedraggable'
import ImageUpload from '@/components/editor/utils/ImageUpload'
import SidAlign from '@/components/editor/components/sid-controls/SidAlign'
import { timeMask } from '@/utils/masks'
import {VueMaskDirective} from 'v-mask'
import SidUploadVideo from '@/components/editor/components/sid-controls/sid-control-pressets/SidUploadVideo.vue'

export default {
  name: 'VideoPlaylistMod',
  components: {
    SidUploadVideo,
    SidAlign,
    ImageUpload,
    ModDragItem,
    PickElement,
    SidCheckbox,
    SidFont,
    SidBackground,
    SidButtonsGroup,
    SidFlexDirection,
    SidBoxShadow, SidColor, SidSelect, RuleTabs, SidLinkedGroup, SidbearSettingsGroup, SidRangeSlider, draggable
  },
  directives: {
    mask: VueMaskDirective
  },
  mixins: [ModMixin],
  data () {
    return {
      timeMask,
      active: null,
      tabs: {}
    }
  },
  created () {
    this.section.options.content.playlist.items.forEach(item => {
      if (item.url) !item.url.includes('player.estage.com') ? item.videoType = 'External URL' : item.videoType = 'ESTAGE'
    })
  },
  methods: {
    deleteItem (index) {
      this.active = null
      this.section.options.content.playlist.items.splice(index, 1)
    },
    addSlide () {
      this.section.options.content.playlist.items.push({
        name: `Video #${this.section.options.content.playlist.items.length + 1}`,
        title: 'Video Title',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Id, ratione.',
        backgroundImage: '',
        videoType: 'ESTAGE'
      })
    }
  }
}
</script>
