<template>
  <div
    v-if="render"
    :class="`--${section.sid}--wrapper`"
    class="overflow-hidden"
  >
    <div>
      <div
        v-if="!isShowVideo && videoOptions.videoPreview"
        class="embed-responsive overflow-visible embed-responsive-16by9"
      >
        <iframe
          :src="getVideoEmbedURL('?autoplay=1&mute=1&muted=1&controls=0')"
          frameborder="0"
          loading="lazy"
          class="embed-responsive-item"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; screen-wake-lock"
          allowfullscreen
        />

        <div class="el-overlay video-placeholder-overlay" />

        <div
          :class="`--${section.sid}--play-btn`"
          class="play-btn"
          @click="() => isShowVideo = true"
        >
          <i
            :class="[`--${section.sid}--play-icon`, section.options.content.video.playIcon.icon]"
          />
        </div>
      </div>

      <div
        v-else-if="!isShowVideo"
        class="embed-responsive overflow-visible embed-responsive-16by9"
      >
        <div
          class="embed-responsive-item"
        >
          <img
            class="video-image-preview position-absolute top-0 left-0 w-100 h-100"
            style="object-position: center center;"
            :src="section.options.content.video.placeholder"
            alt="Video preveiw"
          >

          <div
            :class="`--${section.sid}--play-btn`"
            class="play-btn"
            @click="() => isShowVideo = true"
          >
            <i
              :class="[`--${section.sid}--play-icon`, section.options.content.video.playIcon.icon]"
            />
          </div>
        </div>
      </div>

      <div
        v-else
        class="embed-responsive embed-responsive-16by9"
        :class="`--${section.sid}--video`"
      >
        <iframe
          ref="iframe"
          :src="getVideoEmbedURL()"
          frameborder="0"
          loading="lazy"
          class="embed-responsive-item"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; screen-wake-lock"
          allowfullscreen
        />
        <div class="ambient ambient-t" />
        <div class="ambient ambient-l" />
        <div class="ambient ambient-r" />
        <div class="ambient ambient-b" />
      </div>

      <div v-show="false">
        <div class="el-overlay video-placeholder-overlay" />
      </div>
    </div>
  </div>
</template>

<script>
import $getEmbedUrl from 'better-video-embeds'

export default {
  name: 'VideoPlay',

  props: {
    autoplay: {
      type: Boolean,
      default: false
    },
    section: {
      type: Object,
      required: true
    },
    customs: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      render: true,
      isShowVideo: false
    }
  },

  computed: {
    videoSource () {
      return this.customs.src
    },
    videoOptions () {
      return {
        src: this.customs.src,
        previewType: this.customs.previewType,
        autoplay: this.customs.autoplay,
        disablePreview: this.customs.disablePreview,
        videoPreview: this.customs.videoPreview,
        mute: this.customs.mute,
        loop: this.customs.loop,
        controls: this.customs.controls,
        start: this.customs.start
      }
    }
  },

  watch: {
    videoOptions: {
      deep: true,
      handler: function () {
        this.isShowVideo = false
        this.render = false

        if (this.videoOptions.disablePreview) {
          this.isShowVideo = true
        }

        setTimeout(() => this.render = true, 100)
      }
    }
  },

  created () {
    if (!this.customs.playIcon) {
      this.$set(this.customs, 'playIcon', {
        icon: 'fa fa-play',
        color: '#000',
        backgroundColor: '#fff'
      })
    }
  },

  mounted () {
    if (this.videoOptions.disablePreview) {
      this.isShowVideo = true
    }

    this.videoHeight = this.$el.firstElementChild.clientHeight
  },

  methods: {
    getVideoEmbedURL (options) {
      if (!this.videoSource) return null

      if (this.videoSource.includes('estage')) {
        const videoID = $getEmbedUrl(this.videoSource).split('/').slice(-1)[0]
        return `https://player.estage.com/${videoID}${options || this.parsedVideoEmbedOptions()}`
      }

      if (this.videoSource.includes('youtu.be') || this.videoSource.includes('youtube')) {
        const videoID = $getEmbedUrl(this.videoSource).split('/').slice(-1)[0].slice(0, 11)
        return `https://www.youtube.com/embed/${videoID}${options || this.parsedVideoEmbedOptions(videoID)}`
      }

      if (this.videoSource.includes('wistia')) {
        const videoID = $getEmbedUrl(this.videoSource).split('/').slice(-1)[0].slice(0, 11)
        return `https://fast.wistia.net/embed/iframe/${videoID}${options || this.parsedVideoEmbedOptions()}`
      }

      return `${$getEmbedUrl(this.videoSource)}${options || this.parsedVideoEmbedOptions()}`
    },
    parsedVideoEmbedOptions (videoID) {
      if (this.videoSource.includes('estage')) {
        return `?autoplay=${this.videoOptions.autoplay || !this.section.options.content.video.disablePreview || this.section.options.content.video.videoPreview}`
      }
      if (this.videoSource.includes('youtube') || this.videoSource.includes('youtu.be')) {
        return `?autoplay=${Number(this.videoOptions.autoplay || !this.videoOptions.disablePreview || this.videoOptions.videoPreview)}&mute=${Number(this.videoOptions.mute)}&loop=${Number(this.videoOptions.loop)}&controls=${Number(!this.videoOptions.controls)}&start=${this.getTimeCode(this.videoOptions.start)}&enablejsapi=1&playlist=${videoID}`
      }
      if (this.videoSource.includes('vimeo')) {
        return `?autoplay=${Number(this.videoOptions.autoplay || !this.videoOptions.disablePreview || this.videoOptions.videoPreview)}&muted=${Number(this.videoOptions.mute || 0)}&loop=${Number(this.videoOptions.loop)}&controls=${Number(!this.videoOptions.controls)}&start=${this.getTimeCode(this.videoOptions.start)}`
      }
      if (this.videoSource.includes('wistia')) {
        return `?autoplay=${this.videoOptions.autoplay || !this.section.options.content.video.disablePreview || this.section.options.content.video.videoPreview}&muted=${this.videoOptions.mute}&endVideoBehavior=${this.videoOptions.loop === true ? 'loop' : ''}${this.videoOptions.controls === true ? '&playButton=false&smallPlayButton=false&playbar=false&volumeControl=false&settingsControl=false&fullscreenButton=false' : ''}&time=${this.getTimeCode(this.videoOptions.start)}`
      }
    },
    getTimeCode (timeString) {
      if (!timeString) return
      const timePices = timeString.split(':')
      return parseInt(timePices[0] * 3600) + parseInt(timePices[1] * 60) + parseInt(timePices[2])
    }
  }
}
</script>

<style lang="scss">
.play-btn {
  --height: 80px;
  --width: 80px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: var(--width);
  height: var(--height);
  transition: .2s;

  &:hover {
    cursor: pointer;
    width: calc(var(--width) + 10px);
    height: calc(var(--height) + 10px);
  }
}

@keyframes sticky-fade-in-up {
  0% {
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.stuck {
  position: fixed;
  transform: translateY(100%);
  animation: sticky-fade-in-up .25s ease forwards;
  z-index: 100000;

  .fa-close {
    position: absolute;
    z-index: 1000000;
    top: 5px;
    right: 5px;
    font-size: 18px;
    text-shadow: 0 0 3px #2d2d2d;
    color: #fff;

    &:hover {
      cursor: pointer;
    }
  }
}

.video-image-preview {
  object-fit: cover;
}

.video-sticky-position- {
  &0 {
    top: 20px;
    left: 20px;
  }

  &1 {
    top: 20px;
    right: 20px;
  }

  &2 {
    bottom: 20px;
    left: 20px;
  }

  &3 {
    bottom: 20px;
    right: 20px;
  }
}

.video-sticky-size- {
  &0 {
    width: 260px;
    height: 145px;
  }

  &1 {
    width: 402px;
    height: 218px;
  }

  &2 {
    width: 496px;
    height: 274px;
  }
}

.video-sticky-style- {
  &0 {
    box-shadow: 0 0 12px 4px #727272;
  }

  &1 {
    box-shadow: 0 0 12px 4px #e3e3e3;
  }
}

.video-placeholder-overlay {
  display: block;
  background: rgba(0, 0, 0, .4);
  opacity: 1;
}

.ambient {
  --size: 30px;
  --color: #fff;
  display: none;
  position: absolute;

  &.ambient-t {
    top: 0;
    left: 0;
    width: 100%;
    height: var(--size);
    background-image: linear-gradient(to bottom, var(--color), rgba(225,225,225,0));
  }

  &.ambient-l {
    top: 0;
    left: 0;
    width: var(--size);
    height: 100%;
    background-image: linear-gradient(to right, var(--color), rgba(225,225,225,0));
  }

  &.ambient-r {
    top: 0;
    right: 0;
    width: var(--size);
    height: 100%;
    background-image: linear-gradient(to left, var(--color), rgba(225,225,225,0));
  }

  &.ambient-b {
    bottom: 0;
    left: 0;
    width: 100%;
    height: var(--size);
    background-image: linear-gradient(to top, var(--color), rgba(225,225,225,0));
  }
}
</style>
