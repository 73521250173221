<template>
  <div>
    <sidbear-settings-group
      title="Categories"
      active
    >
      <b-form-group label="Main Button Text">
        <b-input v-model="mainButtonText" />
      </b-form-group>

      <b-form-group label="Categories">
        <tags-select
          v-model="categories"
          class="w-full"
          multiple
          label="name"
          :url="`https://blog-api.estage.com/api/projects/${project.id}/blog/manage/categories`"
        />
      </b-form-group>

      <sid-range-slider
        rule-name="gap"
        label="Gap"
        :sid="className()"
        :dimensions="[]"
        default-dimension="px"
      />

      <b-form-group>
        <sid-flex-direction
          :sid="className()"
          rule-name="flex-direction"
        >
          <template #column>
            <sid-buttons-group
              label="Horizontal Alignment"
              :options="[
                {
                  svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                  value: 'start',
                  tooltip: 'Start'
                },
                {
                  svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                  value: 'center',
                  tooltip: 'Center'
                },
                {
                  svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                  value: 'end',
                  tooltip: 'End'
                }
              ]"
              :sid="className()"
              rule-name="align-items"
            />

            <sid-buttons-group
              label="Vertical Alignment"
              class="mb-0"
              :options="[
                {
                  svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.54688\' y=\'1.59863\' width=\'4.1387\' height=\'7.67361\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.32251\' y=\'1.59863\' width=\'4.1387\' height=\'5.43547\' fill=\'%237E7E7E\'/%3E%3Crect width=\'15.2814\' height=\'0.959201\' transform=\'matrix(1 0 0 -1 0 0.958984)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                  value: 'start',
                  tooltip: 'Start'
                },
                {
                  svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'9\' viewBox=\'0 0 16 9\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.87305\' y=\'0.349243\' width=\'4.05866\' height=\'8.46585\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.55664\' y=\'0.976318\' width=\'4.05866\' height=\'6.8981\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9858\' height=\'0.94065\' transform=\'matrix(1 0 0 -1 0.0634766 5.05249)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                  value: 'center',
                  tooltip: 'Center'
                },
                {
                  svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.0532\' height=\'7.51508\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 3.43945 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.0532\' height=\'5.32318\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 8.11523 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9657\' height=\'0.939385\' transform=\'matrix(1 0 0 -1 0.632812 9.43018)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                  value: 'end',
                  tooltip: 'End'
                },
                {
                  svg: 'data:image/svg+xml,%3Csvg width=\'14\' height=\'13\' viewBox=\'0 0 14 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.02612\' height=\'8.41616\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 4.4834 2.28711)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.02612\' height=\'8.41616\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 11.7031 2.28711)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.1734\' height=\'0.701346\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.701172 12.0225)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.1734\' height=\'0.701346\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 13.1602 12.0225)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                  value: 'space-between',
                  tooltip: 'Between'
                },
                {
                  svg: 'data:image/svg+xml,%3Csvg width=\'13\' height=\'14\' viewBox=\'0 0 13 14\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.26467\' height=\'9.07958\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 5.48535 2.51978)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.26467\' height=\'9.07958\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 10.0303 2.51978)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'12.0541\' height=\'0.756631\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.756836 13.0225)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'12.0541\' height=\'0.756631\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 12.25 13.0225)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                  value: 'space-around',
                  tooltip: 'Around'
                }
              ]"
              :sid="className()"
              rule-name="justify-content"
            />
          </template>

          <template #row>
            <sid-buttons-group
              label="Vertical Alignment"
              :options="[
                {
                  svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.54688\' y=\'1.59863\' width=\'4.1387\' height=\'7.67361\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.32251\' y=\'1.59863\' width=\'4.1387\' height=\'5.43547\' fill=\'%237E7E7E\'/%3E%3Crect width=\'15.2814\' height=\'0.959201\' transform=\'matrix(1 0 0 -1 0 0.958984)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                  value: 'start',
                  tooltip: 'Start'
                },
                {
                  svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'9\' viewBox=\'0 0 16 9\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.87305\' y=\'0.349243\' width=\'4.05866\' height=\'8.46585\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.55664\' y=\'0.976318\' width=\'4.05866\' height=\'6.8981\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9858\' height=\'0.94065\' transform=\'matrix(1 0 0 -1 0.0634766 5.05249)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                  value: 'center',
                  tooltip: 'Center'
                },
                {
                  svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.0532\' height=\'7.51508\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 3.43945 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.0532\' height=\'5.32318\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 8.11523 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9657\' height=\'0.939385\' transform=\'matrix(1 0 0 -1 0.632812 9.43018)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                  value: 'end',
                  tooltip: 'End'
                },
                {
                  svg: 'data:image/svg+xml,%3Csvg width=\'13\' height=\'12\' viewBox=\'0 0 13 12\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.11228\' height=\'8.65576\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 3.26855 9.85815)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.11228\' height=\'8.65576\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 6.86035 9.85815)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.4915\' height=\'0.721313\' transform=\'matrix(1 0 0 -1 0.875 0.721313)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.4915\' height=\'0.721313\' transform=\'matrix(1 0 0 -1 0.875 11.0599)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E',
                  value: 'stretch',
                  tooltip: 'Stretch'
                }
              ]"
              :sid="className()"
              rule-name="align-items"
            />

            <sid-buttons-group
              label="Horizontal Alignment"
              class="mb-0"
              :options="[
                {
                  svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                  value: 'start',
                  tooltip: 'Start'
                },
                {
                  svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                  value: 'center',
                  tooltip: 'Center'
                },
                {
                  svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                  value: 'end',
                  tooltip: 'End'
                },
                {
                  svg: 'data:image/svg+xml,%3Csvg width=\'14\' height=\'13\' viewBox=\'0 0 14 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.02612\' height=\'8.41616\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 4.4834 2.28711)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.02612\' height=\'8.41616\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 11.7031 2.28711)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.1734\' height=\'0.701346\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.701172 12.0225)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.1734\' height=\'0.701346\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 13.1602 12.0225)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                  value: 'space-between',
                  tooltip: 'Between'
                },
                {
                  svg: 'data:image/svg+xml,%3Csvg width=\'13\' height=\'14\' viewBox=\'0 0 13 14\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.26467\' height=\'9.07958\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 5.48535 2.51978)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.26467\' height=\'9.07958\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 10.0303 2.51978)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'12.0541\' height=\'0.756631\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.756836 13.0225)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'12.0541\' height=\'0.756631\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 12.25 13.0225)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                  value: 'space-around',
                  tooltip: 'Around'
                }
              ]"
              :sid="className()"
              rule-name="justify-content"
            />
          </template>
        </sid-flex-direction>
      </b-form-group>
      
      <sid-checkbox
        value="wrap"
        rule-name="flex-wrap"
        :sid="className()"
      >
        Wrap Buttons
      </sid-checkbox>
    </sidbear-settings-group>

    <sidbear-settings-group title="Button Style">
      <rule-tabs :tabs="['Normal', 'Active', 'Hover']">
        <template #Normal>
          <sid-font
            :sid="className(' a')"
          />

          <sid-range-slider
            :sid="className(' a')"
            label="Font Size"
            rule-name="font-size"
          />

          <sid-align
            :sid="className(' a')"
            rule-name="justify-content"
            label="Text Alignment"
          />

          <b-form-group>
            <div class="flex align-items-center gap-[14px]">
              <label class="pb-0">Text Color</label>
              <sid-color
                rule-name="color"
                :sid="className(' a')"
              />
            </div>
          </b-form-group>

          <sid-background :sid="className(' a')" />

          <sid-range-slider
            label="Width"
            :sid="className(' a')"
            rule-name="width"
            var="--width"
            default-dimension="%"
            :dimensions="['px', '%']"
            measurement="width"
          />

          <sid-range-slider
            label="Height"
            :sid="className(' a')"
            rule-name="height"
            var="--height"
            default-dimension="px"
            :dimensions="['px', '%']"
          />

          <b-form-group>
            <sid-checkbox
              rule-name="--text-wrap"
              :sid="`.--${section.sid}--1 .main-text`"
              :value="''"
              unchecked-value="nowrap"
            >
              Text Wrap
            </sid-checkbox>
          </b-form-group>

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="className(' a')"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="className(' a')"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="className(' a')"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="className(' a')"
            rule-name="border"
            :angles="[
              {
                text: '',
                value: 'top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius'
              }
            ]"
          />

          <sid-linked-group
            label="Padding"
            :sid="className(' a')"
            rule-name="padding"
            :min="0"
            :angles="[
              {
                text: '',
                value: 'top'
              },
              {
                text: '',
                value: 'right'
              },
              {
                text: '',
                value: 'bottom'
              },
              {
                text: '',
                value: 'left'
              }
            ]"
          />

          <sid-linked-group
            label="Margin"
            :sid="className(' a')"
            rule-name="margin"
            :min="-2000"
            :max="2000"
            :angles="[
              {
                text: '',
                value: 'top'
              },
              {
                text: '',
                value: 'right'
              },
              {
                text: '',
                value: 'bottom'
              },
              {
                text: '',
                value: 'left'
              }
            ]"
          />
        </template>

        <template #Active>
          <sid-font
            :sid="className(' a.active')"
          />

          <sid-range-slider
            :sid="className(' a.active')"
            label="Font Size"
            rule-name="font-size"
          />


          <b-form-group>
            <div class="flex align-items-center gap-[14px]">
              <label class="pb-0">Text Color</label>
              <sid-color
                rule-name="color"
                :sid="className(' a.active')"
              />
            </div>
          </b-form-group>

          <sid-background
            :sid="className(' a.active')"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="className(' a.active')"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="className(' a.active')"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="className(' a.active')"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="className(' a.active')"
            rule-name="border"
            :angles="[
              {
                text: '',
                value: 'top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius'
              }
            ]"
          />
        </template>

        <template #Hover>
          <sid-font
            :sid="className()"
            presudo=" a:hover"
          />

          <sid-range-slider
            :sid="className()"
            presudo=" a:hover"
            label="Font Size"
            rule-name="font-size"
          />

          <b-form-group>
            <div class="flex align-items-center gap-[14px]">
              <label class="pb-0">Text Color</label>
              <sid-color
                rule-name="color"
                presudo=" a:hover"
                :sid="className()"
              />
            </div>
          </b-form-group>

          <sid-background
            :sid="className()"
            presudo=" a:hover"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="className()"
                presudo=" a:hover"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="className()"
              presudo=" a:hover"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="className()"
                presudo=" a:hover"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="className(' a:hover')"
            rule-name="border"
            :angles="[
              {
                text: '',
                value: 'top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius'
              }
            ]"
          />
        </template>
      </rule-tabs>
    </sidbear-settings-group>

    <sidbear-settings-group title="Padding & Margins">
      <sid-linked-group
        label="Padding"
        :sid="className()"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="className()"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        class="mb-0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </sidbear-settings-group>
  </div>
</template>

<script>
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider.vue'
import ModMixin from '@/components/mixins/ModMixin'
import SidButtonsGroup from '@/components/editor/components/sid-controls/SidButtonsGroup.vue'
import SidAlign from '@/components/editor/components/sid-controls/SidAlign.vue'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground.vue'
import SidCheckbox from '@/components/editor/components/sid-controls/SidCheckbox.vue'
import RuleTabs from '@/components/editor/components/RuleTabs.vue'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup.vue'
import SidColor from '@/components/editor/components/sid-controls/SidColor.vue'
import SidFlexDirection
  from '@/components/editor/components/sid-controls/sid-control-pressets/flex/SidFlexDirection.vue'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect.vue'
import TagsSelect from '@/components/editor/components/TagsSelect.vue'
import SidFont from '@/components/editor/components/sid-controls/sid-control-pressets/font/SidFont.vue'

export default {
  name: 'BlogCategoriesMod',
  components: {
    SidFont,
    TagsSelect,
    SidSelect,
    SidFlexDirection,
    SidColor,
    SidLinkedGroup,
    RuleTabs,
    SidCheckbox,
    SidBackground,
    SidAlign,
    SidButtonsGroup, SidRangeSlider, SidbearSettingsGroup
  },
  mixins: [ModMixin],
  computed: {
    mainButtonText: {
      get () {
        return this.section.options.content.data.mainBtnText || 'All Categories'
      },
      set (val) {
        this.$set(this.section.options.content.data, 'mainBtnText', val)
      }
    },
    categories: {
      get () {
        return this.section.options.content.data.categories || []
      },
      set (val) {
        this.$set(this.section.options.content.data, 'categories', val)
      }
    }
  },
  methods: {
    className (name = '') {
      return `.--blog-categories-${this.section.sid}${name}`
    }
  }
}
</script>