<template>
  <es-dropdown
    :disabled="disabled"
    :placement="dropdownPlacement"
    :dropdown-item="dropdownItem"
  >
    <template v-slot:activator>
      <div>
        <div
          class="transitioner el-menu-link-background absolute w-full h-full top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
        />
        <div
          ref="link"
          class="cursor-pinter el-menu-link transition"
          :class="[`--${sid}--link`, dropdownItem ? `--${sid}--dropdown-link` : '', isActive ? `--${sid}--link-active` : '', linkClass]"
          @click.stop="onClick"
        >
          <div
            class="d-flex align-items-center px-2"
          >
            <i
              v-if="link.icon && !link.iconAppend"
              :class="link.icon"
              class="mr-2"
            />

            <span
              v-text="link.text"
            />

            <i
              v-if="link.icon && link.iconAppend"
              :class="link.icon"
              class="ml-2"
            />

            <i
              v-if="link.children && link.children.length"
              class="fa fa-angle-down mx-2"
            />
          </div>
        </div>
      </div>
    </template>

    <div
      class="d-flex flex-column bg-[#fff]"
      :class="`--${sid}--dropdown-menu`"
    >
      <menu-link-v4
        v-for="(link, index) in link.children"
        :key="index"
        :link="link"
        :menu="menu"
        :sid="sid"
        :dropdown-item="true"
      />
    </div>
  </es-dropdown>
</template>

<script>
import _ from 'lodash'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'
import {mapState} from 'vuex'
import EsDropdown from '@/components/utils/estage-dropdown/EsDropdown'
import BorderMixin from '@/components/mixins/BorderMixin'

export default {
  name: 'MenuLinkV4',
  components: {EsDropdown},
  mixins: [ResolutionMixin, BorderMixin],
  props: {
    sid: {
      type: String,
      required: true
    },
    linkClass: {
      type: String,
      default: ''
    },
    dropdownItem: {
      type: Boolean,
      default: false
    },
    link: {
      type: Object,
      required: true
    },
    menu: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isOpenMegaMenu: false
    }
  },
  computed: {
    ...mapState({
      globalStyles: state => state.editor.globalStyles,
      page: state => state.pages.page
    }),
    disabled () {
      return !this.link.children || !this.link.children.length
    },
    dropdownPlacement () {
      return _.get(this.menu, ['dropdownPlacement', this.resolutioner], _.get(this.menu, ['dropdownPlacement', 'lg'], 'bottom-start'))
    },
    isActive () {
      return _.get(this.link, ['href', 'slug'], false) === this.page.slug
    },
    megaMenu () {
      const megaMenus = this.menu.megaMenus
      if (!this.link.megaMenu || !megaMenus) return null

      return megaMenus.find(menu => menu.id === this.link.megaMenu) || null
    }
  },
  methods: {
    onClick (e) {
      e.preventDefault()
      const pageID = _.get(this.link, 'href.id', null)
      const pageSlug = _.get(this.link, 'href.slug', null)


      if (pageID === 'es-mega-menu' && e.target === this.$refs.link) {
        return this.isOpenMegaMenu = !this.isOpenMegaMenu
      }

      if (pageID === 'blog') {
        return this.$router.push({ name: 'settings.blog', query: {
          page: ''
        } })
      }

      if (pageSlug) {
        VEvent.fire('loader', true)
        return this.$store.dispatch('projects/save').then(() => {
          setTimeout(() => {
            this.$store.dispatch('pages/loadPage', pageSlug).finally(() => {
              this.$store.commit('editor/SET_PAGE', 'sections')
              VEvent.fire('loader', false)
            })
          }, 100)
        })
      }
    }
  }
}
</script>

<style lang="scss">
.el-menu-link {
  position: relative;
  line-height: normal !important;
}
</style>