<template>
  <div>
    <SidbearSettingsGroup
      title="Layout"
      active
    >
      <b-form-group v-if="linked">
        <button
          class="flex p-0 text-[11px] hover:bg-gray-100 items-center text-dark border rounded-[4px] w-[114px] h-[30px] justify-content-center gap-[5px] cursor-pointer font-[500] hover:text-[#3D82EA] text-[#7E7E7E]"
          @click="() => unlinkSid(getSubject)"
        >
          Unlink Styles
          <i class="fa fa-link text-primary" />
        </button>
      </b-form-group>

      <rule-tabs>
        <template #Normal>
          <b-form-group>
            <sid-range-slider
              rule-name="width"
              :sid="`.--${getSubject.sid}--flex-col-el`"
              default-dimension="%"
              :dimensions="['%', 'vw', 'px']"
              label="Width"
              var="--width"
              measurement="width"
            />
          </b-form-group>

          <sid-range-slider
            rule-name="height"
            :sid="`.--${getSubject.sid}--flex-col-el`"
            :clean-sid="getSubject.sid"
            default-dimension="%"
            :dimensions="['%', 'vh', 'px']"
            bad-padding-class="--flex-col-padding"
            bad-content-class="--col-content"
            label="Height"
            :ignore-zero="true"
            var="--height"
            measurement="height"
          />

          <sid-width-and-height
            v-if="false"
            :sid="`.--${getSubject.sid}--flex-col-el`"
            default-value="100%"
          />

          <b-form-group>
            <label class="mb-0">
              Overflow
              <i
                v-b-tooltip.topright.hover
                title="Relevant for fixed height only."
                class="fa fa-question-circle cursor-pointer hover:text-blue-500 ml-1"
              />
            </label>
            <sid-select
              :sid="`.--${getSubject.sid}--flex-col`"
              rule-name="overflow-y"
              :searchable="false"
              class="w-[118px]"
              :options="[
                {
                  label: 'Visible',
                  value: 'visible',
                  customProperties: [[`.--${getSubject.sid}--flex-col`, ['overflow-y', 'visible']], [`.--${getSubject.sid}--flex-col`, ['flex-wrap', '']]]
                },
                {
                  label: 'Hidden',
                  value: 'hidden',
                  customProperties: [[`.--${getSubject.sid}--flex-col`, ['overflow-y', 'hidden']], [`.--${getSubject.sid}--flex-col`, ['flex-wrap', 'nowrap']]]
                },
                {
                  label: 'Scroll',
                  value: 'scroll',
                  customProperties: [[`.--${getSubject.sid}--flex-col`, ['overflow-y', 'scroll']], [`.--${getSubject.sid}--flex-col`, ['flex-wrap', 'nowrap']]]
                },
                {
                  label: 'Auto',
                  value: 'auto',
                  customProperties: [[`.--${getSubject.sid}--flex-col`, ['overflow-y', 'auto']], [`.--${getSubject.sid}--flex-col`, ['flex-wrap', 'nowrap']]]
                }
              ]"
            />
          </b-form-group>

          <sid-flex-direction
            var="--display"
            :sid="`.--${getSubject.sid}--flex-col > .col-content`"
            disable-reverse="true"
            rule-name="flex-direction"
          >
            <template #column>
              <sid-buttons-group
                label="Horizontal Alignment"
                :options="[
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    customProperties: [[`.--${getSubject.sid}--flex-col > .col-content`, ['text-align', 'left']], [`.--${getSubject.sid}--flex-col > .col-content`, ['align-items', 'start']]],
                    value: 'start',
                    tooltip: 'Start'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    customProperties: [[`.--${getSubject.sid}--flex-col > .col-content`, ['text-align', 'center']], [`.--${getSubject.sid}--flex-col > .col-content`, ['align-items', 'center']]],
                    value: 'center',
                    tooltip: 'Center'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    customProperties: [[`.--${getSubject.sid}--flex-col > .col-content`, ['text-align', 'end']], [`.--${getSubject.sid}--flex-col > .col-content`, ['align-items', 'end']]],
                    value: 'end',
                    tooltip: 'End'
                  }
                ]"
                :sid="`.--${getSubject.sid}--flex-col > .col-content`"
                rule-name="align-items"
              />
              
              <sid-buttons-group 
                label="Vertical Alignment"
                :options="[
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.54688\' y=\'1.59863\' width=\'4.1387\' height=\'7.67361\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.32251\' y=\'1.59863\' width=\'4.1387\' height=\'5.43547\' fill=\'%237E7E7E\'/%3E%3Crect width=\'15.2814\' height=\'0.959201\' transform=\'matrix(1 0 0 -1 0 0.958984)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'start',
                    tooltip: 'Start'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'9\' viewBox=\'0 0 16 9\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.87305\' y=\'0.349243\' width=\'4.05866\' height=\'8.46585\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.55664\' y=\'0.976318\' width=\'4.05866\' height=\'6.8981\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9858\' height=\'0.94065\' transform=\'matrix(1 0 0 -1 0.0634766 5.05249)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'center',
                    tooltip: 'Center'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.0532\' height=\'7.51508\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 3.43945 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.0532\' height=\'5.32318\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 8.11523 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9657\' height=\'0.939385\' transform=\'matrix(1 0 0 -1 0.632812 9.43018)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'end',
                    tooltip: 'End'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'13\' height=\'12\' viewBox=\'0 0 13 12\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.11228\' height=\'8.65576\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 3.26855 9.85815)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.11228\' height=\'8.65576\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 6.86035 9.85815)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.4915\' height=\'0.721313\' transform=\'matrix(1 0 0 -1 0.875 0.721313)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.4915\' height=\'0.721313\' transform=\'matrix(1 0 0 -1 0.875 11.0599)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E',
                    value: 'stretch',
                    tooltip: 'Stretch'
                  }
                ]" 
                :sid="`.--${getSubject.sid}--flex-col`"
                rule-name="justify-content"
              />
            </template>

            <template #row>
              <sid-buttons-group
                label="Vertical Alignment"
                :options="[
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.54688\' y=\'1.59863\' width=\'4.1387\' height=\'7.67361\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.32251\' y=\'1.59863\' width=\'4.1387\' height=\'5.43547\' fill=\'%237E7E7E\'/%3E%3Crect width=\'15.2814\' height=\'0.959201\' transform=\'matrix(1 0 0 -1 0 0.958984)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    customProperties: [[`.--${getSubject.sid}--flex-col > .col-content`, ['text-align', 'start']], [`.--${getSubject.sid}--flex-col > .col-content`, ['align-items', 'start']]],
                    value: 'start',
                    tooltip: 'Start'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'9\' viewBox=\'0 0 16 9\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.87305\' y=\'0.349243\' width=\'4.05866\' height=\'8.46585\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.55664\' y=\'0.976318\' width=\'4.05866\' height=\'6.8981\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9858\' height=\'0.94065\' transform=\'matrix(1 0 0 -1 0.0634766 5.05249)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    customProperties: [[`.--${getSubject.sid}--flex-col > .col-content`, ['text-align', 'center']], [`.--${getSubject.sid}--flex-col > .col-content`, ['align-items', 'center']]],
                    value: 'center',
                    tooltip: 'Center'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.0532\' height=\'7.51508\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 3.43945 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.0532\' height=\'5.32318\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 8.11523 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9657\' height=\'0.939385\' transform=\'matrix(1 0 0 -1 0.632812 9.43018)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    customProperties: [[`.--${getSubject.sid}--flex-col > .col-content`, ['text-align', 'end']], [`.--${getSubject.sid}--flex-col > .col-content`, ['align-items', 'end']]],
                    value: 'end',
                    tooltip: 'End'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'13\' height=\'12\' viewBox=\'0 0 13 12\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.11228\' height=\'8.65576\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 3.26855 9.85815)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.11228\' height=\'8.65576\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 6.86035 9.85815)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.4915\' height=\'0.721313\' transform=\'matrix(1 0 0 -1 0.875 0.721313)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.4915\' height=\'0.721313\' transform=\'matrix(1 0 0 -1 0.875 11.0599)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E',
                    customProperties: [[`.--${getSubject.sid}--flex-col > .col-content`, ['text-align', 'justify']], [`.--${getSubject.sid}--flex-col > .col-content`, ['align-items', 'stretch']]],
                    value: 'stretch',
                    tooltip: 'Stretch'
                  }
                ]"
                :sid="`.--${getSubject.sid}--flex-col > .col-content`"
                rule-name="align-items"
              />
              
              <sid-buttons-group
                label="Horizontal Alignment"
                :options="[
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'start',
                    tooltip: 'Start'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'center',
                    tooltip: 'Center'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'end',
                    tooltip: 'End'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'14\' height=\'13\' viewBox=\'0 0 14 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.02612\' height=\'8.41616\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 4.4834 2.28711)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.02612\' height=\'8.41616\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 11.7031 2.28711)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.1734\' height=\'0.701346\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.701172 12.0225)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.1734\' height=\'0.701346\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 13.1602 12.0225)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'space-between',
                    tooltip: 'Between'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'13\' height=\'14\' viewBox=\'0 0 13 14\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.26467\' height=\'9.07958\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 5.48535 2.51978)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.26467\' height=\'9.07958\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 10.0303 2.51978)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'12.0541\' height=\'0.756631\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.756836 13.0225)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'12.0541\' height=\'0.756631\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 12.25 13.0225)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'space-around',
                    tooltip: 'Around'
                  },
                ]"
                :sid="`.--${getSubject.sid}--flex-col > .col-content`"
                rule-name="justify-content"
              />
            </template>
          </sid-flex-direction>

          <sid-background
            class="mb-0"
            :sid="`.--${getSubject.sid}--flex-col-el`"
          />

          <sid-range-slider
            :sid="`.--${getSubject.sid}--flex-col-el`"
            label="Background Blur"
            rule-name="--backdrop-filter-blur"
            var="--backdrop-filter-blur"
            default-dimension="px"
            :dimensions="['px']"
            :px-range="[0, 10]"
            class="mb-4"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Z-Index</label>

              <sid-input
                :sid="`.--${getSubject.sid}--flex-col-el`"
                rule-name="z-index"
                :default-dimension="''"
                class="!w-[59px]"
              />
            </div>
          </b-form-group>

          <sid-checkbox
            value="auto"
            rule-name="overflow-y"
            :sid="`.--${getSubject.sid}--flex-col-el`"
          >
            Scrollable
          </sid-checkbox>

          <b-form-group>
            <b-checkbox v-model="sticky">
              <div class="transform translate-y-[-2px]">
                Sticky on Scroll
              </div>
            </b-checkbox>
          </b-form-group>

          <b-form-group v-if="sticky">
            <div class="flex items-center gap-[14px]">
              <label class="pb-0">Sticky Offset</label>
              <linked-control-item
                v-model="getSubject.stickyOffsetTop"
                :min="-400"
                :max="400"
                :step="1"
                class="!w-[80px]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-checkbox
              value="100%"
              default-value=""
              rule-name="--col-content-height"
              :sid="`.--${getSubject.sid}--flex-col-el`"
            >
              Fit Content
            </sid-checkbox>
          </b-form-group>
        </template>
        <template #Hover>
          <sid-background
            class="mb-0"
            :sid="`.--${getSubject.sid}--flex-col-el`"
            presudo=":hover"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Background Image"
    >
      <rule-tabs :tabs="['Static', 'Animation']">
        <template #Static>
          <sid-upload-image
            :sid="`.--${getSubject.sid}--bg`"
            rule-name="background-image"
          />

          <b-form-group label="Background Image Size">
            <sid-select
              priority
              :searchable="false"
              :options="[
                {
                  label: 'Normal',
                  value: '',
                  customProperties: [[`.--${getSubject.sid}--bg`, ['background-attachment', 'unset']]]
                },
                {
                  label: 'Full Center (Parallax)',
                  value: 'fixed',
                  customProperties: [[`.--${getSubject.sid}--bg`, ['background-size', 'cover']], [`.--${getSubject.sid}--bg`, ['background-attachment', 'fixed']]]
                },
                {
                  label: 'Fill 100 % Width',
                  value: '100% auto',
                  customProperties: [[`.--${getSubject.sid}--bg`, ['background-attachment', 'unset']]]
                },
                {
                  label: 'Fill 100 % Width & Height',
                  value: '100% 100%',
                  customProperties: [[`.--${getSubject.sid}--bg`, ['background-attachment', 'unset']]]
                },
                {
                  label: 'Repeat',
                  value: 'repeat',
                  property: 'background-repeat',
                  customProperties: [[`.--${getSubject.sid}--bg`, ['background-size', 'contain']], [`.--${getSubject.sid}--bg`, ['background-repeat', 'repeat']], [`.--${getSubject.sid}--bg`, ['background-attachment', 'unset']]]
                },
                {
                  label: 'Repeat Horizontally',
                  value: 'repeat-x',
                  property: 'background-repeat',
                  customProperties: [[`.--${getSubject.sid}--bg`, ['background-size', 'contain']], [`.--${getSubject.sid}--bg`, ['background-repeat', 'repeat-x']], [`.--${getSubject.sid}--bg`, ['background-attachment', 'unset']]]
                },
                {
                  label: 'Repeat Vertically',
                  value: 'repeat-y',
                  property: 'background-repeat',
                  customProperties: [[`.--${getSubject.sid}--bg`, ['background-size', 'contain']], [`.--${getSubject.sid}--bg`, ['background-repeat', 'repeat-y']], [`.--${getSubject.sid}--bg`, ['background-attachment', 'unset']]]
                },
                {
                  label: 'Cover',
                  value: 'cover',
                  customProperties: [[`.--${getSubject.sid}--bg`, ['background-size', 'cover']], [`.--${getSubject.sid}--bg`, ['background-attachment', 'unset']]]
                },
                {
                  label: 'Contain',
                  value: 'contain',
                  customProperties: [[`.--${getSubject.sid}--bg`, ['background-size', 'contain']], [`.--${getSubject.sid}--bg`, ['background-attachment', 'unset']]]
                }
              ]"
              :sid="`.--${getSubject.sid}--bg`"
              var="--background-size"
              rule-name="background-size"
            />
          </b-form-group>

          <b-form-group label="Background Image Position">
            <sid-select
              :searchable="false"
              :options="[
                {label: 'Left Top', value: 'left top'},
                {label: 'Left Center', value: 'left center'},
                {label: 'Left Bottom', value: 'left bottom'},
                {label: 'Right Top', value: 'right top'},
                {label: 'Right Center', value: 'right center'},
                {label: 'Right Bottom', value: 'right bottom'},
                {label: 'Center Top', value: 'center top'},
                {label: 'Center Center', value: 'center center'},
                {label: 'Center Bottom', value: 'center bottom'},
              ]"
              :sid="`.--${getSubject.sid}--bg`"
              rule-name="background-position"
            />
          </b-form-group>

          <sid-range-slider
            :sid="`.--${getSubject.sid}--bg`"
            label="Custom Size"
            rule-name="background-size"
            var="--custom-bg-size"
            unlim
            :dimensions="['px', '%', 'vh', 'vw']"
          />

          <b-form-group label="Transform-Y">
            <sid-range-slider
              :sid="`.--${getSubject.sid}--bg`"
              rule-name="background-position-y"
            />
          </b-form-group>

          <b-form-group label="Transform-X">
            <sid-range-slider
              :sid="`.--${getSubject.sid}--bg`"
              rule-name="background-position-X"
            />
          </b-form-group>

          <sid-overlay
            :sid="`.--${getSubject.sid}--bg + .el-overlay`"
            rule-name=""
          />

          <hr>

          <sid-background-filters
            :sid="`.--${getSubject.sid}--bg`"
            rule-name="filter"
          />

          <b-form-group>
            <sid-range-slider
              label="Opacity"
              rule-name="opacity"
              :dimensions="[]"
              default-dimension=""
              :sid="`.--${getSubject.sid}--bg`"
              :min="0"
              :max="1"
              :step="0.1"
            />
          </b-form-group>

          <sid-background-mask
            v-if="false"
            :sid="`.--${getSubject.sid}--bg`"
          >
            <b-form-group>
              <div class="flex items-center justify-between">
                <label class="mb-0">Mask Fill Color</label>

                <sid-color
                  :sid="`.--${getSubject.sid}--flex-col-el.flex-col-el > div`"
                  rule-name="background-color"
                />
              </div>
            </b-form-group>
          </sid-background-mask>
        </template>
        <template #Animation>
          <backgroun-images-slider-customs :config="getSubject" />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Lottie Background">
      <lottie-config
        :config="lottieBackground"
        :sid="getSubject.sid"
      />

      <sid-translate
        :sid="`.--lottie-container-${getSubject.sid} > div`"
        rule-name="translate"
        :min="-100"
        :max="100"
        dimension="%"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Shape Divider"
    >
      <shape-divider-customs :config="getSubject" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Float Image">
      <sid-checkbox
        :sid="`.--${getSubject.sid}--flex-col-el > div > .float-image-wrapper`"
        rule-name="display"
        value="block"
        unchecked-value="none"
      >
        Float Image
      </sid-checkbox>

      <sid-upload-image
        :sid="`.--${getSubject.sid}--flex-col-el > div > .float-image-wrapper`"
        rule-name="content"
      />

      <sid-position-buttons
        :sid="`.--${getSubject.sid}--flex-col-el > div > .float-image-wrapper`"
        rule-name="translate"
      />

      <sid-flip
        :sid="`.--${getSubject.sid}--flex-col-el > div > .float-image-wrapper`"
        rule-name="scale"
      />

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Z-Index</label>

          <sid-input
            :sid="`.--${getSubject.sid}--flex-col-el > div > .float-image-wrapper`"
            rule-name="z-index"
            default-dimension=""
            class="!w-[59px]"
          />
        </div>
      </b-form-group>

      <sid-transform
        :sid="`.--${getSubject.sid}--flex-col-el > div > .float-image-wrapper`"
        rule-name="transform"
        translate-dimensional="%"
        :section="section"
        :controls="[
          {
            label: 'Skew',
            key: 'skew',
            min: -80,
            max: 80,
            step: 1,
            dimension: 'deg'
          },
          {
            label: 'Scale',
            key: 'scale',
            min: 0,
            max: 2,
            step: 0.1,
            dimension: ''
          },
          {
            label: 'Rotate',
            key: 'rotate',
            min: -360,
            max: 360,
            step: 1,
            dimension: 'deg'
          }
        ]"
      />

      <sid-position
        :sid="`.--${getSubject.sid}--flex-col-el > div > .float-image-wrapper`"
        rule-name="translate"
      />

      <sid-range-slider
        label="Opacity"
        :sid="`.--${getSubject.sid}--flex-col-el > div > .float-image-wrapper`"
        rule-name="opacity"
        :dimensions="[]"
        :default-dimension="''"
        :min="0"
        :max="1"
        :step="0.1"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Animations"
    >
      <b-form-group
        label="Onload effect"
        class="mb-0"
      >
        <animations
          :sid="getSubject.uid"
          :animations="animations"
        />
      </b-form-group>

      <hover-animations v-model="hoverAnimation" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Border & Radius">
      <rule-tabs>
        <template #Normal>
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${getSubject.sid}--flex-col-el`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${getSubject.sid}--flex-col-el`"
              label="Border Width"
              rule-name="border"
              css-var="--border-size"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${getSubject.sid}--flex-col-el`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${getSubject.sid}--flex-col-el`"
            rule-name="border"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />
        </template>
        <template #Hover>
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${getSubject.sid}--flex-col-el`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                presudo=":hover"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${getSubject.sid}--flex-col-el`"
              label="Border Width"
              rule-name="border"
              css-var="--border-size"
              class="!mb-[7px]"
              presudo=":hover"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${getSubject.sid}--flex-col-el`"
                rule-name="border-color"
                presudo=":hover"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${getSubject.sid}--flex-col-el`"
            rule-name="border"
            presudo=":hover"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Drop Shadow">
      <rule-tabs>
        <template #Normal>
          <b-form-group
            label="Shadow Color"
            class="mb-0"
          >
            <sid-box-shadow
              :sid="`.--${getSubject.sid}--flex-col-el`"
              rule-name="box-shadow"
            />
          </b-form-group>
        </template>
        <template #Hover>
          <b-form-group
            label="Shadow Color"
            class="mb-0"
          >
            <sid-box-shadow
              :sid="`.--${getSubject.sid}--flex-col-el`"
              rule-name="box-shadow"
              presudo=":hover"
            />
          </b-form-group>
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Free Position"
      beta
    >
      <b-form-group>
        <b-checkbox v-model="absolute">
          Enable Free Position
        </b-checkbox>
      </b-form-group>

      <b-form-group
        label="Transform Y"
        label-class="!pb-[3px]"
      >
        <b-row class="align-items-center pl-2">
          <b-col
            cols="8"
          >
            <vue-slider
              v-model="transformY"
              :interval="1"
              :max="100"
              :min="-100"
              class="editor-slider-1"
              tooltip="none"
            />
          </b-col>
          <b-col class="p-0">
            <LinkedControlItem
              v-model="transformY"
              :max="100"
              :min="-100"
              class="d-block !w-[60px]"
            />
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group
        label="Transform X"
        label-class="!pb-[3px]"
      >
        <b-row class="align-items-center pl-2">
          <b-col
            cols="8"
          >
            <vue-slider
              v-model="transformX"
              :interval="1"
              :max="100"
              :min="-100"
              class="editor-slider-1"
              tooltip="none"
            />
          </b-col>
          <b-col class="p-0">
            <LinkedControlItem
              v-model="transformX"
              :max="100"
              :min="-100"
              class="d-block !w-[60px]"
            />
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group label="Z-Index">
        <LinkedControlItem
          v-model="zIndex"
          :max="1000"
          :min="0"
          class="d-block !w-[60px]"
        />
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Motions"
      beta
    >
      <Motions :subject="getSubject" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Click Action">
      <ButtonAction
        :config="getSubject"
        class="mb-0"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <sid-linked-group
        label="Padding"
        :sid="`.--${getSubject.sid}--flex-col-padding`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
      <sid-linked-group
        label="Margin"
        :sid="`.--${getSubject.sid}--flex-col-el`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Device Visibility"
      content-class="p-0"
    >
      <Panel :config="getSubject" />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin'
import RuleTabs from '@/components/editor/components/RuleTabs'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground'
import SidWidthAndHeight from '@/components/editor/components/sid-controls/sid-control-pressets/SidWidthAndHeight'
import SidInput from '@/components/editor/components/sid-controls/SidInput'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup'
import SidColor from '@/components/editor/components/sid-controls/SidColor'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect'
import SidCheckbox from '@/components/editor/components/sid-controls/SidCheckbox'
import SidPositionButtons
  from '@/components/editor/components/sid-controls/sid-control-pressets/transform/buttons/SidPositionButtons'
import SidUploadImage
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidUploadImage'
import SidFlip from '@/components/editor/components/sid-controls/SidFlip'
import SidTransform from '@/components/editor/components/sid-controls/sid-control-pressets/transform/SidTransform'
import SidPosition from '@/components/editor/components/sid-controls/sid-control-pressets/transform/SidPosition'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'
import SidBoxShadow from '@/components/editor/components/sid-controls/sid-control-pressets/box-shadow/SidBoxShadow'
import BackgrounImagesSliderCustoms from '@/components/editor/controls/BackgrounImagesSliderCustoms'
import Motions from '@/components/editor/components/motions/Motions'
import _ from 'lodash'
import LinkedControlItem from '@/components/editor/components/linked-control-group/LinkedControlItem'
import ShapeDividerCustoms from '@/components/editor/controls/ShapeDividerCustoms'
import ButtonAction from '@/components/editor/controls/button-mod/ButtonAction'
import SidBackgroundFilters
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidBackgroundFilters'
import SidOverlay from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidOverlay'
import Animations from '@/components/editor/controls/Animations'
import HoverAnimations from '@/components/editor/controls/HoverAnimations'
import SidButtonsGroup from '@/components/editor/components/sid-controls/SidButtonsGroup'
import SidBackgroundMask
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidBackgroundMask'
import SidFlexDirection
  from '@/components/editor/components/sid-controls/sid-control-pressets/flex/SidFlexDirection.vue'
import Panel from '@/components/editor/controls/Panel.vue'
import SidTranslate from '@/components/editor/components/sid-controls/sid-control-pressets/transform/SidTranslate.vue'
import LottieConfig from '@/components/editor/components/LottieConfig.vue'

export default {
  name: 'FlexColumnModV2',
  components: {
    LottieConfig, SidTranslate,
    Panel,
    SidFlexDirection,
    SidBackgroundMask,
    SidButtonsGroup,
    HoverAnimations,
    Animations,
    SidOverlay,
    SidBackgroundFilters,
    ButtonAction,
    ShapeDividerCustoms,
    LinkedControlItem,
    Motions,
    BackgrounImagesSliderCustoms,
    SidBoxShadow,
    SidRangeSlider,
    SidPosition,
    SidTransform,
    SidFlip,
    SidUploadImage,
    SidPositionButtons,
    SidCheckbox,
    SidSelect,
    SidColor,
    SidLinkedGroup,
    SidInput, SidWidthAndHeight, SidBackground, SidbearSettingsGroup, RuleTabs
  },
  mixins: [ModMixin],
  data () {
    return {
      warnProps: false
    }
  },
  computed: {
    hoverAnimation: {
      get () {
        return this.getSubject.hoverAnimation || null
      },
      set (val) {
        this.$set(this.getSubject, 'hoverAnimation', val)
      }
    },
    lottieBackground () {
      if (!this.getSubject.lottie) {
        this.$set(this.getSubject, 'lottie', {})
      }

      return this.getSubject.lottie
    },
    animations () {
      if (!this.getSubject.animations) {
        this.$set(this.getSubject, 'animations', {
          animation: null,
          once: true,
          offset: 200,
          delay: 50
        })
      }

      return this.getSubject.animations
    },
    getSubject () {
      return this.openedItemOptions.subject ? this.openedItemOptions.subject : this.config.options.content.cols.find(col => col.uid === this.openedItemOptions.subjectIndex)
    },
    absolute: {
      get () {
        return _.get(this.getSubject, ['absolute', this.resolutioner], false)
      },
      set (val) {
        this.$set(this.getSubject, 'absolute', {..._.set(this.getSubject['absolute'] || {}, [this.resolutioner], val)})
      }
    },
    transformY: {
      get () {
        return _.get(this.getSubject, ['transformY', this.resolutioner], 0)
      },
      set (val) {
        this.$set(this.getSubject, 'transformY', {..._.set(this.getSubject['transformY'] || {}, [this.resolutioner], val)})
      }
    },
    transformX: {
      get () {
        return _.get(this.getSubject, ['transformX', this.resolutioner], 0)
      },
      set (val) {
        this.$set(this.getSubject, 'transformX', {..._.set(this.getSubject['transformX'] || {}, [this.resolutioner], val)})
      }
    },
    zIndex: {
      get () {
        return _.get(this.getSubject, ['zIndex', this.resolutioner], 0)
      },
      set (val) {
        this.$set(this.getSubject, 'zIndex', {..._.set(this.getSubject['zIndex'] || {}, [this.resolutioner], val)})
      }
    },
    sid () {
      return this.getSubject.sid
    },
    sticky: {
      get () {
        return _.get(this.getSubject, ['sticky', this.resolutioner], _.get(this.getSubject, ['sticky', 'lg'], this.getSubject.sticky))
      },
      set (val) {
        this.$set(this.getSubject, 'sticky', {
          ..._.set(typeof this.getSubject.sticky === 'object' ? this.getSubject.sticky : {
            lg: this.getSubject.sticky,
            md: this.getSubject.sticky,
            sm: this.getSubject.sticky
          }, [this.resolutioner], val)
        })
      }
    }
  },
  watch: {
    sid () {
      this.checkLinked()
    }
  },
  methods: {
    checkLinked () {
      if (document.body.querySelectorAll(`[data-sid="${this.getSubject.sid}"]`).length > 1) {
        return this.linked = true
      }

      this.linked = false
    }
  }
}
</script>