require('dotenv').config()
import Cookie from 'js-cookie'

import Vue from 'vue'
import _ from 'lodash'
// import Pusher from 'pusher-js'
// import Echo from 'laravel-echo'
import 'sticksy'
// Register All Global Components and Sections
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
// Router
import router from './router'

// Vuex
import store from './store'


// Plugins
import './plugins/bootstrap-vue'
import './plugins/vue-animate'
import './plugins/moment'
import './plugins/colorPicker'
import './plugins/axios'
import './plugins/sweetalert'
import './plugins/vEvent'
import './plugins/select'
import './plugins/unsplash'
import './plugins/countdown'
import './plugins/aos'
import './plugins/veevalidate'
import './plugins/vue-clipboard2'
import './plugins/animate-css'
import './plugins/custom-slider'
import './plugins/vuelidate'

// Styles
import '@builder/assets/css/dist/tailwind.css'
import '@builder/assets/scss/main.scss'

// Directives
import './directives/index'


// App Component
import App from './App.vue'


// window.Pusher = Pusher

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)


const builderComponents = require.context('./components/builder/sections', true, /[A-Z]\w+\.(vue)$/)
const builderElements = require.context('./components/builder/elements', true, /[A-Z]\w+\.(vue)$/)
const modComponents = require.context('./components/editor/mods', false, /[A-Z]\w+\.(vue)$/)
const controlComponents = require.context('./components/editor/controls', false, /[A-Z]\w+\.(vue)$/)
const utilsComponents = require.context('./components/editor/utils', false, /[A-Z]\w+\.(vue)$/)
const utilsBuilderComponents = require.context('./components/builder/utils', true, /[A-Z]\w+\.(vue)$/)

function registerComponents (requireComponent, prefix = '') {
  requireComponent.keys().forEach(fileName => {
    // Get component config
    const componentConfig = requireComponent(fileName)

    // Get PascalCase name of component
    const componentName = upperFirst(
      camelCase(
        // Gets the file name regardless of folder depth
        fileName
          .split('/')
          .pop()
          .replace(/\.\w+$/, '')
      )
    )

    // Register component globally
    Vue.component(
      prefix + componentName,
      // Look for the component options on `.default`, which will
      // exist if the component was exported with `export default`,
      // otherwise fall back to module's root.
      componentConfig.default || componentConfig
    )
  })
}

registerComponents(modComponents)
registerComponents(controlComponents)
registerComponents(utilsComponents)
registerComponents(utilsBuilderComponents)
registerComponents(builderComponents)
registerComponents(builderElements)

/* Global Components */
import FormSearch from '@/components/editor/components/form/FormSearch.vue'
Vue.component('FormSearch',FormSearch)
import InlineSvg from './components/editor/utils/InlineSvg.vue'
Vue.component('inline-svg', InlineSvg)
// Router Middleware
router.beforeEach((to, from, next) => {
  if (to.query.auth_token) {
    Cookie.set('cross_estage_token', to.query.auth_token)
    window.location = '/'
  } else {
    const user = store.state.auth.user
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    if (requiresAuth && !user) {
      Cookie.remove('cross_estage_token')

      if (process.env.NODE_ENV !== 'development') {
        window.location.href = process.env.VUE_APP_AUTH_HOST + '/logout'
      }
    } else if (requiresAuth && user) {
      if (
        user.user_subscription &&
        _.includes(['ESTAGE_SUB', 'BUSINESS_Y', 'BUSINESS_PLUS_M', 'BUSINESS_PLUS_Y'], user.user_subscription.plan.code) &&
        user.user_subscription.status !== 'succeeded' &&
        to.name !== 'reactivate'
      ) {
        next({ path: '/reactivate', query: { } })
      } else{
        next()
      }
    } else {
      next()
    }
  }
})

/* Mixin */
import GlobalMixin from '@/mixins/GlobalMixin'
Vue.mixin(GlobalMixin)

// Initialize Vue Application
Vue.config.productionTip = false

// window.Echo = new Echo({
//   broadcaster: 'pusher',
//   key: 'ZQYOZWHOEN',
//   wsHost: 'socket.estage.com',
//   wsPort: '6002',
//   wssHost: 'socket.estage.com',
//   wssPort: '6002',
//   forceTLS: true,
//   encrypted: true,
//   disableStats: true,
//   enabledTransports: ['wss', 'ws'],
//   authEndpoint: 'https://api.estage.com/api/broadcasting/auth',
//   auth: {
//     headers: {
//       Authorization: `Bearer ${Cookie.get('cross_estage_token')}`
//     }
//   }
// })

store.dispatch('auth/me')
  .then(() => {
    new Vue({
      store,
      router,
      render: h => h(App)
    }).$mount('#app')
  })
/* test */
