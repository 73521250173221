import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Live Stream'
export const group = 'Premium / Licensed'
export const paid = true
export const elementCode = 'LIVE_STREAM'
export const test = true

export const defaultOptions = {
  content: {
    liveStream: {
      display: true,
      type: 'live-stream-mod',
      isLive: false,
      replay: false,
      videoSource: 'webcam',
      showMembersCount: true
    }
  }
}

export const icon = `
<svg width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.9162 7.93095L9.90569 4.57569C9.71258 4.4464 9.46923 4.43955 9.27011 4.55779C9.07094 4.67603 8.94739 4.90079 8.94739 5.14474V11.8553C8.94739 12.0992 9.07094 12.3239 9.27011 12.4422C9.36472 12.4984 9.46928 12.5263 9.5737 12.5263C9.68907 12.5263 9.80427 12.4922 9.90565 12.4243L14.9162 9.06906C15.0993 8.94643 15.2105 8.73138 15.2105 8.5C15.2105 8.26863 15.0994 8.05357 14.9162 7.93095ZM10.2001 10.6445V6.35545L13.4025 8.5L10.2001 10.6445Z" fill="black"/>
<path d="M19.0918 0H3.27662C1.46989 0 0 1.46658 0 3.26923V13.7308C0 15.5334 1.46989 17 3.27662 17H19.0918C20.8985 17 22.3684 15.5334 22.3684 13.7308V3.26923C22.3684 1.46658 20.8985 0 19.0918 0ZM21.0578 13.7308C21.0578 14.8124 20.1758 15.6923 19.0918 15.6923H3.27662C2.19259 15.6923 1.31065 14.8124 1.31065 13.7308V3.26923C1.31065 2.18764 2.19259 1.30769 3.27662 1.30769H19.0918C20.1758 1.30769 21.0578 2.18764 21.0578 3.26923V13.7308Z" fill="black"/>
</svg>
`

export default class ElLiveStream extends SectionProvider {
  constructor (options = {}) {
    super('ElLiveStream', options)

    this.name = name
    this.group = group

    this.defaultOptions = defaultOptions
  }
}