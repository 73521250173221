<template>
  <div class="vh-100 overflow-hidden position-relative">
    <global-styles-render />
    <module-editor-header @save="onSave">
      <template #before>
        <slot name="header-before" />
      </template>
      
      <template #save-text>
        <slot name="save-text">
          Save
        </slot>
      </template>

      <template #after>
        <slot name="header-after" />
      </template>
    </module-editor-header>

    <div
      class="flex w-full"
    >
      <module-editor-objects :aside-title="asideTitle">
        <slot name="aside" />
      </module-editor-objects>

      <div
        class="w-full"
      >
        <stack-elements :main-crumb-title="crumbTitle" />

        <div
          id="editor-container"
          class="editor-container max-h-full w-full flex flex-column position-relative h-[calc(100vh-110px)]"
        >
          <div class="scrollbale-container overflow-auto h-100">
            <div 
              class="d-flex overflow-hidden justify-content-center"
              :style="{overflowY: moduleType === 'modal' ? 'auto !important' : null}"
            >
              <div
                :class="['resolution-' + resolutioner]"
                class="es-root web bg-transparent"
              >
                <slot name="container-prepend" />

                <div
                  class="blocks-container w-100 p-[2px]"
                  :class="blockContainerClass"
                >
                  <slot name="first-element" />

                  <slot>
                    <component
                      :is="draggable ? 'draggable' : 'div'"
                      v-model="content"
                      chosen-class="block-chosen"
                      :class="[{'empty-row-drag !h-[50vh]': !content.length}]"
                      class="d-flex align-items-center flex-column w-100"
                      :group="group"
                      handle=".move"
                    >
                      <component
                        :is="section.component"
                        v-for="(section, index) in content"
                        :key="section.uid"
                        :section="section"
                        :highliter-options="highliterOptions"
                        @delete="() => deleteSection(index)"
                        @dublicate="() => dublicateElement(index)"
                        @move-to="to => moveTo(index, to)"
                        @add-subject="() => addBlock(index)"
                        @favorite="node => favoriteBlock = {section: JSON.parse(JSON.stringify(section)), node}"
                      />
                    </component>
                  </slot>
                </div>

                <slot name="container-append" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      v-model="warnModal"
      hide-footer
      hide-header
      size="lg"
      centered
      body-class="p-5"
    >
      <h4 class="text-center mb-4">
        You have unsaved changes, are you sure?
      </h4>

      <div class="flex justify-center w-full gap-[10px]">
        <b-btn
          variant="primary"
          class="w-[82px]"
          @click="() => nextPromise(true)"
        >
          Yes
        </b-btn>
        <b-btn @click="() => nextPromise(false)">
          Cancel
        </b-btn>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ResolutionMixin from '@/components/mixins/ResolutionMixin'
import StackElements from '@/components/editor/components/StackElements'
import ModuleEditorHeader from '@/views/module-editor/components/ModuleEditorHeader'
import ModuleEditorObjects from '@/views/module-editor/components/ModuleEditorObjects'
import draggable from 'vuedraggable'
import {insert} from '@/sections/utils/helpers'
import shortid from 'shortid'
import GlobalStylesRender from '@/components/builder/utils/GlobalStylesRender'
import ViewportUpdate from '@/mixins/viewportUpdate'

export default {
  name: 'ModuleEditor',
  components: {GlobalStylesRender, ModuleEditorObjects, ModuleEditorHeader, StackElements, draggable},
  mixins: [ResolutionMixin, ViewportUpdate],
  props: {
    value: {
      type: Array
    },
    draggable: {
      type: Boolean,
      default: false
    },
    group: {
      type: String,
      default: 'rows'
    },
    moduleType: {
      type: String
    },
    crumbTitle: {
      type: String,
      required: true
    },
    asideTitle: {
      type: String,
      required: true
    },
    highliterOptions: {
      type: Object,
      default: () => {}
    },
    blockContainerClass: {
      type: [Array, String],
      default: () => []
    }
  },
  data () {
    return {
      warnModal: false,
      favoriteBlock: {},
      isChanged: false,
      isSaved: false,
      nextPromise: null
    }
  },
  computed: {
    content: {
      get () {
        return this.value || []
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  mounted () {
    window.addEventListener('mouseup', this.changed, true)
    window.addEventListener('keydown', this.changed, true)
    document.body.setAttribute('onbeforeunload', 'return true')
  },

  beforeDestroy () {
    window.removeEventListener('mouseup', this.changed, true)
    window.removeEventListener('keydown', this.changed, true)
    document.body.removeAttribute('onbeforeunload')
  },

  methods: {
    confirmLeave () {
      return new Promise((resolve) => {
        if (this.isChanged && !this.isSaved) {
          this.warnModal = true
          this.nextPromise = resolve
        } else {
          resolve(true)
        }
      })
        .finally(() => {
          this.warnModal = false
        })
    },
    changed (e) {
      if (!e.target.closest('.editor-header')) {
        this.isChanged = true
        this.isSaved = false
      }
    },
    onSave () {
      this.isSaved = true
      this.$emit('save')
    },
    addBlock (index) {
      this.$store.commit('editor/SET_PAGE', {
        name: 'add-row',
        event: 'click',
        subject: {
          subject: {
            children: this.content
          },
          options: {
            index
          }
        }
      })
    },
    favorite (payload) {
      this.$store.dispatch('favorites/addFavorite', {
        favorite: {
          section: payload.favorite,
          group: 'blocks'
        },
        inGlobal: payload.globally,
        node: payload.node
      })
        .finally(() => {
          this.favoriteBlock = {}
        })
    },
    moveTo (index, to) {
      const copySection = JSON.parse(JSON.stringify(this.content[index]))

      if (to === 'up') {
        if (index === 0) return
        this.content.splice(index, 1)
        this.content = insert(this.content, index - 1, copySection)
      } else if (to === 'down') {
        if (index + 1 === this.content.length) return
        this.content.splice(index, 1)
        this.content = insert(this.content, index + 1, copySection)
      }
    },
    dublicateElement (section) {
      this.content = insert(this.content, section, JSON.parse(JSON.stringify({
        ...this.content[section],
        uid: shortid.generate()
      })))
    },
    deleteSection (section) {
      this.content.splice(section, 1)
    }
  }
}
</script>