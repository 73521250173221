<template>
  <div>
    <module-editor
      v-if="!loading"
      ref="editor"
      crumb-title="Post Layout"
      aside-title="Post Layout"
      group="blocks"
      :draggable="true"
      @save="onSave"
    >
      <template #header-before>
        <button
          class="flex disabled:opacity-60 items-center rounded-left justify-center h-[36px] w-[41px] border bg-white p-0"
          :disabled="!isActiveUndo"
          @click="undo"
        >
          <svg
            width="17"
            height="11"
            viewBox="0 0 17 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          ><path
            d="M16.4649 10.7396C16.6349 10.704 16.7547 10.5567 16.7547 10.3867C16.7547 4.51518 9.7847 3.26446 8.16565 3.05318V0.35908C8.16565 0.226318 8.0928 0.10489 7.97541 0.0409374C7.85641 -0.0205864 7.71556 -0.0116817 7.60627 0.0603659L0.157034 5.07456C0.059082 5.14094 -1.52588e-05 5.25103 -1.52588e-05 5.37327C-1.52588e-05 5.49227 0.059082 5.60318 0.157034 5.66956L7.60222 10.6829C7.71313 10.7558 7.8556 10.7639 7.97218 10.7016C8.09037 10.6384 8.16322 10.5162 8.16322 10.3826V7.48451C9.3346 7.49179 10.3044 7.5436 11.1115 7.64075C14.9851 8.09975 16.0205 10.4255 16.0626 10.5251C16.12 10.6587 16.2512 10.7445 16.3945 10.7445C16.4187 10.7461 16.443 10.7445 16.4649 10.7396Z"
            fill="#6E747A"
          /></svg>
        </button>
        <button
          class="flex disabled:opacity-60 items-center rounded-right border-left-0 justify-center h-[36px] w-[41px] border bg-white p-0"
          :disabled="!isActiveRedo"
          @click="redo"
        >
          <svg
            width="17"
            height="11"
            viewBox="0 0 17 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          ><path
            d="M0.28981 10.7396C0.11981 10.704 0 10.5567 0 10.3867C0 4.51518 6.97 3.26446 8.58905 3.05318V0.35908C8.58905 0.226318 8.6619 0.10489 8.77929 0.0409374C8.89829 -0.0205864 9.03914 -0.0116817 9.14843 0.0603659L16.5977 5.07456C16.6956 5.14094 16.7547 5.25103 16.7547 5.37327C16.7547 5.49227 16.6956 5.60318 16.5977 5.66956L9.15248 10.6829C9.04157 10.7558 8.8991 10.7639 8.78252 10.7016C8.66433 10.6384 8.59148 10.5162 8.59148 10.3826V7.48451C7.4201 7.49179 6.45029 7.5436 5.64319 7.64075C1.76962 8.09975 0.734238 10.4255 0.692143 10.5251C0.634667 10.6587 0.503524 10.7445 0.360238 10.7445C0.335952 10.7461 0.311667 10.7445 0.28981 10.7396Z"
            fill="#6E747A"
          /></svg>
        </button>
      </template>

      <template #header-after>
        <b-dropdown
          toggle-tag="div"
          variant="link"
          right
          toggle-class="border !border-[#E2E5EC] h-[37px] bg-white ml-3"
        >
          <template #button-content>
            <svg
              width="16"
              height="4"
              viewBox="0 0 16 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="8"
                cy="2"
                r="2"
                transform="rotate(-180 8 2)"
                fill="#6E747A"
              />
              <circle
                cx="14"
                cy="2"
                r="2"
                transform="rotate(-180 14 2)"
                fill="#6E747A"
              />
              <circle
                cx="2"
                cy="2"
                r="2"
                transform="rotate(-180 2 2)"
                fill="#6E747A"
              />
            </svg>
          </template>

          <div class="px-[29px] py-2 w-[223px]">
            <b-link
              class="block text-[#6E747A] text-[15px] text-decoration-none hover:text-[rgba(110,116,122,.8)]"
              @click="modal = true"
            >
              Save As Layout Template
            </b-link>
          </div>
        </b-dropdown>
      </template>

      <template #aside>
        <SidbearSettingsGroup
          title="Layout Templates"
          content-class="py-0"
        >
          <div class="overflow-auto max-h-[470px] mr-[-21px] pr-[21px] pt-[14px] pb-[22px]">
            <div class="flex flex-col gap-[18px] w-100 align-items-center py-[18px]">
              <delete-confirm
                v-for="(template, index) in templates"
                :key="template.id"
                title="Are you sure?"
                description="Current progress will be lost."
                @confirm="() => selectTemplate(index)"
              >
                <template v-slot:activator="{on}">
                  <div
                    class="shadow-sm h-[200px] overflow-hidden w-[200px] relative text-[14px] font-[700] flex justify-center items-center cursor-pointer transition bg-cover border-[1px] border-[#D7D7D7] rounded-[5px]"
                    :class="{'border-[#4F83E3] border-[2px]': template.id === blogTemplate.id}"
                    :style="{backgroundImage: `url(${template.screen})`}"
                    v-on="on"
                  >
                    <span
                      v-if="template.id === 'blank'"
                      v-text="template.name"
                    />
                    <div
                      v-else
                      class="absolute bottom-0 text-white font-[700] text-[14px] left-0 h-[40px] bg-[rgba(0,0,0,.65)] flex items-center px-[12px] text-truncate w-[200px]"
                      v-text="template.name"
                    />
                  </div>
                </template>
              </delete-confirm>
            </div>
          </div>
        </SidbearSettingsGroup>

        <SidbearSettingsGroup
          title="Blocks"
          content-class="p-0 no-scrollbar"
        >
          <blocks-drag-items
            class="h-100"
            :filtered-blocks="['empty-blocks']"
          />
        </SidbearSettingsGroup>

        <SidbearSettingsGroup
          :ref="refs[0]"
          title="Rows"
          content-class="p-0 no-scrollbar"
          :active="$store.state.editor.page === 'add-row'"
          @toggle="e => onGroupChanged(e, refs[0])"
        >
          <rows-drag-items class="h-100" />
        </SidbearSettingsGroup>

        <SidbearSettingsGroup
          :ref="refs[1]"
          title="Elements"
          content-class="p-0"
          :active="$store.state.editor.page === 'add-element'"
          @toggle="e => onGroupChanged(e, refs[1])"
        >
          <elements-drag-items />
        </SidbearSettingsGroup>

        <SidbearSettingsGroup
          title="Dynamic Content"
          content-class="p-0"
        >
          <dynamic-elements-drag-items :group="['Dynamic Post Template', 'Dynamic blog']" />
        </SidbearSettingsGroup>

        <SidbearSettingsGroup
          :ref="refs[2]"
          title="Favorites"
          content-class="p-0"
        >
          <favorites-drag-items class="border-0" />
        </SidbearSettingsGroup>

        <SidbearSettingsGroup
          title="Layout Settings"
          :active="activeTab"
        >
          <b-form-group>
            <div class="flex items-center flex-wrap gap-[15px]">
              <!-- <b-checkbox
                v-model="settings.comments_moderation"
              >
                Comments Moderation
              </b-checkbox> -->

              <b-checkbox
                v-model="enableSidebar"
                :value="resolutioner"
              >
                Include Sidebar
              </b-checkbox>

              <b-checkbox
                v-if="resolutioner === 'lg'"
                v-model="settings.sidebar.right"
                :disabled="!enableSidebar.includes(resolutioner)"
                switch
              >
                Right
              </b-checkbox>

              <b-checkbox
                v-else
                v-model="settings.sidebar.top"
                :disabled="!enableSidebar.includes(resolutioner)"
                switch
                class="w-100 !mt-[-5px]"
              >
                Push to The Top
              </b-checkbox>
            </div>
          </b-form-group>

          <b-form-group>
            <div class="flex items-center gap-[10px]">
              <label class="pb-0">Header Link Color</label>

              <sid-color
                sid=".es-navbar .el-menu-link-v2"
                rule-name="color"
                var="--blog-header-link-color"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <b-checkbox v-model="settings.hideHeader">
              Hide Header
            </b-checkbox>
          </b-form-group>

          <b-form-group>
            <b-checkbox v-model="settings.hideFooter">
              Hide Footer
            </b-checkbox>
          </b-form-group>

          <sid-background
            sid=".blog-home-bg"
          />

          <sid-upload-image
            sid=".blog-home-bg"
            rule-name="background-image"
          />

          <b-form-group label="Background Image Size">
            <sid-select
              priority
              :searchable="false"
              :options="[
                {
                  label: 'Normal',
                  value: 'auto',
                  customProperties: [['.blog-home-bg', ['background-size', 'auto']], ['.blog-home-bg', ['background-attachment', 'unset']]]
                },
                {
                  label: 'Full Center (Parallax)',
                  value: 'fixed',
                  customProperties: [['.blog-home-bg', ['background-size', 'cover']], ['.blog-home-bg', ['background-attachment', 'fixed']]]
                },
                {
                  label: 'Fill 100 % Width',
                  value: '100% auto',
                  customProperties: [['.blog-home-bg', ['background-attachment', 'unset']]]
                },
                {
                  label: 'Fill 100 % Width & Height',
                  value: '100% 100%',
                  customProperties: [['.blog-home-bg', ['background-attachment', 'unset']]]
                },
                {
                  label: 'Repeat',
                  value: 'repeat',
                  property: 'background-repeat',
                  customProperties: [['.blog-home-bg', ['background-size', 'auto']], ['.blog-home-bg', ['background-repeat', 'repeat']], ['.blog-home-bg', ['background-attachment', 'unset']]]
                },
                {
                  label: 'Repeat Horizontally',
                  value: 'repeat-x',
                  property: 'background-repeat',
                  customProperties: [['.blog-home-bg', ['background-size', 'auto']], ['.blog-home-bg', ['background-repeat', 'repeat-x']], ['.blog-home-bg', ['background-attachment', 'unset']]]
                },
                {
                  label: 'Repeat Vertically',
                  value: 'repeat-y',
                  property: 'background-repeat',
                  customProperties: [['.blog-home-bg', ['background-size', 'auto']], ['.blog-home-bg', ['background-repeat', 'repeat-y']], ['.blog-home-bg', ['background-attachment', 'unset']]]
                },
                {
                  label: 'Cover',
                  value: 'cover',
                  customProperties: [['.blog-home-bg', ['background-size', 'cover']], ['.blog-home-bg', ['background-attachment', 'unset']]]
                },
                {
                  label: 'Contain',
                  value: 'contain',
                  customProperties: [['.blog-home-bg', ['background-size', 'contain']], ['.blog-home-bg', ['background-attachment', 'unset']]]
                }
              ]"
              sid=".blog-home-bg"
              var="--background-size"
              rule-name="background-size"
            />
          </b-form-group>

          <b-form-group label="Background Image Position">
            <sid-select
              :searchable="false"
              :options="[
                {label: 'Left Top', value: 'left top'},
                {label: 'Left Center', value: 'left center'},
                {label: 'Left Bottom', value: 'left bottom'},
                {label: 'Right Top', value: 'right top'},
                {label: 'Right Center', value: 'right center'},
                {label: 'Right Bottom', value: 'right bottom'},
                {label: 'Center Top', value: 'center top'},
                {label: 'Center Center', value: 'center center'},
                {label: 'Center Bottom', value: 'center bottom'},
              ]"
              sid=".blog-home-bg"
              rule-name="background-position"
            />
          </b-form-group>

          <sid-flip
            sid=".blog-home-bg"
            rule-name="scale"
          />

          <sid-range-slider
            sid=".blog-home-bg"
            label="Custom Size"
            rule-name="background-size"
            var="--custom-bg-size"
            unlim
            :dimensions="['px', '%', 'vh', 'vw']"
          />

          <b-form-group label="Transform-Y">
            <sid-range-slider
              sid=".blog-home-bg"
              rule-name="background-position-y"
            />
          </b-form-group>

          <b-form-group label="Transform-X">
            <sid-range-slider
              sid=".blog-home-bg"
              rule-name="background-position-X"
            />
          </b-form-group>

          <hr>

          <sid-overlay
            sid=".blog-home-bg > .el-overlay"
            rule-name=""
          />

          <sid-background-filters
            sid=".blog-home-bg"
            rule-name="filter"
          />

          <hr>

          <sid-background-mask
            sid=".blog-home-bg"
          />
        </SidbearSettingsGroup>
      </template>

      <!-- Just for styling -->
      <div class="es-navbar">
        <div class="el-menu-link-v2" />
      </div>
      <!-- End just for styling -->

      <div class="bg-[#5C5C5C] font-[700] text-[13px] flex justify-center items-center w-full h-[198px] text-white">
        User’s Current Site Header
      </div>

      <div
        v-if="!loadingRedoUndo && blogTemplate.content"
        class="d-flex flex-col post-layout align-items-center blog-home-bg"
      >
        <content-drop-area
          v-model="blogTemplate.content.top"
          :content-key="blogTemplate.id"
          drop-area-class="!min-h-[0px] !h-[0px] before:!content-['']"
          group="blocks"
        />

        <blog-content-wrapper
          :class="[{'flex-row-reverse': !settings.sidebar.right}, {'flex-col-reverse': resolutioner !== 'lg' && settings.sidebar.top}]"
        >
          <template v-slot:default="slotProps">
            <div class="w-full">
              <div
                class="relative w-100 h-[1138px] bg-cover  post-content !bg-no-repeat bg-top bg-center mt-[50px]"
                :style="{backgroundImage: `url(${require('@/assets/images/post-content-placeholder.png')}`}"
              >
                <b-btn
                  v-b-popover.hover.top="'This is a placeholder to indicate where you posts will display once its live. You cannot edit this. You can edit or create posts from the Dashboard Overview.'"
                  class="absolute top-[16px] right-[22px] p-0 bg-transparent border-none"
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_8518_58915)">
                      <path
                        d="M7.875 9.625C7.875 10.1082 7.48325 10.5 7 10.5C6.51675 10.5 6.125 10.1082 6.125 9.625C6.125 9.14175 6.51675 8.75 7 8.75C7.48325 8.75 7.875 9.14175 7.875 9.625Z"
                        fill="#3C7FE4"
                      />
                      <path
                        d="M6.74592 3.5C6.40944 3.5 6.14273 3.7839 6.16372 4.11972L6.39956 7.89319C6.40917 8.04691 6.53664 8.16667 6.69066 8.16667H7.30932C7.46333 8.16667 7.59081 8.04691 7.60041 7.89319L7.83626 4.11972C7.85724 3.7839 7.59054 3.5 7.25406 3.5H6.74592Z"
                        fill="#3C7FE4"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7ZM7 1.16667C3.77834 1.16667 1.16667 3.77834 1.16667 7C1.16667 10.2217 3.77834 12.8333 7 12.8333C10.2217 12.8333 12.8333 10.2217 12.8333 7C12.8333 3.77834 10.2217 1.16667 7 1.16667Z"
                        fill="#3C7FE4"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_8518_58915">
                        <rect
                          width="14"
                          height="14"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </b-btn>
              </div>

              <content-drop-area
                v-model="blogTemplate.content.bottom"
                :content-key="blogTemplate.id"
                drop-area-class="!min-h-[0px] !h-[0px] before:!content-['']"
                group="blocks"
              />
            </div>

            <content-drop-area
              v-if="enableSidebar.includes(resolutioner)"
              v-model="blogTemplate.content.aside"
              :content-key="blogTemplate.id"
              drop-area-class="!min-h-[100px] !min-w-[400px]"
              class="blog-aside w-auto"
              group="rows"
              @flow="slotProps.flow"
            />
          </template>
        </blog-content-wrapper>
      </div>

      <div class="bg-[#5C5C5C] font-[700] text-[13px] flex justify-center items-center w-full h-[198px] text-white">
        User’s Current Site Footer
      </div>
    </module-editor>
    <div
      v-else
      class="flex justify-center items-center vh-100"
    >
      <b-spinner variant="primary" />
    </div>

    <b-modal
      v-model="modal"
      hide-header
      hide-footer
      size="lg"
      centered
    >
      <div class="py-[40px] px-[90px]">
        <h5 class="text-[27px] font-[600] text-center">
          Save Layout Template
        </h5>
        <p class="text-center mb-4">
          (Single Post Page)
        </p>

        <b-form @submit.prevent="onSubmit">
          <b-form-group
            label="Give your Template a name:"
            label-class="!text-[15px] !text-[#5B5B5B] !font-[400]"
            class="!mb-[35px]"
          >
            <b-input
              v-model="layoutName"
              required
            />
          </b-form-group>

          <modal-button
            :loading="modalLoading"
          >
            Save Layout Template
          </modal-button>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ModuleEditor from '@/views/module-editor/ModuleEditor'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import RowsDragItems from '@/components/editor/components/RowsDragItems'
import ElementsDragItems from '@/components/editor/components/ElementsDragItems'
import FavoritesDragItems from '@/components/editor/components/FavoritesDragItems'
import {mapState} from 'vuex'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'
import BlocksDragItems from '@/components/editor/components/BlocksDragItems.vue'
import DynamicElementsDragItems from '@/components/editor/components/DynamicElementsDragItems.vue'
import ContentDropArea from '@/components/editor/components/ContentDropArea.vue'
import ModalButton from '@/components/editor/components/ModalButton.vue'
import defaultTempaltes from './post-templates.js'

// eslint-disable-next-line no-unused-vars
import {applyPageStyles, pageStylesToString} from '@/utils/useStylesheet'
import Screenshot from '@/mixins/Screenshot'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider.vue'
import SidBackgroundMask
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidBackgroundMask.vue'
import SidOverlay
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidOverlay.vue'
import SidFlip from '@/components/editor/components/sid-controls/SidFlip.vue'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect.vue'
import SidBackgroundFilters
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidBackgroundFilters.vue'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground.vue'
import SidUploadImage
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidUploadImage.vue'
import DeleteConfirm from '@/components/editor/modals/DeleteConfirm.vue'
import BlogContentWrapper from '@/components/builder/components/blog/BlogContentWrapper.vue'
import SidColor from '@/components/editor/components/sid-controls/SidColor.vue'
import undoRedoSupport from '@/components/mixins/UndoRedoSupport'

export default {
  name: 'PostTemplateEditor',
  components: {
    SidColor,
    BlogContentWrapper,
    DeleteConfirm,
    SidUploadImage, SidBackground, SidBackgroundFilters, SidSelect, SidFlip, SidOverlay, SidBackgroundMask,
    SidRangeSlider,
    ModalButton,
    ContentDropArea,
    DynamicElementsDragItems,
    BlocksDragItems,
    FavoritesDragItems, ElementsDragItems, RowsDragItems, SidbearSettingsGroup, ModuleEditor
  },
  mixins: [ResolutionMixin, Screenshot, undoRedoSupport],
  data () {
    return {
      id: null,
      loading: false,
      layoutName: '',
      modalLoading: false,
      subjectStyle: 'subject-styles',
      templates: [],
      modal: false,
      activeTab: false,
      settings: {
        hideHeader: false,
        hideFooter: false,
        sidebar: {
          enable: ['lg'],
          right: true,
          top: true
        }
      },
      templateData: {},
      blogTemplate: {},
      selectedTemplate: 0,
      refs: [
        'add-row',
        'add-element',
        'favorites'
      ]
    }
  },

  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    enableSidebar: {
      get () {
        return typeof this.settings.sidebar.enable === 'object' ? this.settings.sidebar.enable : [this.settings.sidebar.enable]
      },
      set (val) {
        this.settings.sidebar.enable = val
      }
    },
    getTargetContent: {
      get () {
        return this.blogTemplate.content
      },
      set (val) {
        this.$set(this.blogTemplate, 'content', val)
      }
    }
  },

  created () {
    this.$store.commit('editor/SET_PAGE', '')

    this.$store.state.editor.backLink = {
      name: 'settings.blog'
    }

    this.fetchTemplates()

    if (this.$route.params.template_id !== 'new') {
      this.loading = true

      axios.get(`https://blog-api.estage.com/api/projects/${this.project.id}/blog/manage/layouts/${this.$route.params.template_id}`)
        .then(({data}) => {
          this.blogTemplate = {
            ...data.data,
            content: JSON.parse(data.data.content)
          }
          this.settings.hideHeader = data.data.hide_header
          this.settings.hideFooter = data.data.hide_footer
          this.settings.comments_moderation = Boolean(data.data.comments_moderation)
          this.settings.sidebar.right = data.data.sidebar_right
          this.settings.sidebar.top = data.data.sidebar_top
          this.settings.sidebar.enable = JSON.parse(data.data.has_sidebar)
          applyPageStyles(data.data.css, 'subject-styles')
          this.templateData = data.data
          this.initUndoRedoSupport()
        })
        .finally(() => {
          this.loading = false

          setTimeout(() => {
            this.activeTab = true
          })
        })
    } else {
      this.initUndoRedoSupport()
    }
  },

  async beforeRouteLeave (to, from, next) {
    const answer = await this.$refs.editor.confirmLeave()
    if (!answer) return false
    next()
  },

  methods: {
    getTargetCSS () {
      return pageStylesToString(this.subjectStyle)
    },
    async fetchTemplates () {
      this.loading = true
      return await axios.get(`https://blog-api.estage.com/api/projects/${this.project.id}/blog/manage/layouts?template_type=post_template`)
        .then(({data}) => {
          this.templates = [...defaultTempaltes, ...data.data.data.filter(temp => temp.screen)]

          if (this.$route.params.template_id === 'new') {
            this.selectTemplate(0)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    selectTemplate (index) {
      this.$store.commit('editor/SET_PAGE', '')

      if (!this.templates[index].content) {
        this.loading = true
        return axios.get(`https://blog-api.estage.com/api/projects/${this.project.id}/blog/manage/layouts/${this.templates[index].id}`)
          .then(({data}) => {
            this.templates[index].content = JSON.parse(data.data.content)
            this.templates[index].css = data.data.css
            this.blogTemplate = {
              ...data.data,
              content: JSON.parse(data.data.content)
            }
            this.settings.hideHeader = data.data.hide_header
            this.settings.hideFooter = data.data.hide_footer
            this.settings.comments_moderation = Boolean(data.data.comments_moderation)
            this.settings.sidebar.right = data.data.sidebar_right
            this.settings.sidebar.top = data.data.sidebar_top
            this.settings.sidebar.enable = JSON.parse(data.data.has_sidebar)
            applyPageStyles(data.data.css, 'subject-styles')
            this.templateData = data.data
          })
          .finally(() => {
            this.loading = false
          })
      }

      this.blogTemplate = JSON.parse(JSON.stringify(this.templates[index]))

      this.settings.hideHeader = this.blogTemplate.hide_header
      this.settings.hideFooter = this.blogTemplate.hide_footer
      this.settings.comments_moderation = Boolean(this.blogTemplate.comments_moderation)
      this.settings.sidebar.right = this.blogTemplate.sidebar_right
      this.settings.sidebar.top = this.blogTemplate.sidebar_top
      this.settings.sidebar.enable = this.blogTemplate.has_sidebar

      applyPageStyles(this.blogTemplate.css, 'subject-styles')
    },
    onSave () {
      if (this.$route.params.template_id !== 'new') {
        this.$store.dispatch('font/prepareFonts', {}, {root: true})
          .then((fonts) => {
            this.loading = true
            this.takeScreenshot(document.getElementById('editor-container').querySelector('div > div'))
              .then((screen) => {
                axios.put(`https://blog-api.estage.com/api/projects/${this.project.id}/blog/manage/layouts/${this.$route.params.template_id}`, {
                  ...this.templateData,
                  template_type: 'post_template',
                  content: JSON.stringify(this.blogTemplate.content),
                  css: pageStylesToString('subject-styles'),
                  has_sidebar: JSON.stringify(this.enableSidebar),
                  sidebar_right: this.settings.sidebar.right,
                  sidebar_top: this.settings.sidebar.top,
                  hide_header: this.settings.hideHeader || false,
                  hide_footer: this.settings.hideFooter || false,
                  comments_moderation: Boolean(this.settings.comments_moderation) || false,
                  screen,
                  fonts: fonts.googleFonts + fonts.customFonts
                })
                  .then(() => {
                    this.$swal({
                      icon: 'success',
                      iconColor: '#4F83E3',
                      title: 'Saved!',
                      showConfirmButton: false,
                      timer: 1500
                    })
                  })
                  .catch(() => {
                    this.$swal({
                      icon: 'error',
                      iconColor: '#4F83E3',
                      toast: true,
                      position: 'top-right',
                      title: 'Server Error.',
                      showConfirmButton: false,
                      timer: 1500
                    })
                  })
                  .finally(() => {
                    this.loading = false
                  })
              })
          })
      } else {
        this.modal = true
      }
    },
    onSubmit () {
      this.modalLoading = true
      this.$store.dispatch('font/prepareFonts', {}, {root: true})
        .then((fonts) => {
          this.takeScreenshot(document.getElementById('editor-container').querySelector('div > div'))
            .then((screen) => {
              axios.post(`https://blog-api.estage.com/api/projects/${this.project.id}/blog/manage/layouts?template_type=post_template`, {
                template_type: 'post_template',
                content: JSON.stringify(this.blogTemplate.content),
                css: pageStylesToString('subject-styles'),
                name: this.layoutName,
                has_sidebar: JSON.stringify(this.enableSidebar),
                sidebar_right: this.settings.sidebar.right,
                sidebar_top: this.settings.sidebar.top,
                hide_header: this.settings.hideHeader || false,
                hide_footer: this.settings.hideFooter || false,
                comments_moderation: Boolean(this.settings.comments_moderation) || false,
                is_main: false,
                fonts: fonts.googleFonts + fonts.customFonts,
                screen
              })
                .then(({data}) => {
                  this.$swal({
                    icon: 'success',
                    iconColor: '#4F83E3',
                    toast: true,
                    position: 'top-right',
                    title: 'Saved!',
                    showConfirmButton: false,
                    timer: 1500
                  })

                  this.templateData = data.data
                  this.fetchTemplates()
                  this.$router.push({name: '', params: {template_id: data.data.id}})
                })
                .catch(() => {
                  this.$swal({
                    icon: 'error',
                    iconColor: '#4F83E3',
                    toast: true,
                    position: 'top-right',
                    title: 'Server Error.',
                    showConfirmButton: false,
                    timer: 1500
                  })
                })
                .finally(() => {
                  this.modalLoading = false
                  this.modal = false
                })
            })
            .finally(() => {
              this.modalLoading = true
            })
        })
    },
    onGroupChanged (isOpen, page) {
      this.$store.commit('editor/SET_PAGE', isOpen ? page : '')
    }
  }
}
</script>

<style lang="scss">
.blog-aside .flex-wrapper:first-child {
  min-width: var(--width, 400px);
}

.post-middle {
  max-width: 1600px;
  --width: 1600px;
}

.resolution-sm {
  .post-middle {
    flex-wrap: wrap;
  }
}

//@media screen {}
//@media (max-width: 1100px) {}
//@media (max-width: 520px) {}

</style>