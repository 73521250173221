<template>
  <highliter
    v-model="section.name"
    section-type="element"
    class="highlight-element hide-copy-favorite"
    :dublicate-deep="parentUID"
    :dublicate="section"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="$emit('delete')"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('order-form-mod')"
  >
    <div 
      class="el-order-form"
      :data-sid="section.sid"
    >
      <form-wizard
        :order-form="section.options.content.orderForm"
        :steps="steps"
        :count="steps.length"
        :section="section"
        :class="`--${section.sid}--order-form`"
        class="overflow-hidden order-form"
      />
    </div>
  </highliter>
</template>

<script>
import {mapState} from 'vuex'
import Highliter from '@/components/builder/utils/Highliter.vue'
import SectionMixin from '@/components/mixins/SectionMixin'
import PropertiesManager from '@/components/mixins/PropertiesManager'
import FormWizard from '@/components/builder/components/form/FormWizard.vue'
export default {
  name: 'ElOrderForm',
  components: {FormWizard, Highliter},
  mixins: [SectionMixin, PropertiesManager],
  computed: {
    ...mapState('forms', {
      wizard: state => state.wizard
    }),
    currentStep () {
      return this.wizard.currentStep
    },
    steps () {
      return this.section.options.content.orderForm.steps
    }
  },
  async created () {
    const length = this.steps.length
    await this.$store.dispatch('forms/setWizardCount', length)
    await this.$store.dispatch('forms/setWizardCurrentStep', 0)
  }
}
</script>

<style lang="scss">
.order-form {
  overflow: hidden;
  outline-width: var(--border-width);
}
</style>