import _ from 'lodash'

const state = () => ({
  loading: false,
  products: [],
  options: [],
  selected: []
})

const actions = {
  async getProduct ({state}, id) {
    state.loading = true
    await axios.get(`api/user/payment-systems/products/${id}`)
      .then(({data}) => {
        state.loading = false
        return data.data
      })
  },
  async getProductList ({state, rootState}) {
    state.loading = true
    await axios.get(`api/user/payment-systems/products?project_id=${rootState.projects.project.id}`)
      .then(({data}) => {
        state.products = data.data.data
        state.loading = false
      })
  },
  differenceOptions ({state}) {
    state.options = _.filter(state.products, item => !_.includes(state.selected, item))
  }
}

const mutations = {
  SET_SELECTED (state, payload) {
    state.selected = payload
  },
  PUSH_SELECTED (state, payload) {
    state.selected.push(payload)
  },
  SPLICE_SELECTED (state, payload) {
    state.selected.splice(payload, 1)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}