<template>
  <b-form-group
    class="sid-range mb-2"
    :label="label"
  >
    <div class="flex items-center gap-[17px] justify-between">
      <vue-slider
        v-model="val"
        :interval="step"
        :max="getDimensionValue.max"
        :min="getDimensionValue.min"
        class="editor-slider-1 w-100"
        tooltip="none"
        @change="val => $emit('change', val)"
      />

      <div :class="dimensions.length > 1 ? 'mt-[-26px]' : 'mt-[-10px]'">
        <div
          v-if="dimensions.length > 1"
          class="flex gap-[7px] text-[#A6A6A6] text-uppercase text-[11px] font-[600] mb-[11px]"
        >
          <span
            v-for="dim in dimensions"
            :key="dim"
            class="cursor-pointer hover:text-[#44474A]"
            :class="{'text-[#44474A]': dimension === dim}"
            @click="() => dimension = dim"
            v-text="dim"
          />
        </div>

        <LinkedControlItem
          v-model="val"
          :step="step"
          :ignore-zero="ignoreZero"
          :max="getDimensionValue.max"
          :min="getDimensionValue.min"
          class="d-block !w-[57px] !text-[#7E7E7E] !text-[13px] !h-[29px]"
          :placeholder="sidValueMutation === 'auto' ? 'auto' : '-'"
          @input="val => $emit('change', val)"
        />
      </div>
    </div>
    <div class="flex justify-end pt-2">
      <span
        class="text-[13px] text-[#44474A] hover:opacity-80 cursor-pointer underline"
        @click="reset"
      >Reset</span>
    </div>
  </b-form-group>
</template>

<script>
import SidConcept from '@/components/mixins/SidConcept'
import LinkedControlItem from '@/components/editor/components/linked-control-group/LinkedControlItem'

export default {
  name: 'SidRangeSlider',
  components: {LinkedControlItem},
  mixins: [SidConcept],
  props: {
    cleanSid: {
      type: String,
      default: ''
    },
    resetValue: {
      type: String,
      default: ''
    },
    defaultDimension: {
      type: String,
      default: 'px'
    },
    max: {
      type: Number,
      default: 100
    },
    min: {
      type: Number,
      default: 0
    },
    step: {
      type: Number,
      default: 1
    },
    dimensions: {
      type: Array,
      default: () => ['px', 'rem', '%']
    },
    label: {
      type: String,
      default: ''
    },
    measurement: {
      type: String,
      default: ''
    },
    unlim: {
      type: Boolean,
      default: false
    },
    pxRange: {
      type: Array,
      default: () => [0, 100]
    },
    ignoreZero: {
      type: Boolean,
      default: false
    },
    badPaddingClass: {
      type: String,
      default: ''
    },
    badContentClass: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      d: null,
      forceDimension: null
    }
  },
  computed: {
    getDefaultDimension () {
      return this.measurement ? '%' : this.defaultDimension
    },
    getDimensionValue () {
      if (this.ruleName === 'height' && this.badContentClass && this.badPaddingClass) {
        const padding = document.getElementsByClassName(`--${this.cleanSid}${this.badPaddingClass}`)[0]
        const content = document.getElementsByClassName(`--${this.cleanSid}${this.badContentClass}`)[0]
        const safeValue = parseInt(getComputedStyle(padding).paddingTop.replace('px', '')) + parseInt(getComputedStyle(padding).paddingBottom.replace('px', ''))
        const px = Math.round(content.getBoundingClientRect().height) + safeValue
        const vh = Math.round(px / window.innerHeight * 100)

        if (this.dimension === 'px') {
          return {
            max: this.measurement || this.unlim ? 4000 : this.pxRange[1],
            min: px
          }
        } else if (this.dimension === 'vh') {
          return {
            max: this.max,
            min: vh
          }
        } else {
          return {
            max: this.max,
            min: this.min
          }
        }
      } else {
        return this.dimension === 'px' ? {
          max: this.measurement || this.unlim ? 4000 : this.pxRange[1],
          min: this.pxRange[0]
        } : {
          max: this.max,
          min: this.min
        }
      }
    },
    dimension: {
      get () {
        if (!this.measurement) {
          if (['normal', 'auto', 'contain'].includes(this.sidValueMutation)) return this.getDefaultDimension
          if (this.forceDimension) return this.forceDimension
          if (!this.dimensions.length) return ''
        }

        const regx = /(\d+(?:\.\d+)?)(px|%|rem|vh|vw)/.exec(this.sidValueMutation) || []
        return this.sidValueMutation !== '' && regx.length >= 3 ? regx[2] || this.getDefaultDimension : this.getDefaultDimension
      },
      set (val) {
        this.forceDimension = val

        if (this.measurement) {
          setTimeout(() => {
            if (val === 'px') {
              const value = parseInt(document.querySelector(this.getSid()).getBoundingClientRect()[this.measurement])
              this.d = value
              return this.addSidRules([[this.getSid(), [this.ruleName, `${value}${val}`]]])
            }

            if (val === 'vw') {
              const value = parseInt((document.querySelector(this.getSid()).getBoundingClientRect().width / window.innerWidth) * 100)
              this.d = value
              return this.addSidRules([[this.getSid(), [this.ruleName, `${value}${val}`]]])
            }

            if (val === 'vh') {
              const value = parseInt((document.querySelector(this.getSid()).getBoundingClientRect().height / window.innerHeight) * 100)
              this.d = value
              return this.addSidRules([[this.getSid(), [this.ruleName, `${value}${val}`]]])
            }

            if (val === '%' && this.measurement === 'width') {
              let value = ''
              const parentWrapper = document.querySelector(this.getSid()).closest('[data-sid]') || document.querySelector(this.getSid()).parentElement

              if (parentWrapper) {
                value = parseInt((document.querySelector(this.getSid()).getBoundingClientRect().width / parentWrapper.clientWidth) * 100)
              }

              this.d = value
              this.addSidRules([[this.getSid(), [this.ruleName, `${value}${val}`]]])
            }

            if (val === '%' && this.ruleName === 'height') {
              let value = ''
              const parentWrapper = document.querySelector(this.getSid()).closest('[data-sid]') || document.querySelector(this.getSid()).parentElement

              if (parentWrapper) {
                value = parseInt((document.querySelector(this.getSid()).getBoundingClientRect().height / parentWrapper.clientHeight) * 100)
              }

              this.d = value
              this.addSidRules([[this.getSid(), [this.ruleName, `${value}${val}`]]])
            }
          }, 100)
        } else {
          this.addSidRules([[this.getSid(), [this.ruleName, `${parseInt(this.sidValueMutation)}${val}`]]])
        }
      }
    },
    val: {
      get () {
        const val = this.d !== null ? parseFloat(this.d) : parseFloat(this.sidValueMutation) || ''
        if (isNaN(this.d) || val > this.getDimensionValue.max) return ''
        return val
      },
      set (val) {
        this.d = val
        this.addSidRules([[this.getSid(), [this.ruleName, isNaN(val) ? '' : `${val}${this.dimension || this.getDefaultDimension}`]]])

        if (!this.defaultValue && this.d === '') {
          setTimeout(() => this.val = parseFloat(this.getSidStyle()))
        }
      }
    }
  },
  watch: {
    sid () {
      this.d = null
      this.forceDimension = null
    }
  },
  methods: {
    reset () {
      this.d = null
      this.addSidRules([[this.getSid(), [this.ruleName, this.resetValue]]])
    }
  }
}
</script>

<style lang="scss">
.sid-range {
  .custom-select {
    background: none !important;
  }
}
</style>