import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Social Links'
export const group = 'Others'

export const defaultOptions = {
  content: {
    socialLinks: {
      display: true,
      type: 'social-links-mod-v2',
      links: [
        {
          href: '',
          icon: 'fa fa-twitter',
          background: '#309EEB',
          color: '#fff',
          shape: 'circle'
        },
        {
          href: '',
          icon: 'fa fa-facebook',
          background: '#5D83E1',
          color: '#fff',
          shape: 'circle'
        }
      ]
    }
  }
}

export const icon = `
<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.6876 4.31987C19.957 4.64362 19.1721 4.86237 18.347 4.96125C19.1984 4.45182 19.8353 3.65003 20.139 2.7055C19.3391 3.18061 18.4637 3.51504 17.5508 3.69425C16.9368 3.03876 16.1237 2.60428 15.2375 2.45829C14.3514 2.31229 13.4418 2.46294 12.6501 2.88684C11.8583 3.31075 11.2287 3.98419 10.8589 4.80261C10.4891 5.62104 10.3999 6.53865 10.605 7.413C8.98424 7.33162 7.39868 6.91035 5.95126 6.17654C4.50383 5.44273 3.22687 4.41276 2.20326 3.1535C1.85326 3.75725 1.65201 4.45725 1.65201 5.20275C1.65162 5.87387 1.81689 6.53471 2.13315 7.12664C2.44942 7.71857 2.9069 8.22328 3.46501 8.596C2.81775 8.5754 2.18477 8.40051 1.61876 8.08587V8.13837C1.6187 9.07965 1.94429 9.99195 2.5403 10.7205C3.1363 11.449 3.96601 11.9489 4.88864 12.1354C4.2882 12.2979 3.65868 12.3218 3.04764 12.2054C3.30795 13.0153 3.81501 13.7235 4.49783 14.2309C5.18066 14.7383 6.00506 15.0195 6.85564 15.0351C5.41174 16.1686 3.62854 16.7835 1.79289 16.7807C1.46772 16.7808 1.14283 16.7618 0.819885 16.7239C2.68318 17.9219 4.85218 18.5577 7.06739 18.5552C14.5661 18.5552 18.6655 12.3445 18.6655 6.958C18.6655 6.783 18.6611 6.60625 18.6533 6.43125C19.4506 5.8546 20.1389 5.14053 20.6859 4.3225L20.6876 4.31987V4.31987Z" fill="black"/>
</svg>
`
export default class ElSocialLinks extends SectionProvider {
  constructor (options = {}) {
    super('ElSocialLinksV2', options)

    this.name = name
    this.group = group
    this.css = [['.--t3x95ha--07LNjU5Qxe--wrapper { padding: 20px; }'], [], []]
    this.sids = ['t3x95ha--07LNjU5Qxe']
    this.sid = 't3x95ha--07LNjU5Qxe'
    this.defaultOptions = defaultOptions
  }
}

