<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    :uid="section.uid"
    class="el-dynamic"
    section-type="element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('data')"
  >
    <div
      :data-sid="section.sid"
      :class="`--post-featured-title-${section.sid}`"
    >
      <div
        class="post-featured-title"
        v-text="text"
      />
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter.vue'

export default {
  name: 'ElFeaturedPostTitle',
  components: {Highliter},
  mixins: [SectionMixin],
  computed: {
    text () {
      const text = 'How to Fresh Ideas for Your Social Media Marketing'
      let max = this.section.options.content.data.maxWords

      if (!max) return text

      // Split the text into an array of words
      const words = text.split(' ')

      // Extract the first "limit" words
      const limitedWords = words.slice(0, max)

      // Join the words back into a string
      const limitedText = limitedWords.join(' ')

      // Add ellipsis if the text was truncated
      if (words.length > max) {
        return `${limitedText}...`
      }

      return limitedText
    }
  }
}
</script>

<style lang="scss">
 .post-featured-title {
   font-weight: 700;
   font-size: 50px;
   line-height: 56px;
   color: #000000;
   margin-bottom: 20px;
 }
</style>